import styles from "./InteractionsWidget.module.scss";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import LoadingPieChart from "../../assets/svg/LoadingPieChart.svg";
import Skeleton from "../../components/Skeleton/Skeleton";
import EmptyPieChartWidget from "../../components/FiraIcons/EmptyPieChartWidget";
import { useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import EmptyPieChartWidgetDark from "../../components/FiraIcons/EmptyPieChartWidgetDark";

interface LegendType {
  name: string;
  value: number;
}

interface Props {
  likes: number;
  shares: number;
  messages: number;
  clicks: number;
  isLoading: boolean;
  pieHeight?: number;
  background?: string;
}
const InteractionsWidget = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const pieChartDefaultSize = {
    width: "85%",
    height: props.pieHeight || 380,
  };
  const COLORS = ["#1E3BB3", "#F8CFC7", "#BEEF00", "#53CDF9"];
  const { t } = useTranslation();
  const [data, setData] = useState<LegendType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dataIsEmpty, setDataIsEmpty] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(0);
  const { utils } = useSelector((state: RootState) => state);
  const [columnFlex, setColumnFlex] = useState<boolean>(false);

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className={styles.CustomTooltip}>
          <p className={styles.textValue}>{payload[0].value}</p>
          <p className={styles.textLabel}>{`${t(
            "widgets.interactions.total"
          )} ${payload[0].name} `}</p>
        </div>
      );
    }

    return null;
  };

  const CustomLegend = () => {
    const maxwidth = 482;
    return (
      <div className={styles.CustomLegend} ref={ref}>
        {data.map((legend, i) => (
          <div className={styles.LegendItem} key={i}>
            <div className={styles.LegendLabel}>
              <div
                style={{ backgroundColor: `${COLORS[i]}` }}
                className={styles.Icon}
              ></div>
              <span
                style={{
                  fontSize: `${currentWidth < maxwidth ? "12px" : "16px"}`,
                }}
              >
                {legend.name}
              </span>
            </div>
            <span className={styles.LegendValue}>{legend.value}</span>
          </div>
        ))}
      </div>
    );
  };

  const handleData = () => {
    const newDataArray = [];

    if (props.likes > 0) {
      newDataArray.push({
        name: t("widgets.interactions.likes"),
        value: props.likes,
      });
    }

    if (props.shares > 0) {
      newDataArray.push({
        name: t("widgets.interactions.shares"),
        value: props.shares,
      });
    }

    if (props.clicks > 0) {
      newDataArray.push({
        name: t("widgets.interactions.clicks"),
        value: props.clicks,
      });
    }

    if (props.messages > 0) {
      newDataArray.push({
        name: t("widgets.interactions.messages"),
        value: props.messages,
      });
    }

    setData(newDataArray);

    if (
      props.likes === 0 &&
      props.shares === 0 &&
      props.messages === 0 &&
      props.clicks === 0
    ) {
      setDataIsEmpty(true);
    } else {
      setDataIsEmpty(false);
    }
  };

  useEffect(() => {
    handleData();
    setIsLoading(props.isLoading);
  }, [props]);

  useEffect(() => {
    if (!dataIsEmpty && ref.current) {
      setCurrentWidth(ref.current.clientWidth);
    }
  }, [dataIsEmpty]);

  useEffect(() => {
    if (ref.current) setCurrentWidth(ref.current?.clientWidth);
  }, [utils.screenWidth]);

  useEffect(() => {
    setColumnFlex(true);
  }, [currentWidth]);

  return (
    <div
      className={styles.ContainerInteractionsWidget}
      style={{ background: props.background ? props.background : "#FFFFFF" }}
    >
      {isLoading ? (
        <LoadingState />
      ) : dataIsEmpty ? (
        <NoInteractions background={""} />
      ) : (
        <>
          <label className={styles.TitleWidget}>
            {t("widgets.interactions.title")}
          </label>
          <label className={styles.SubtitleWidget}>
            {t("widgets.interactions.subtitle_2")}
          </label>
          <div
            className={`${columnFlex ? styles.ColumnFlex : ""} ${
              styles.PieChartContainer
            }`}
          >
            <div style={pieChartDefaultSize}>
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    dataKey="value"
                    data={data}
                    fill="#8884d8"
                    innerRadius={`40%`}
                    paddingAngle={data.length === 1 ? 0 : 2}
                    startAngle={90}
                    endAngle={450}
                    blendStroke={data.length === 1}
                  >
                    <Tooltip />
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip content={CustomTooltip} />
                </PieChart>
              </ResponsiveContainer>
            </div>
            {data !== undefined && <CustomLegend />}
          </div>
        </>
      )}
    </div>
  );
};

const LoadingState = () => {
  const n = 4;
  const items = [...Array(n)].map((e, i) => <LoadingItem key={i} />);
  return (
    <>
      <div className={styles.LoadingStateContainer}>
        <div className={styles.LoadingStateContainer__Top}>
          <div style={{ width: "40%" }}>
            <Skeleton width="100%" height="22px" />
          </div>
          <div style={{ width: "20%" }}>
            <Skeleton width="100%" height="22px" />
          </div>
        </div>
        <div className={styles.LoadingStateContainer__PieContainer}>
          <img src={LoadingPieChart} width={"90%"} height={"90%"} />
        </div>
        <div className={styles.LoadingStateContainer__Bottom}>{items}</div>
      </div>
    </>
  );
};

const LoadingItem = () => {
  return (
    <>
      <div className={styles.LoadingItem}>
        <div className={styles.FirstArea}></div>
        <div className={styles.SecondArea}>
          <Skeleton width="100%" height="22px" />
        </div>
        <div className={styles.ThirdArea}>
          <Skeleton width="100%" height="22px" />
        </div>
      </div>
    </>
  );
};

interface props {
  background: string;
}
const NoInteractions = (props: props) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.EmptyStateContainer}>
        <div className={styles.Labels}>
          <label className={styles.TitleWidget}>
            {t("widgets.interactions.title")}
          </label>
          <label className={styles.SubtitleWidget}>
            {t("widgets.interactions.subtitle_2")}
          </label>
        </div>

        <div className={styles.PictureContainer}>
          {props.background === "#FFFFFF" ? (
            <EmptyPieChartWidget height={"44%"} />
          ) : (
            <EmptyPieChartWidgetDark height={"44%"} />
          )}
        </div>
      </div>
    </>
  );
};

export default InteractionsWidget;
