import React from "react";
import { ChangeEvent, ReactNode, useEffect, useState } from "react";

import Input from "../Input/Input";
import Checkbox from "../Checkbox/Checkbox";

import "./Table.scss";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useClickOutside } from "../../utils/useClickOutside";
import { useDebounce } from "../../utils/useDebounce";
import TrashCan from "../FiraIcons/TrashCan";
import MagnifyingGlass from "../FiraIcons/MagnifyingGlass";
import UpDownArrow from "../FiraIcons/UpDownArrow";
import ArrowRight from "../FiraIcons/ArrowRight";
import CountrySelect from "../CountrySelect/CountrySelect";
import { OptionsSelect } from "../../utils/types";

export enum TableStyles {
  normal = "",
  transparent = "TableTransparent",
  noHeader = "NoHeader",
  draggable = "Draggable",
  underline = "Underline",
}

interface TableProps {
  header: ReactNode[] | ReactNode;
  body: ReactNode[] | ReactNode;
  tableStyle?: TableStyles;
  height?: string | number;
  headerHeight?: string;
  detectScroll?(scrollTop: number): void;
}
const Table = ({
  header,
  body,
  tableStyle = TableStyles.normal,
  height = "stretch",
  headerHeight = "",
  detectScroll,
}: TableProps) => {
  return (
    <div
      className={`TableWrapper ${tableStyle}`}
      style={{ height }}
      onScroll={(e) => {
        detectScroll && detectScroll(e.currentTarget.scrollTop);
      }}
    >
      <table className="Table">
        <thead className={`${tableStyle}`} style={{ height: headerHeight }}>
          {header}
        </thead>
        <tbody>{body}</tbody>
      </table>
    </div>
  );
};

export enum InfiniteTableStyles {
  normal = "",
  transparent = "TableTransparent",
  noHeader = "NoHeader",
}

interface InfiniteTableProps {
  header: ReactNode[] | ReactNode;
  body: ReactNode[] | ReactNode;
  rowLoader?: ReactNode;
  tableStyle?: TableStyles;
  height?: string | number;
  headerHeight?: string;
  loadData(): void;
  rowsNumber: number;
  hasMore: boolean;
}
const InfiniteTable = ({
  header,
  body,
  tableStyle = TableStyles.normal,
  height = "stretch",
  headerHeight = "",
  loadData,
  rowsNumber,
  rowLoader,
  hasMore,
}: InfiniteTableProps) => {
  return (
    <InfiniteScroll
      next={() => loadData()}
      dataLength={1000 * rowsNumber}
      hasMore={hasMore}
      loader={rowLoader}
      height={height}
      className={`TableWrapper ${tableStyle}`}
    >
      <table className="Table">
        <thead className={`${tableStyle}`} style={{ height: headerHeight }}>
          {header}
        </thead>
        <tbody>{body}</tbody>
      </table>
    </InfiniteScroll>
  );
};

export enum checkBoxPosition {
  left = "left",
  right = "right",
}
export interface orderingType {
  id: number;
  name: string;
  value: string | number;
}

interface TableHeaderProps {
  checkboxHandle?(): void;
  checkboxIsActive?: boolean;
  selected?: number;
  searchInputValue?: string;
  searchInputChangeHandle?(value: ChangeEvent<HTMLInputElement>): void;
  handleOptionClick?(): void;
  itemsOnList?: number;
  optionIsActive?: boolean;
  hideCheckBox?: boolean;
  checkBoxPosition?: checkBoxPosition;
  orderingList?: orderingType[];
  orderingClick?(id: number): void;
  orderingValue?: orderingType;
  separated?: boolean;
  onFocus?(): void;
  onBlur?(): void;
  onChange?(option: OptionsSelect): void;
  optionSelected?: string;
}
const TableHeader = ({
  checkboxHandle,
  checkboxIsActive = false,
  selected = 0,
  searchInputChangeHandle,
  searchInputValue,
  handleOptionClick,
  optionIsActive = false,
  hideCheckBox = false,
  checkBoxPosition,
  orderingList,
  orderingValue,
  orderingClick,
  separated = false,
  onFocus,
  onBlur,
  onChange,
  optionSelected,
}: TableHeaderProps) => {
  const { t } = useTranslation();
  let optionClassName = "Options";
  if (!optionIsActive) {
    optionClassName += " Inactive";
  }
  const [openList, setOpenList] = useState(false);
  const domNode = useClickOutside(() => setOpenList(false));

  const handleSelectedOption = (id: number) => {
    setOpenList(false);
    orderingClick && orderingClick(id);
  };

  return (
    <div
      className={`TableHeader ${selected > 0 ? "IsActive" : ""} ${
        hideCheckBox ? "nocheckBox" : ""
      } ${checkBoxPosition === "right" ? "invertedHeader" : ""}
      ${separated ? "Separated" : ""}`}
    >
      {hideCheckBox ? (
        ""
      ) : (
        <div className="CheckboxContainer">
          <Checkbox isActive={checkboxIsActive} clickHandler={checkboxHandle} />

          {selected > 0 && (
            <>
              <span className="SelectedText">
                {selected} {t("components.table.counterText")}
              </span>
              <span
                onClick={optionIsActive ? handleOptionClick : undefined}
                className={optionClassName}
              >
                <TrashCan className="Icon" />
                {t("components.table.optionsButton")}
              </span>
            </>
          )}
        </div>
      )}
      <div className="SearchContainer">
        <Input
          height="44px"
          width="100%"
          onChange={searchInputChangeHandle}
          value={searchInputValue}
          icon={<MagnifyingGlass className="SearchIcon" />}
          placeholder={"" + t("components.table.searchPlaceHolder")}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
      {onChange && (
        <CountrySelect
          isLocalChange={true}
          onChange={onChange}
          selectedValue={optionSelected ? optionSelected : ""}
        />
      )}

      {orderingValue && (
        <div
          className="orderingContainer"
          onClick={() => setOpenList(!openList)}
          ref={domNode}
        >
          <div className="orderingTitle">
            <p className="body-14-text">
              {t("components.table.filteringTitle")}
            </p>
            <UpDownArrow />
          </div>

          {openList && (
            <div className="orderingOptions">
              {orderingList?.map((option) => {
                return (
                  <p
                    onClick={() => handleSelectedOption(option.id)}
                    key={option.name}
                    className="body-14-text insideOption"
                  >
                    {option.name}
                  </p>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export enum TableRowStyles {
  iluminated = "iluminated",
  inactive = "inactive",
}

interface TableRowProps {
  children?: ReactNode[] | ReactNode;
  onClick?(): void;
  iluminated?: boolean;
  inactive?: boolean;
  shrink?: boolean;
}
const TableRow = ({
  children,
  onClick,
  iluminated = false,
  inactive = false,
  shrink = false,
}: TableRowProps) => {
  return (
    <tr
      onClick={onClick}
      className={`Row ${iluminated ? "iluminated" : ""} ${
        inactive ? "inactive" : ""
      } ${onClick ? "onClick" : ""} ${shrink ? "shrink" : ""}`}
    >
      {children}
    </tr>
  );
};

interface TableHeadProps {
  children?: ReactNode;
  size?: string;
  centerContent?: boolean;
  background?: boolean;
  paddingLeft?: boolean;
}
const TableHead = ({
  children,
  centerContent = false,
  size,
  background = false,
  paddingLeft = false,
}: TableHeadProps) => {
  return (
    <th
      className={`Head body-14-text ${centerContent ? "CenterContent" : ""} ${
        background ? "background" : ""
      }`}
      style={{ width: size, paddingLeft: paddingLeft ? "9px" : "" }}
    >
      {children}
    </th>
  );
};

interface TableCellProps {
  children?: ReactNode;
  size?: string;
  centerContent?: boolean;
  RightContent?: boolean;
  shrink?: boolean;
  noPadding?: boolean;
  flexRow?: boolean;
}
const TableCell = ({
  children,
  size,
  centerContent = false,
  RightContent = false,
  shrink = false,
  noPadding = false,
  flexRow = false,
}: TableCellProps) => {
  return (
    <td
      className={`Cell lead-text ${centerContent ? "CenterContent" : ""} ${
        RightContent ? "RightContent" : ""
      } ${shrink ? "shrink" : ""} ${flexRow ? "flexRow" : ""} `}
      style={{ minWidth: size, padding: noPadding ? "0px" : "" }}
    >
      {children}
    </td>
  );
};

//CellSeparator
interface TableCellProps {
  children?: ReactNode;
  size?: string;
  centerContent?: boolean;
  RightContent?: boolean;
  shrink?: boolean;
  noPadding?: boolean;
}
const TableCellNoBorder = ({
  children,
  size,
  centerContent = false,
  RightContent = false,
  shrink = false,
  noPadding = false,
}: TableCellProps) => {
  return (
    <td
      className={`CellNoBorder lead-text ${
        centerContent ? "CenterContent" : ""
      } ${RightContent ? "RightContent" : ""} ${shrink ? "shrink" : ""}  `}
      style={{ minWidth: size, padding: noPadding ? "0px" : "" }}
    >
      {children}
    </td>
  );
};

interface TableButtonProps {
  onClick?(): void;
  width?: string;
  height?: string;
  iconSize?: string;
}

const TableButton = ({
  onClick,
  width = "49px",
  height = "57px",
  iconSize = "30px",
}: TableButtonProps) => {
  return (
    <Button
      width={width}
      height={height}
      clickHandler={onClick}
      className={"TableButton"}
    >
      <ArrowRight style={{ fontSize: iconSize, color: "#000000" }} />
    </Button>
  );
};

interface TableImageProps {
  src?: string;
  opacity?: boolean;
}
const TableImage = ({ src, opacity = false }: TableImageProps) => {
  return (
    <div className="TableImage" style={{ opacity: !opacity ? "100%" : "50%" }}>
      <img src={src}></img>
    </div>
  );
};

interface DraggableTableProps {
  header: ReactNode[] | ReactNode;
  body: ReactNode[] | ReactNode;
  tableStyle?: TableStyles;
}

const DraggableTable = ({
  header,
  body,
  tableStyle = TableStyles.normal,
}: DraggableTableProps) => {
  return (
    <div className="TableWrapper">
      <table className={`${tableStyle} Table`}>
        <thead>{header}</thead>
        <tbody>{body}</tbody>
      </table>
    </div>
  );
};

interface TableInputProps {
  inputValue: string;
  inputName: string;
  rowId: string | number;
  handleChange(
    inputValue: string,
    inputName: string,
    rowId: string | number
  ): void;
  onlyNumbers?: boolean;
  onlyPrices?: boolean;
  isDisabled?: boolean;
  noCeros?: boolean;
  notEmpty?: boolean;
  returnError?(): void;
}

const TableInput = ({
  inputValue,
  inputName,
  rowId,
  handleChange,
  onlyNumbers,
  onlyPrices,
  isDisabled,
  noCeros,
  notEmpty,
  returnError,
}: TableInputProps) => {
  const [value, setvalue] = useState(inputValue);
  const notNumbersRegex = new RegExp(/\D+/g);
  const priceRegex = new RegExp(/[^.,\d]/g);
  const decimalRegex = new RegExp(/[.,]/g);

  const debounced = useDebounce(value, 1500);

  const inputChange = (newValue: string) => {
    let d = newValue;
    if (onlyNumbers) {
      d = newValue.replace(notNumbersRegex, "");
    }
    if (onlyPrices) {
      d = newValue.replace(priceRegex, "");
    }
    setvalue(d);
  };
  useEffect(() => {
    handleChange(value, inputName, rowId);
    if (value === "0" && noCeros && onlyPrices) {
      setvalue(inputValue);
    }
    if (onlyPrices) {
      //validamos si incluye punto
      if (value.includes(".") || value.includes(",")) {
        //si incluye punto, validamos que tenga un valor luego del punto
        const splited = value.includes(".")
          ? value.split(".")
          : value.split(",");
        if (splited[1].length === 0) {
          //si no hay nada despues del punto, retornamos el valor sin punto
          setvalue(inputValue);
        } else {
          //si tiene un valor luego pero es punto y coma o coma y punto, retornamos el valor sin los decimales
          if (value.includes(",.") || value.includes(".,")) {
            setvalue(value.replace(decimalRegex, ""));
          }
        }
      }
    }
    if (value.length === 0) {
      returnError && returnError();
      onlyPrices && setvalue(inputValue);
      onlyNumbers && setvalue("0");
      notEmpty && setvalue(inputValue);
    }
  }, [debounced]);
  return (
    <Input
      fontSize="12px"
      value={value}
      isTextCenter
      width="88px"
      onChange={(e) => inputChange(e.target.value)}
      isDisabled={isDisabled}
    />
  );
};

export {
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableCellNoBorder,
  TableHeader,
  TableButton,
  TableImage,
  InfiniteTable,
  DraggableTable,
  TableInput,
};
