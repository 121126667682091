import React, { useState, createContext, useContext } from "react";

import stylesheet from "./AlertStyles.module.scss";
import CheckCircleFill from "../../components/FiraIcons/CheckCircleFill";
import ErrorSign from "../../components/FiraIcons/ErrorSign";
import WarningCircle from "../../components/FiraIcons/WarningCircle";
import Cross from "../../components/FiraIcons/Cross";

export enum AlertType {
  success = "Success",
  error = "Error",
  warning = "Warning",
}

interface AlertMessage {
  type: AlertType;
  message: string;
}

interface AlertContextType {
  alert: AlertMessage | null;
  setAlert: (alert: AlertMessage | null) => void;
}

const AlertContext = createContext<AlertContextType>({
  alert: null,
  setAlert: () => ({}),
});

export const useAlert = () => useContext(AlertContext);

interface AlertProviderProps {
  children: React.ReactNode;
}
export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const [alert, setAlert] = useState<AlertMessage | null>(null);

  const closeMessage = () => {
    setAlert(null);
  };

  const alertIconToRender = () => {
    switch (alert?.type) {
      case AlertType.success:
        return <CheckCircleFill />;
      case AlertType.error:
        return <ErrorSign />;
      case AlertType.warning:
        return <WarningCircle />;
      default:
        return <WarningCircle />;
    }
  };

  let alertTypeCss = stylesheet.Success;
  if (alert) {
    switch (alert.type) {
      case AlertType.success:
        alertTypeCss = stylesheet.Success;
        break;
      case AlertType.error:
        alertTypeCss = stylesheet.Error;
        break;
      case AlertType.warning:
        alertTypeCss = stylesheet.Warning;
        break;
      default:
        alertTypeCss = stylesheet.Warning;
        break;
    }
  }

  return (
    <AlertContext.Provider value={{ alert, setAlert }}>
      {children}
      {alert && (
        <div className={`${stylesheet.AlertWrapper} ${alertTypeCss} fade-in`}>
          <div className={stylesheet.AlertIconWrapper}>
            {alertIconToRender()}
          </div>
          <div className={stylesheet.AlertMessageWrapper}>{alert.message}</div>
          <button
            className={stylesheet.AlertCloseButton}
            onClick={closeMessage}
          >
            <Cross />
          </button>
        </div>
      )}
    </AlertContext.Provider>
  );
};

export const usePopupAlert = () => {
  const { setAlert } = useAlert();

  const showAlert = (type: AlertType, message: string) => {
    setAlert({ type, message });
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  };

  return showAlert;
};
