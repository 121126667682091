import { TRANSLATIONS_EN } from "./../translations/en/locale";

export const findTranslationByValue = (value: string) => {
  const translations = TRANSLATIONS_EN;
  const errorMessages = translations.views.users.new.errorMessages;

  for (const key in errorMessages) {
    if (errorMessages[key as keyof typeof errorMessages] === value) {
      return "" + key;
    }
  }
  return "Error service: please try again"; // Error por defecto en caso de no encontrar coincidencia
};
