import { createSlice } from "@reduxjs/toolkit";
import { AuthResponse } from "../../utils/types";
import { AuthState } from "../../utils/typesStates";
import { login, loginMicrosoft } from "./authActions";

const initialState: AuthState = {
  isLoading: false,
  isLogging: false,
  user: null,
  error: false,
  tokenOAuth: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsLoggingState: (state, action) => {
      state.isLogging = action.payload as boolean;
    },
    logoutAction: (state) => {
      state.isLoading = false;
      state.isLogging = false;
      state.user = null;
      state.error = false;
    },
    updateToken: (state, action) => {
      state.user.token = action.payload as string;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLogging = true;
        state.tokenOAuth = action.payload.token;
        state.user = action.payload.authData as AuthResponse;
      })
      .addCase(login.rejected, (state, _) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const {
  setIsLoggingState,
  logoutAction,
  updateToken,
} = authSlice.actions;
export default authSlice.reducer;
