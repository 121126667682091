import axios from "axios";
import { AllCountries, CategoryItem } from "../utils/types";
import { apiCmsPrivate, apiManagement } from "./api";

export const getAllCountries = (token: string) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return apiCmsPrivate.get<AllCountries[]>(`/api/v1/countries/all`, {
    headers,
  });
};

export const getAllCategories = (token: string) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return apiCmsPrivate.get<CategoryItem[]>(
    `/api/v1/fira-event-category-controller/all`,
    {
      headers,
    }
  );
};

export const AllUsersListService = (tokenMicrosoft: string) => {
  return apiManagement.get<string[]>(
    `/v1/super-admin/get-all-users/${tokenMicrosoft}`
  );
};

export interface CountryCode {
  name: string;
  countryCode: string;
  dialCode: string;
  flagUrl: string;
}
export const getPhoneCodes = (): Promise<CountryCode[]> => {
  return new Promise((resolve, reject) => {
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((res) => {
        const formattedData: CountryCode[] = res.data
          .map((country: any) => {
            let dialCode =
              country.idd.root +
              (country.idd.suffixes ? country.idd.suffixes[0] : "");
            if (country.cca2 === "US") {
              dialCode = "+1";
            }
            return {
              name: country.name.common,
              countryCode: country.cca2,
              dialCode,
              flagUrl: `https://flagcdn.com/w20/${country.cca2.toLowerCase()}.png`,
            };
          })
          .sort((a: CountryCode, b: CountryCode) =>
            a.name.localeCompare(b.name)
          );
        resolve(
          formattedData.filter((country) => country.dialCode !== "undefined")
        );
      })
      .catch((err) => {
        reject(err);
      });
  });
};
