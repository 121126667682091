import React from "react";
import { CSSProperties, ReactNode, useEffect, useState } from "react";
import LeftArrowNoPadding from "../../assets/svg/LeftArrowNoPadding.svg";

import styles from "./FiraNavBar.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/typesStates";

import HorizontalDots from "../FiraIcons/HorizontalDots";

interface FiraNavBarProps {
  trailingOptions?: ReactNode[] | ReactNode;
  leadingOptions?: ReactNode;
  titleSection?: ReactNode;
  backButtonHandler?(): void;
  style?: CSSProperties;
}
const FiraNavBar = ({
  trailingOptions,
  leadingOptions,
  titleSection,
  backButtonHandler,
  style,
}: FiraNavBarProps) => {
  const { utils } = useSelector((state: RootState) => state);
  const [isNavVisible, setNavVisibility] = useState(false);
  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  useEffect(() => {
    if (utils.screenWidth > 700) {
      setNavVisibility(true);
    } else {
      setNavVisibility(false);
    }
  }, [utils.screenWidth]);

  return (
    <header className={styles.Header}>
      <div className={styles.LeadingSection}>
        {backButtonHandler && (
          <img
            className={styles.btnBack}
            src={LeftArrowNoPadding}
            alt="backButton"
            onClick={backButtonHandler}
          />
        )}
        <span
          className={`${styles.DefaultText} ${styles.LimitText}`}
          style={style ? style : {}}
        >
          {titleSection}
        </span>

        <div className={styles.LeadingSectionOptions}>{leadingOptions}</div>
      </div>

      {isNavVisible && (
        <nav className={styles.TrailingSection}>{trailingOptions}</nav>
      )}
      {trailingOptions && (
        <div className={styles.MenuOption} onClick={toggleNav}>
          <div className={styles.buttonMenu}>
            <HorizontalDots />
          </div>
        </div>
      )}
    </header>
  );
};

export default FiraNavBar;
