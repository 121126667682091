import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DashboardState } from "../../utils/typesStates";
import { t } from "i18next";

const initialState: DashboardState = {
  firstDate: new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  ).getTime(),
  lastDate: new Date().getTime(),
  country: "",
  isLoading: false,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDatesRange: (
      state,
      action: PayloadAction<{ firstDate: number; lastDate: number }>
    ) => {
      state.firstDate = action.payload.firstDate;
      state.lastDate = action.payload.lastDate;
    },
    setCountryState: (state, action) => {
      state.country = action.payload as string;
    },
    setDashboardIsLoading: (state, action) => {
      state.isLoading = action.payload as boolean;
    },
  },
});

export const { setDatesRange, setCountryState, setDashboardIsLoading } =
  dashboardSlice.actions;
export default dashboardSlice.reducer;
