export const TRANSLATIONS_ES = {
  auth: {
    title: "¡Te damos la bienvenida!",
    subtitle: "Ingresa a tu cuenta administrativa con",
    msgErrorValidToken:
      "Correo no autorizado, para acceder se requieren permisos adicionales.",
  },
  components: {
    navBar: {
      logoutButton: "Cerrar Sesión",
      settingButton: "Configuración",
    },
    table: {
      searchPlaceHolder: "Toca para Buscar...",
      filteringTitle: "Ordenar",
    },
    dateRangePicker: {
      from: "Desde",
      to: "Hasta",
      january: "Enero",
      february: "Febrero",
      march: "Marzo",
      april: "Abril",
      may: "Mayo",
      june: "Junio",
      july: "Julio",
      august: "Agosto",
      september: "Septiembre",
      october: "Octubre",
      november: "Noviembre",
      december: "Diciembre",
      sunday: "D",
      monday: "L",
      tuesday: "M",
      wednesday: "M",
      thursday: "J",
      friday: "V",
      saturday: "S",
    },
  },
  views: {
    clients: {
      title: "Clientes",
      exportButton: "Exportar",
      createButton: "Crear cuenta",
      enterButton: "Ingresar a cuenta",
      filterOptions: {
        recent: "Más reciente",
        older: "Más antiguo",
        alphabet: "A-Z",
        reverse: "Z-A",
        live: "Live",
        nolive: "No live",
      },
      table: {
        account: "Cuenta",
        state: "Estado",
        subscription: "Suscripción",
        creationDate: "Fecha de creación",
        country: "País",
        options: {
          active: "Activo",
          inactive: "Inactivo",
        },
        button: "Ver detalle",
      },
      tabs: {
        clients: "Clientes",
      },
      details: {
        firatv: "Fira TV",
        proseller: "Proseller",
        firaweb: "Fira web",
        cards: {
          livesCount: "Cantidad de lives",
          minutes: "Consumo en minutos",
          stores: "Número de tiendas",
          users: "Número de operarios",
        },
        details: "Ver detalles",
        edit: "Editar",
        createdOn: "Creada el ",
        contact: "Contacto",
        country: "País",
        state: "Estado",
        services: "Servicios",
        transfer: "Transferir propiedad",
        desactivate: "Desactivar cuenta",
        active: "Activo",
        inactive: "Inactivo",
        seeEvent: "Ver eventos",
        table: {
          storeName: "Nombre tiendas",
          operators: "Operarios",
          idStore: "ID de tienda",
          state: "Estado",
        },
        tableModal: {
          operatorName: "Nombre operario",
          state: "Estado",
          role: "Rol",
          idOper: "ID del operario",
        },
        editService: {
          title: "Edición de servicios",
          subtitle: "Activa o inactiva los servicios de la cuenta",
          managerWeb: "Manager web",
          descWeb1:
            "Al inactivar el interruptor anularás el uso de este producto y los permisos que vienen con él.",
          descWeb2: "Se enviará un correo al owner con los cambios realizados.",
          proseller: "Proseller",
          descproseller1:
            " Al activar el interruptor permitirás el uso de  este producto y los permisos que vienen con él.",
          descproseller2:
            "Se enviará un correo al owner con los cambios realizados.",
          firatv: "Fira TV",
          descfiratv1:
            " Al activar el interruptor permitirás el uso de  este producto y los permisos que vienen con él.",
          descfiratv2:
            "Se enviará un correo al owner con los cambios realizados.",
          messageSuccessEdit: "¡Se editaron los servicios de la cuenta!",
          messageErrorEdit:
            "¡No se editaron los servicios, intenta nuevamente!",
          save: "Guardar",
          cancel: "Cancelar",
        },
      },
      create: {
        header: "Crear nueva cuenta",
        title: "Informacion de la cuenta",
        subtitle:
          "Completa los datos generales de la cuenta para continuar con la operación.",
        optionFiraWeb: "Fira web",
        optionFiraTv: " Fira tv",
        optionProseller: "Proseller",
        form: {
          accountName: "Nombre de la cuenta*",
          phAccountName: "Ej: Fira Onlive",
          userName: "Usuario*",
          phUserName: "Ej: Fabio1234",
          email: "Correo electronico*",
          phEmail: "Correo@electrónico.com",
          cellphone: "Numero telefonico*",
          phMobilephone: "Número",
          password: "Contraseña*",
          phPassword: "Clave",
          passwordConfirm: "Confirmar contraseña*",
          ownerName: "Nombre de quien gestionará la cuenta*",
          phOwnerName: "Ej: Jack",
          ownerLastName: "Apellido*",
          phOwnerLastName: "Ej: Correa",
          url: "URL de la página web",
          phUrl: "Link",
          phSizes: "Selecciona el rango",
          peopleWork: "En que sector se desempeña @empresa*",
          phSector: "Selecciona el sector",
          sector: "En que sector se desempeña @empresa*",
          plan: "Plan*",
          phPlan: "Selecciona paquetes",
          optionCategory: "Tecnología",
        },
        optionEmployees: {
          option10: "1 a 10 empleados",
          option50: "11 a 50 empleados",
          option100: "51 a 100 empleados",
          option250: "101 a 250 empleados",
          option1000: "251 a 1.000 empleados",
          option5000: "1.001 a 5.000 empleados",
          option10000: "Más de 5.000 empleados",
        },
        create: "Crear cuenta",
        back: "Regresar",
        popupTitle:
          "¿Estás seguro de que deseas abandonar la creación de la cuenta?",
        popupSubTitle:
          "Al continuar perderás la información ingresada y si quieres continuar el proceso deberás iniciar de cero.",
        messageSuccess: "¡Has creado la cuenta exitosamente!",
        errorEmail: "Correo invalido, intenta nuevamente",
        errorPassword: "!UPS¡ parece que las contraseñas no coinciden",
        errorAccount: "Este usuario ya existe, intenta uno nuevo",
      },
    },
    dashboard: {
      title: "Dashboard",
      subtitle: "Visualiza el resumen de tus estadísticas",
      greetings: {
        goodMorning: "Buenos días",
        goodAfternoon: "Buenas tardes",
        goodEvening: "Buenas noches",
      },
      selectCountries: {
        all: "Todos los países",
        chile: "Chile",
        peru: "Perú",
        argentina: "Argentina",
        mexico: "México",
      },
      cards: {
        totalAudience: "Audiencia total",
        numberOfLives: "Cantidad de lives",
        testLives: "Lives de prueba",
        averageViews: "Promedio de visualizaciones",
        transmissionTime: "Tiempo de transmisión",
        vodTotal: "VOD totales",
        vodVisible: "VOD visibles",
        vodHidden: "VOD ocultos",
        averageTimeViews: "Tiempo de reproducción promedio",
        totalViews: "Total de reproducciones",
      },
      subContainer: {
        generalData: "Datos generales",
        subtitle: "Espacio para realizar en versus entre métricas especificas",
      },
    },
    events: {
      title: "Eventos",
      tableHeaders: {
        eventName: "Nombre del evento",
        date: "Fecha de transmisión",
        storeName: "Tienda",
        status: "Estado",
        country: "País",
        account: "Cuenta",
      },
      filterOptions: {
        recent: "Recientes",
        accountAlphabet: "Cuenta cliente",

        alphabet: "A-Z",
        reverse: "Z-A",
      },
      details: "Ver detalles",
      done: "Finalizado",
    },
    users: {
      title: "Usuarios Fira",
      details: "Ver perfil",
      btnCreate: "Crear usuario",
      tableHeaders: {
        userName: "Nombre de usuario",
        email: "Correo",
        rol: "Rol",
        lastSignIn: "Último ingreso",
      },
      filterOptions: {
        alphabet: "A-Z",
        reverse: "Z-A",
      },
      optionRole: {
        superAdmin: "Super admin",
        support: "Soporte",
        firaUser: "Fira user",
      },
      new: {
        title: "Crear un nuevo usuario fira",
        sectionEmail: {
          title: "Información del Usuario",
          subtitle:
            "Completa los datos generales del nuevo usuario para continuar con la operación.",
          email: "Correo electrónico",
          errorEmail: "No se encontró al usuario",
        },
        sectionRole: {
          title: "Rol del usuario",
          subtitle: "Elige el rol que ocupará el usuario que estás creando.",
          commercial: "Agente comercial",
          commercialDesc:
            "Este perfil se encargará de administrar las diferentes cuentas a su cargo.",
          support: "Soporte",
          supportDesc:
            "Este perfil estará encargado de prestar apoyo a los usuarios fira o a las cuentas que lo requieran.",
          marketing: "Marketing",
          marketingDesc:
            "Este perfil solo podrá ver datos de los dashboards para el desempeño de sus labores.",
        },
        back: "Regresar",
        next: "Siguiente",
        invite: "Invitar",
        successMessage: "¡Creaste al usuario exitosamente!",
        popupTitle:
          "¿Estas seguro de que deseas abandonar la creación de usuario?",
        popupMessage:
          "Al continuar perderás la información ingresada y si quieres continuar el proceso deberás iniciar de cero.",
        popupCancel: "Cancelar",
        popupContinue: "Continuar",

        errorMessages: {
          pending: "Ya existe otra invitación pendiente para el usuario",
          errorOwner:
            "Los usuarios gerentes deben ser creados por el propietario de la cuenta",
          userExist: "¡El usuario ya existe!",
          errorService: "Error: por favor intente nuevamente",
        },
      },
    },
  },
  widgets: {
    interactions: {
      title: "Interacciones",
      subtitle: "En este espacio se podran visualizar todos los",
      subtitle_2: "Cantidad total según tipo de interacción",
      likes: "Me Gusta",
      shares: "Compartidos",
      messages: "Mensajes",
      clicks: "Clicks",
      total: "Total",
    },
  },
  toggle: {
    yes: "Si",
    no: "No",
    inactive: "Inactivo",
    active: "Activo",
  },
};
