import React, { useEffect, useState } from "react";
import Login from "../../components/Login/Login";
import styles from "./AuthView.module.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../utils/typesStates";
import MainLoader from "../../components/MainLoader/MainLoader";

import { AllUsersListService } from "../../services/utils";
import { setAllUsers } from "../../store/utils/utilsSlice";

const AuthView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { auth } = useSelector((state: RootState) => state);
  const [showError, setShowError] = useState<boolean>(false);

  const getAllUsers = async () => {
    try {
      const response = await AllUsersListService(auth.tokenOAuth);

      const users = response.data;
      dispatch(setAllUsers(users));
    } catch (error) {
      console.error("Error during getAll:", error);
    }
  };

  const onSuccessLogin = () => {
    getAllUsers();
    //Agregar logica de envio de token al back para validar
    navigate("/home");
  };

  useEffect(() => {
    auth.error && setShowError(true);
  }, [auth.error]);

  return (
    <div className={styles.Auth}>
      <div className={styles.square}>
        <Login onSuccessLogin={onSuccessLogin} showError={showError} />
        {auth.isLoading && <MainLoader />}
      </div>
    </div>
  );
};

export default AuthView;
