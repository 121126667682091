import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import FiraNavBar from "../../components/FiraNavBar/FiraNavBar";
import Button, { ButtonStyle } from "../../components/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import styles from "./UsersView.module.scss";
import {
  InfiniteTable,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  orderingType,
} from "../../components/Table/Table";
import Skeleton from "../../components/Skeleton/Skeleton";
import EmptyPieChartWidget from "../../components/FiraIcons/EmptyPieChartWidget";
import {
  OptionsSelect,
  UsersData,
  UsersFilter,
  UsersRole,
} from "../../utils/types.d";
import { useDebounce } from "../../utils/useDebounce";
import { useTranslation } from "react-i18next";
import { dateFormat } from "../../utils/handleDates";
import { UsersContext, UsersContextData } from "../../providers/UsersProvider";
import { ClientsFilter, FilterTypesClients } from "../../utils/typesClients.d";

const UsersView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filterValue, setFilterValue] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);

  const debounced = useDebounce(filterValue, 800);
  const { fetchUsers, isLoading, usersList } = useContext(
    UsersContext
  ) as UsersContextData;

  useEffect(() => {
    fetchUsers("DEFAULT", "0", "ASCEND_ALPHABET");
  }, []);

  useEffect(() => {
    setUsersListItem(usersList);
  }, [usersList]);

  const [optionValue, setOptionValue] = useState("none");
  const orderingList = [
    {
      id: 3,
      name: t("views.users.filterOptions.alphabet"),
      value: UsersFilter.ascend,
    },
    {
      id: 4,
      name: t("views.users.filterOptions.reverse"),
      value: UsersFilter.descend,
    },
  ];
  const [selectedFilter, setSelectedFilter] = useState<orderingType>(
    orderingList[0]
  );

  const usersHeader = [
    { name: "", size: "2%" },
    { name: t("views.users.tableHeaders.userName"), size: "15%" },
    { name: t("views.users.tableHeaders.email"), size: "25%" },
    { name: t("views.users.tableHeaders.rol"), size: "15%" },
    { name: t("views.users.tableHeaders.lastSignIn"), size: "15%" },
    { name: "", size: "25%" },
  ];

  const getUserRole = (rol: string) => {
    switch (rol) {
      case UsersRole.superAdmin:
        return t("views.users.optionRole.superAdmin");
        break;
      case UsersRole.firaUser:
        return t("views.users.optionRole.firaUser");
        break;
      case UsersRole.support:
        return t("views.users.optionRole.support");
        break;
    }
  };
  const [usersListItem, setUsersListItem] = useState<UsersData[]>([]);
  const handleClickCreate = () => {
    navigate("/home/users/new");
  };

  let titleSection;
  let createButton: JSX.Element;
  {
    titleSection = (
      <span className={`${styles.title}`}>{t("views.users.title")}</span>
    );

    createButton = (
      <Button key={"btncreate"}>
        <Link
          className={`${styles.LinkToNewUser} ${"lead-14-text"}`}
          onClick={handleClickCreate}
          to={"/home/users/new"}
        >
          {t("views.users.btnCreate")}
        </Link>
      </Button>
    );
  }

  const handleUsersFiltering = (event: ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    setFilterValue(keyword);
  };

  const handleOrdering = (id: number) => {
    const selected = orderingList.filter((p) => {
      return p.id === id;
    });
    setFilterValue("");
    setOptionValue("none");
    setSelectedFilter(selected[0]);
  };

  useEffect(() => {
    selectedFilter.value &&
      fetchUsers(FilterTypesClients.default, "none", selectedFilter.value + "");
  }, [selectedFilter]);

  const handleDebouncedFilter = () => {
    if (filterValue.length != 0) {
      fetchUsers("FIRST_NAME", filterValue, ClientsFilter.ascend);
    } else {
      fetchUsers(FilterTypesClients.default, "0000", ClientsFilter.ascend);
    }
  };

  useEffect(() => {
    firstLoad && handleDebouncedFilter();
  }, [debounced]);

  const body = usersListItem.map((user, i) => {
    const handleClickDetails = () => {
      //Pendiente agregar redireccion para ver detalles de usuario
    };

    return (
      <TableRow key={i}>
        <TableCell></TableCell>
        <TableCell>{user.firstName + " " + user.lastName}</TableCell>
        <TableCell centerContent>{user.email}</TableCell>
        <TableCell centerContent>{getUserRole(user.profile)}</TableCell>

        <TableCell centerContent>
          {user.lastSignedId && dateFormat(new Date(user.lastSignedId))}
        </TableCell>

        <TableCell RightContent>
          <div className={styles.buttonTd}>
            <Button
              clickHandler={handleClickDetails}
              buttonStyle={ButtonStyle.transparent}
              width="80px"
              height="48px"
            >
              <label className={styles.labelDetail}>
                {t("views.users.details")}
              </label>
            </Button>
          </div>
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
    );
  });
  const header = (
    <TableRow>
      {usersHeader.map((user, i) => (
        <TableHead key={i} size={user.size} centerContent={i !== 1}>
          {user.name}
        </TableHead>
      ))}
    </TableRow>
  );
  return (
    <>
      <FiraNavBar
        titleSection={titleSection}
        trailingOptions={[createButton]}
      ></FiraNavBar>
      <TableHeader
        hideCheckBox
        searchInputValue={filterValue}
        searchInputChangeHandle={handleUsersFiltering}
        orderingList={orderingList}
        orderingValue={selectedFilter}
        orderingClick={handleOrdering}
        optionSelected={optionValue}
      />
      <div className={styles.infiniteTableWrapper}>
        {isLoading ? (
          <InfiniteTable
            header={header}
            body={SkeletonTableBody}
            rowsNumber={usersListItem.length}
            loadData={() => ({})}
            rowLoader={<RowLoader />}
            hasMore={usersListItem.length >= 150}
          />
        ) : usersListItem.length > 0 ? (
          <InfiniteTable
            header={header}
            body={body}
            rowsNumber={usersListItem.length}
            loadData={() => ({})}
            rowLoader={<RowLoader />}
            hasMore={usersListItem.length >= 15}
          />
        ) : (
          <div className={styles.emptyInfiniteTableWrapper}>
            <EmptyUsersView />
          </div>
        )}
      </div>
    </>
  );
};

const EmptyUsersView = () => {
  return (
    <div className={styles.emptyWrapper}>
      <EmptyPieChartWidget />
    </div>
  );
};
const placeholder = [
  { id: 1, name: "placeholder" },
  { id: 2, name: "placeholder" },
  { id: 3, name: "placeholder" },
  { id: 4, name: "placeholder" },
  { id: 5, name: "placeholder" },
  { id: 6, name: "placeholder" },
  { id: 7, name: "placeholder" },
];
const SkeletonTableBody = placeholder.map((data) => {
  return (
    <TableRow key={data.id}>
      <TableCell size="8px"></TableCell>
      <TableCell>
        <Skeleton width="50px" height="50px" />
      </TableCell>
      <TableCell size="300px">
        <div className={styles.nameCell}>
          <Skeleton width="300px" />
        </div>
      </TableCell>
      <TableCell centerContent>
        <div className={`${styles.topCells} ${styles.selectVisibility} `}>
          <Skeleton />
        </div>
      </TableCell>
      <TableCell centerContent>
        <div className={styles.topCells}>
          <Skeleton width="200px" />
        </div>
      </TableCell>
      <TableCell size="50px">
        <Skeleton width="100px" />
      </TableCell>
      <TableCell size="50px">
        <Skeleton width="100px" />
      </TableCell>
      <TableCell size="5px"></TableCell>
    </TableRow>
  );
});

const RowLoader = () => {
  return (
    <table className={styles.fakeTable}>
      <tbody>
        <tr className={styles.fakeRow} key={"loader"}>
          <td
            className={`${styles.first} ${styles.fakeCell}`}
            style={{ width: "8px" }}
          ></td>
          <td
            className={styles.fakeCell}
            style={{ width: "57px", minWidth: "50px" }}
          >
            <Skeleton width="50px" height="50px" />
          </td>
          <td
            className={styles.fakeCell}
            style={{ width: "300px", minWidth: "300px" }}
          >
            {/* por alguna razon esta celda agrega un margen interno, asi que lo cancelamos con marginRight */}
            <div style={{ marginRight: "-41px", width: "fit-content" }}>
              <Skeleton width="300px" />
            </div>
          </td>
          <td className={styles.fakeCell} style={{ minWidth: "100px" }}>
            <div className={styles.centered}>
              <Skeleton />
            </div>
          </td>
          <td className={styles.fakeCell} style={{ minWidth: "200px" }}>
            <div className={styles.centered}>
              <Skeleton width="200px" />
            </div>
          </td>
          <td className={styles.fakeCell} style={{ minWidth: "200px" }}>
            <div className={styles.centered}>
              <Skeleton width="150px" />
            </div>
          </td>
          <td className={styles.fakeCell} style={{ minWidth: "200px" }}>
            <div className={styles.centered}>
              <Skeleton width="100px" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
export default UsersView;
