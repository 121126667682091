import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import "./ModalLayout.scss";
import { RootState } from "../../utils/typesStates";

export enum ModalLayoutAlign {
  left = "left",
  center = "center",
  right = "right",
}

interface ModalLayoutProps {
  children?: ReactNode;
  onClickHandle?(): void;
  align?: ModalLayoutAlign;
}
const ModalLayout = ({ children, onClickHandle, align }: ModalLayoutProps) => {
  const { utils } = useSelector((state: RootState) => state);

  return (
    <div
      onClick={onClickHandle}
      className={`ModalWrapper ${utils.navBarIsClosed ? "IsClosed" : ""} ${
        align == ModalLayoutAlign.right ? "rightAlign" : ""
      }`}
    >
      {children}
    </div>
  );
};

export default ModalLayout;
