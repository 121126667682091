import React from "react";
import { t } from "i18next";
import styles from "./ToggleSwitch.module.scss";

interface ToggleSwitchProps {
  handleChecked(): void;
  checked: boolean;
  hideTags?: boolean;
  optionFalse?: string;
  optionTrue?: string;
}

const ToggleSwitch = ({
  handleChecked,
  checked,
  hideTags,
  optionFalse = t("toggle.no") + "",
  optionTrue = t("toggle.yes") + "",
}: ToggleSwitchProps) => {
  return (
    <>
      <div className={styles.ToggleContainer}>
        {!hideTags && <span className={styles.h6}>{optionFalse}</span>}

        <div>
          <label className={styles.switch}>
            <input type="checkbox" onChange={handleChecked} checked={checked} />
            <span className={styles.slider}></span>
          </label>
        </div>
        {!hideTags && <span className={styles.h6}>{optionTrue}</span>}
      </div>
    </>
  );
};

export default ToggleSwitch;
