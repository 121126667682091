import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import ModalLayout from "../../../layout/ModalLayout/ModalLayout";
import styles from "./OperStoreModal.module.scss";
import {
  InfiniteTable,
  TableButton,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  orderingType,
} from "../../Table/Table";
import { useDebounce } from "../../../utils/useDebounce";
import {
  ClientContextData,
  ClientsFilter,
  Operator,
  StoreData,
} from "../../../utils/typesClients.d";
import { useTranslation } from "react-i18next";
import Skeleton from "../../Skeleton/Skeleton";
import EmptyPieChartWidget from "../../FiraIcons/EmptyPieChartWidget";
import Close from "../../FiraIcons/Close";
import Button, { ButtonStyle } from "../../Button/Button";
import noImageStoreSVG from "../../../assets/svg/no-image-store.svg";
import { ClientsContext } from "../../../providers/clientsProvider";
import { FiraAccountState } from "../../../utils/types.d";

interface OperStoreModalProps {
  store: StoreData | undefined;
  onClose(): void;
}

const OperStoreModal = ({ onClose, store }: OperStoreModalProps) => {
  const { t } = useTranslation();
  const [filterValue, setFilterValue] = useState("");
  const debounced = useDebounce(filterValue, 800);
  const [firstLoad, setFirstLoad] = useState(true);
  const { operatorList, getOperatorList } = useContext(
    ClientsContext
  ) as ClientContextData;
  const [operList, setOperList] = useState<Operator[]>([]);

  const handleDebouncedFilter = () => {
    if (filterValue.length != 0 && store) {
      getOperatorList(
        store?.storeId,
        filterValue,
        selectedFilter.value + "",
        0,
        5
      );
    } else {
      getOperatorList(
        store ? store?.storeId : "",
        "",
        selectedFilter.value + "",
        0,
        5
      );
    }
  };

  useEffect(() => {
    //62feb214a5086b6b207be67c/0/5/DESCEND_ALPHABET?name=AR
    store && getOperatorList(store?.storeId, "", ClientsFilter.descend, 0, 5);
  }, []);

  const handleEventFiltering = (event: ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    setFilterValue(keyword);
  };

  const handleOrdering = (id: number) => {
    const selected = orderingList.filter((p) => {
      return p.id === id;
    });
    setSelectedFilter(selected[0]);
  };

  const orderingList = [
    {
      id: 3,
      name: t("views.clients.filterOptions.alphabet"),
      value: ClientsFilter.ascend,
    },
    {
      id: 4,
      name: t("views.clients.filterOptions.reverse"),
      value: ClientsFilter.descend,
    },
  ];

  const [selectedFilter, setSelectedFilter] = useState<orderingType>(
    orderingList[0]
  );

  useEffect(() => {
    !firstLoad && handleDebouncedFilter();
  }, [debounced]);

  useEffect(() => {
    setFirstLoad(false);
    store &&
      getOperatorList(store?.storeId, "", selectedFilter.value + "", 0, 5);
  }, [selectedFilter]);

  const OperHeader = [
    { name: "", size: "8%" },
    { name: t("views.clients.details.tableModal.operatorName"), size: "20%" },
    { name: t("views.clients.details.tableModal.state"), size: "10%" },
    { name: t("views.clients.details.tableModal.role"), size: "20%" },
    { name: t("views.clients.details.tableModal.idOper"), size: "21%" },
    { name: "", size: "5%" },
  ];
  const header = (
    <TableRow>
      {OperHeader.map((head, i) => (
        <TableHead
          key={i}
          size={head.size}
          centerContent={i !== 1 && i != OperHeader.length - 1}
        >
          <label className={styles.titleHeaderTable}>{head.name}</label>
        </TableHead>
      ))}
    </TableRow>
  );
  const body = operatorList.map((oper, i) => {
    return (
      <TableRow key={i}>
        <TableCell>
          <div className={styles.tableImg}>
            <img src={oper.image ? oper.image : noImageStoreSVG} />
          </div>
        </TableCell>
        <TableCell>
          <label className={styles.labelItem}>{oper.name}</label>
        </TableCell>
        <TableCell centerContent>
          <label
            className={`${styles.itemStatus} ${
              oper.status === FiraAccountState.active
                ? styles.active
                : styles.inactive
            }`}
          >
            {oper.status === FiraAccountState.active
              ? t("views.clients.details.active")
              : t("views.clients.details.inactive")}
          </label>
        </TableCell>
        <TableCell centerContent>
          <label className={styles.labelItem}>{oper.role}</label>
        </TableCell>
        <TableCell centerContent>
          <label className={styles.labelItem}>{oper.id}</label>
        </TableCell>

        <TableCell></TableCell>
      </TableRow>
    );
  });

  const onCloseHandle = () => {
    onClose();
  };
  return (
    <ModalLayout>
      <div className={styles.OperStoreContainer}>
        <div className={styles.headerModal}>
          <label className={styles.title}>{store && store.storeName}</label>
          <Button
            buttonStyle={ButtonStyle.transparent}
            clickHandler={onCloseHandle}
            width="40px"
          >
            {<Close />}
          </Button>
        </div>
        <div
          style={{
            borderBottom: "1px solid var(--borde, #D5DDE0)",
            borderTop: "1px solid var(--borde, #D5DDE0)",
            width: "100%",
          }}
        >
          <TableHeader
            hideCheckBox
            searchInputValue={filterValue}
            searchInputChangeHandle={handleEventFiltering}
            orderingList={orderingList}
            orderingValue={selectedFilter}
            orderingClick={handleOrdering}
          />
        </div>

        {operatorList.length > 0 ? (
          <div className={styles.infiniteTableWrapper}>
            <InfiniteTable
              header={header}
              body={operatorList.length === 0 ? SkeletonTableBody : body}
              rowsNumber={operatorList.length}
              loadData={() => ({})}
              rowLoader={<RowLoader />}
              hasMore={operatorList.length >= 20}
            />
          </div>
        ) : (
          <div className={styles.emptyInfiniteTableWrapper}>
            <EmptyClientsView />
          </div>
        )}
      </div>
    </ModalLayout>
  );
};

const RowLoader = () => {
  return (
    <table className={styles.fakeTable}>
      <tr className={styles.fakeRow} key={"loader"}>
        <td
          className={`${styles.first} ${styles.fakeCell}`}
          style={{ width: "8px" }}
        ></td>
        <td
          className={styles.fakeCell}
          style={{ width: "57px", minWidth: "50px" }}
        >
          <Skeleton width="50px" height="50px" />
        </td>
        <td
          className={styles.fakeCell}
          style={{ width: "300px", minWidth: "300px" }}
        >
          {/* por alguna razon esta celda agrega un margen interno, asi que lo cancelamos con marginRight */}
          <div style={{ marginRight: "-41px", width: "fit-content" }}>
            <Skeleton width="300px" />
          </div>
        </td>
        <td className={styles.fakeCell} style={{ minWidth: "100px" }}>
          <div className={styles.centered}>
            <Skeleton />
          </div>
        </td>
        <td className={styles.fakeCell} style={{ minWidth: "200px" }}>
          <div className={styles.centered}>
            <Skeleton width="200px" />
          </div>
        </td>
        <td
          className={`${styles.last} ${styles.fakeCell}`}
          style={{ width: "50px" }}
        ></td>
      </tr>
    </table>
  );
};

const EmptyClientsView = () => {
  return (
    <div className={styles.emptyWrapper}>
      <EmptyPieChartWidget />
    </div>
  );
};

const placeholder = [
  { id: 1, name: "placeholder" },
  { id: 2, name: "placeholder" },
  { id: 3, name: "placeholder" },
  { id: 4, name: "placeholder" },
  { id: 5, name: "placeholder" },
  { id: 6, name: "placeholder" },
  { id: 7, name: "placeholder" },
];
const SkeletonTableBody = placeholder.map((data) => {
  return (
    <TableRow key={data.id}>
      <TableCell size="8px"></TableCell>
      <TableCell>
        <Skeleton width="50px" height="50px" />
      </TableCell>
      <TableCell size="300px">
        <div className={styles.nameCell}>
          <Skeleton width="300px" />
        </div>
      </TableCell>
      <TableCell centerContent>
        <div className={`${styles.topCells} ${styles.selectVisibility} `}>
          <Skeleton />
        </div>
      </TableCell>
      <TableCell centerContent>
        <div className={styles.topCells}>
          <Skeleton width="200px" />
        </div>
      </TableCell>
      <TableCell size="50px">
        <TableButton />
      </TableCell>
    </TableRow>
  );
});
export default OperStoreModal;
