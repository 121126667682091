import * as React from "react";
import { SVGProps } from "react";

const CalendarBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m15.528 4.615.001.6c2.204.172 3.66 1.674 3.662 3.977l.009 6.74c.003 2.511-1.574 4.056-4.103 4.06L8.921 20c-2.512.003-4.11-1.578-4.112-4.096l-.01-6.662c-.002-2.318 1.402-3.816 3.606-4.018l-.001-.6c0-.351.26-.616.607-.616.348 0 .609.263.61.615v.56l4.692-.007v-.56c-.002-.351.259-.615.606-.616.34 0 .609.263.61.615Zm-9.51 4.874 11.958-.016v-.28c-.034-1.719-.897-2.62-2.445-2.755l.001.616a.607.607 0 0 1-.607.616.602.602 0 0 1-.61-.615v-.647l-4.692.006v.647c0 .344-.259.616-.606.616a.601.601 0 0 1-.61-.615v-.615c-1.54.154-2.393 1.06-2.39 2.794v.248Zm8.574 3.634v.01a.65.65 0 0 0 .672.638.659.659 0 0 0-.025-1.32.662.662 0 0 0-.647.672Zm.652 3.59a.678.678 0 0 1-.657-.678.666.666 0 0 1 .647-.681h.008c.371 0 .672.303.672.678 0 .376-.3.68-.67.682Zm-3.906-3.577a.658.658 0 0 0 .68.639.658.658 0 0 0 .623-.689.653.653 0 0 0-.656-.638.682.682 0 0 0-.647.688Zm.683 3.541a.658.658 0 0 1-.68-.638c0-.368.283-.672.647-.689.355 0 .648.28.656.638a.658.658 0 0 1-.623.69Zm-3.938-3.513a.657.657 0 0 0 .68.639.657.657 0 0 0 .623-.689.652.652 0 0 0-.656-.638.682.682 0 0 0-.647.688Zm.684 3.518a.658.658 0 0 1-.68-.64c0-.367.283-.672.647-.688.355 0 .648.28.656.64a.657.657 0 0 1-.623.688Z"
      fill="#000"
    />
  </svg>
);

export default CalendarBlack;
