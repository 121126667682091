import { format } from "date-fns";
import * as Locales from "date-fns/locale";

export const locale = () => {
  if (navigator.language.includes("es")) {
    return Locales.es;
  } else {
    return Locales.enGB;
  }
};

export const dateFormat = (date: Date) => {
  return format(date, "dd/MM/yyyy");
};

export const hyphenDateFormat = (date: Date) => {
  return format(date, "dd-MM-yyyy");
};
export const timeFormat = (date: Date) => {
  return format(date, "hh:mm a", { locale: locale() });
};

export const timeFormatTest = (date: Date) => {
  return format(date, "dd-MM-yyyy hh:mm a", { locale: locale() });
};
export const timeFormatGraph = (date: Date) => {
  return format(date, "hh:mm ");
};

export const shortDate = (date: Date) => {
  return format(date, "dd MMM", { locale: locale() });
};

export const shortYearDate = (date: Date) => {
  return format(date, "dd MMM yyyy", { locale: locale() });
};

export const completeDate = (date: Date) => {
  return format(date, "dd MMMM yyyy", { locale: locale() });
};

export const timeZoneDate = (date: Date) => {
  return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS");
};

export const convertDateUTC = (date: Date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes()
  );
};

export const convertDateLocal = (date: Date) => {
  return new Date(format(date, "yyyy-MM-dd'T'HH:mm"));
};

export const inputDateFormat = (date: Date) => {
  return format(date, "yyyy-MM-dd");
};
