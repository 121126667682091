import * as React from "react";
import { SVGProps } from "react";

const UserMarketing = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={29}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="currentColor">
      <path d="M22.39 8c0 4.418-3.533 8-7.89 8-4.357 0-7.89-3.582-7.89-8s3.533-8 7.89-8c4.357 0 7.89 3.582 7.89 8ZM1.36 26.641C2.664 21.054 8.133 18 13.795 18h1.41c5.662 0 11.13 3.054 12.435 8.641a26.04 26.04 0 0 1 .565 3.362c.108 1.1-.782 1.997-1.871 1.997H2.666c-1.09 0-1.98-.898-1.871-1.997.112-1.15.313-2.28.565-3.362Z" />
    </g>
    <g fill="#fff">
      <path d="M21.547 24.07v-.546a.667.667 0 0 0-1.333 0v.554a2.52 2.52 0 0 0 1.334-.007ZM20.214 24.666v1.832c0 .344 0 .516.05.678.048.162.143.305.334.59l.045.068c.108.161.161.242.238.242.076 0 .13-.08.238-.242l.045-.068c.19-.285.285-.428.334-.59.05-.162.05-.334.05-.677v-1.84c-.44.1-.896.102-1.334.007Z" />
    </g>
  </svg>
);

export default UserMarketing;
