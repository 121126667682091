import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllCategories, getAllCountries } from "../../services/utils";

export const getAllCountriesActions = createAsyncThunk(
  "utils/getAllCountriesActions",
  async (token: string) => {
    const response = await getAllCountries(token);
    return response.data;
  }
);

export const getAllCategoriesActions = createAsyncThunk(
  "utils/getCategoriesActions",
  async (token: string) => {
    const response = await getAllCategories(token);
    return response.data;
  }
);
