/* eslint-disable react/react-in-jsx-scope */
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../utils/typesStates";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { setCountryState } from "../../store/dashboard/dashboardSlice";
import CustomSelect from "../CustomSelect/CustomSelect";
import { Countries, OptionsSelect } from "../../utils/types.d";

interface SelectProps {
  isLocalChange?: boolean;
  onChange(option: OptionsSelect): void;
  selectedValue: string;
}
const CountrySelect = ({
  isLocalChange,
  onChange,
  selectedValue,
}: SelectProps) => {
  const { dashboard, utils } = useSelector((state: RootState) => state);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [countrySelected, setCountrySelected] = useState(
    t("views.dashboard.selectCountries.all") + ""
  );

  const handleSelectRowOption = (option: OptionsSelect) => {
    isLocalChange ? onChange(option) : dispatch(setCountryState(option.value));

    setCountrySelected(option.name);
  };

  useEffect(() => {
    dispatch(setCountryState(Countries.none));
  }, []);

  useEffect(() => {
    if (selectedValue === "none") {
      setCountrySelected(t("views.dashboard.selectCountries.all") + "");
      dispatch(setCountryState(Countries.none));
    }
  }, [selectedValue]);

  useEffect(() => {
    rowOptions.map((item) => {
      if (item.value === dashboard.country) {
        setCountrySelected(item.name);
      }
    });
  }, [dashboard.country]);

  const [rowOptions, setRowOptions] = useState<OptionsSelect[]>([]);

  useEffect(() => {
    setRowOptions([]);
    setRowOptions((prevItems) => [
      ...prevItems,
      {
        id: 0,
        name: t("views.dashboard.selectCountries.all"),
        value: Countries.none,
      },
    ]);
    if (utils) {
      !utils.isLoading &&
        utils.countries.map((item, key) => {
          setRowOptions((prevItems) => [
            ...prevItems,
            {
              id: key + 1,
              name: item.name,
              value: item.name,
            },
          ]);
        });
    }
  }, [utils.countries]);
  return (
    <div
      style={
        isLocalChange
          ? { marginLeft: "10px", height: "44px" }
          : { marginRight: "10px" }
      }
    >
      <CustomSelect
        width="140px"
        height={isLocalChange ? "43px" : "32px"}
        selectId={"0"}
        options={rowOptions}
        value={countrySelected}
        handleSelected={handleSelectRowOption}
      />
    </div>
  );
};
export default CountrySelect;
