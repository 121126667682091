import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { RootState } from "../../utils/typesStates";
import { loginMicrosoft } from "../../store/auth/authActions";
import { useAppDispatch } from "../../store/Store";

const AuthGuard = () => {
  const { isLogging } = useSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();


  // const checkSession = async () => {
  //   if (userAccount) {
  //     try {
  //       const response = await instance.acquireTokenSilent({
  //         scopes: ["api://" + client_id + "/.default"],
  //       });

  //       dispatch(loginMicrosoft(response.accessToken));
  //     } catch (error) {
  //       // El token no es válido o ha expirado
  //       console.error("Error obtaining access token:", error);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (!userAccount) {
  //     checkSession();
  //   }
  // }, [userAccount]);

  return isLogging ? <Outlet /> : <Navigate to="/" replace />;
};

export default AuthGuard;
