import { createSlice } from "@reduxjs/toolkit";
import { UtilsState } from "../../utils/typesStates";
import { AllCountries, CategoryItem } from "../../utils/types";
import {
  getAllCategoriesActions,
  getAllCountriesActions,
} from "./utilsActions";

const initialState: UtilsState = {
  navBarIsClosed: false,
  screenWidth: 0,
  countries: [],
  categories: [],
  isLoading: false,
  allUsers: [],
};

export const utilSlice = createSlice({
  name: "utils",
  initialState,
  reducers: {
    setNavbarState: (state, action) => {
      state.navBarIsClosed = action.payload as boolean;
    },
    setScreenWidthState: (state, action) => {
      state.screenWidth = action.payload as number;
      state.navBarIsClosed = action.payload <= 1200;
    },
    setAllUsers: (state, action) => {
      state.allUsers = action.payload as string[];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCountriesActions.pending, (state) => {
        state.isLoading = true;
        state.error = false;
      })
      .addCase(getAllCountriesActions.fulfilled, (state, action) => {
        state.isLoading = false;

        state.countries = action.payload as AllCountries[];
      })
      .addCase(getAllCountriesActions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(getAllCategoriesActions.pending, (state) => {
        state.isLoading = true;
        state.error = false;
      })
      .addCase(getAllCategoriesActions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categories = action.payload as CategoryItem[];
      })
      .addCase(getAllCategoriesActions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const { setNavbarState, setScreenWidthState, setAllUsers } =
  utilSlice.actions;
export default utilSlice.reducer;
