import * as React from "react";
import { SVGProps } from "react";

const Close = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="#000">
      <path d="M15 3a1.414 1.414 0 0 0-2-2L1.001 13a1.414 1.414 0 0 0 2 2l12-12Z" />
      <path d="M1 3a1.414 1.414 0 0 1 2-2l11.998 12a1.414 1.414 0 0 1-2 2L1 3Z" />
    </g>
  </svg>
);

export default Close;
