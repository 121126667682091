import { NextEventList } from "../utils/types";
import {
  AccountOwner,
  BusinessClient,
  BusinessClientCreate,
  GeneralData,
  Operator,
  ProductsOn,
  StoreData,
} from "../utils/typesClients";
import { apiManagement } from "./api";

export const clientListService = (
  filterType: string,
  valueFilter: string,
  sortType: string
) => {
  return apiManagement.get<BusinessClient[]>(
    `/v1/super-admin/business-client/fetch-collections/${filterType}/${valueFilter}/${sortType}`
  );
};
///v1/super-admin/business-client/fetch-collections/{filterType}/{statusFilter}/{valueFilter}/{sortType}

// /v1/super-admin/account-owner-info/{accountId}
export const acountOwnerInfoService = (accountId: string) => {
  return apiManagement.get<AccountOwner>(
    `/v1/super-admin/account-owner-info/${accountId}`
  );
};

export const toggleSignupExpress = (accountId: string) => {
  return new Promise<{ message: "ENABLED" | "DISABLED" }>((resolve, reject) => {
    apiManagement
      .put<{ message: "ENABLED" | "DISABLED" }>(
        `/v1/super-admin/business-client/update-sign-on-express/${accountId}`
      )
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

// /v1/super-admin/get-stores-info/{accountId}/{offSet}/{limit}/{sortType}

export const getStoreInfoService = (
  accountId: string,
  sortType: string,
  offSet: number,
  limit: number,
  storeName: string
) => {
  const queryParams = new URLSearchParams({
    storeName: storeName,
  });
  let urlService = "";
  storeName !== ""
    ? (urlService = `/v1/super-admin/get-stores-info/${accountId}/${offSet}/${limit}/${sortType}?${queryParams}`)
    : (urlService = `/v1/super-admin/get-stores-info/${accountId}/${offSet}/${limit}/${sortType}`);

  return apiManagement.get<StoreData[]>(urlService);
};
// v1/super-admin/last-events/64e414ff64f8913df746c2bd
export const getLastEventService = (accountId: string) => {
  return apiManagement.get<NextEventList[]>(
    `/v1/super-admin/last-events/${accountId}`
  );
};

///v1/super-admin/general-account-info/{accountId}

export const generalAccountInfoService = (accountId: string) => {
  return apiManagement.get<GeneralData>(
    `/v1/super-admin/general-account-info/${accountId}`
  );
};

// /v1/super-admin/operators-info-by-store/62feb214a5086b6b207be67c/0/5/DESCEND_ALPHABET?name=AR
export const getOperatorsInfoByStoreService = (
  accountId: string,
  sortType: string,
  offSet: number,
  limit: number,
  name: string
) => {
  const queryParams = new URLSearchParams({
    name: name,
  });
  let urlService = "";
  name !== ""
    ? (urlService = `/v1/super-admin/operators-info-by-store/${accountId}/${offSet}/${limit}/${sortType}?${queryParams}`)
    : (urlService = `/v1/super-admin/operators-info-by-store/${accountId}/${offSet}/${limit}/${sortType}`);

  return apiManagement.get<Operator[]>(urlService);
};

export const updateFiraProductsService = (
  accountId: string,
  body: string[]
) => {
  return apiManagement.put<ProductsOn>(
    `/v1/super-admin/business-client/update-fira-products/${accountId}`,
    body
  );
};

export const createAccountBusinessClientService = (
  body: BusinessClientCreate
) => {
  return apiManagement.post<any>(
    `/v1/super-admin/business-client/create`,
    body
  );
};
