import * as React from "react";
import { SVGProps } from "react";

const ArrowDownSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.098 7H4.902c-.751 0-1.172.753-.708 1.267L7.292 11.7c.36.4 1.055.4 1.416 0l3.098-3.433C12.27 7.753 11.849 7 11.098 7Z"
      fill="#000"
    />
  </svg>
);

export default ArrowDownSolid;
