import axios from "axios";

export const apiManagement = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_MANAGEMENT,
});

export const eventSourceApiActions = (url: string) => {
  const eventSource = new EventSource(
    `${process.env.REACT_APP_BASE_URL_ACTIONS}${url}`
  );
  return eventSource;
};
//REACT_APP_BASE_URL_CMS
export const apiCmsPrivate = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_CMS,
});
