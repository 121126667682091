import * as React from "react";
import { SVGProps } from "react";

const WorldSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.938 7.13a15.65 15.65 0 0 1 .724 4.25h3.335a10.352 10.352 0 0 0-2.175-5.775 11.212 11.212 0 0 1-1.884 1.525ZM12.602 15.135c1.477.082 2.924.463 4.256 1.121.348-1.181.543-2.405.582-3.638h-4.833l-.005 2.517ZM19.006 4.685a9.976 9.976 0 0 0-4.132-2.515 11.086 11.086 0 0 1 2.622 3.782 9.952 9.952 0 0 0 1.51-1.267ZM11.395 22v-5.623a9.897 9.897 0 0 0-3.84 1.032A9.394 9.394 0 0 0 11.396 22ZM6.493 18.024A9.937 9.937 0 0 0 4.96 19.29a9.979 9.979 0 0 0 4.166 2.537 11.097 11.097 0 0 1-2.632-3.804ZM17.938 16.868c.68.437 1.313.948 1.887 1.524a10.352 10.352 0 0 0 2.174-5.774h-3.346a15.642 15.642 0 0 1-.715 4.25ZM7.135 16.241a11.086 11.086 0 0 1 4.26-1.108v-2.515H6.563c.036 1.228.229 2.446.572 3.623ZM7.556 6.591c1.2.602 2.505.953 3.84 1.033V2a9.393 9.393 0 0 0-3.84 4.592ZM14.874 21.829a9.977 9.977 0 0 0 4.132-2.515 9.936 9.936 0 0 0-1.52-1.267 11.093 11.093 0 0 1-2.612 3.782ZM6.557 11.382h4.832V8.867A11.086 11.086 0 0 1 7.13 7.76a14.5 14.5 0 0 0-.572 3.622ZM12.602 2v5.624a9.781 9.781 0 0 0 3.835-1.047A9.386 9.386 0 0 0 12.602 2ZM5.344 12.618H2a10.352 10.352 0 0 0 2.15 5.75 11.173 11.173 0 0 1 1.902-1.523 15.648 15.648 0 0 1-.708-4.227ZM2 11.38h3.344c.041-1.436.28-2.859.708-4.227A11.17 11.17 0 0 1 4.151 5.63 10.353 10.353 0 0 0 2 11.38ZM12.602 16.377V22a9.387 9.387 0 0 0 3.835-4.576 9.782 9.782 0 0 0-3.835-1.047ZM9.123 2.17c-1.57.484-3 1.355-4.166 2.537.469.474.983.899 1.534 1.268A11.096 11.096 0 0 1 9.123 2.17ZM12.602 11.382h4.833a14.477 14.477 0 0 0-.582-3.639 10.963 10.963 0 0 1-4.25 1.121v2.518Z"
      fill="#D5DDE0"
    />
  </svg>
);

export default WorldSvg;
