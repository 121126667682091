import React, { useEffect } from "react";
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import AuthView from "./views/AuthView/AuthView";
import styles from "./App.module.scss";
import HomeLayout from "./layout/HomeLayout/HomeLayout";
import { useDispatch } from "react-redux";
import { setScreenWidthState } from "./store/utils/utilsSlice";
import ViewLayout from "./layout/ViewLayout/ViewLayout";

import ClientsView from "./views/ClientsView/ClientsView";
import DashboardView from "./views/DashboardView/DashboardView";
import DashboardProvider from "./providers/DashboardProvider";
import AuthGuard from "./auth/AuthGuard/AuthGuard";
import DetailAccountOwnerView from "./views/ClientsView/ClientDetailView/ClientDetailView";
import EventsView from "./views/EventsView/EventsView";
import ClientsProvider from "./providers/clientsProvider";
import EventsProvider from "./providers/EventsProvider";
import ClientCreateView from "./views/ClientsView/ClientCreateView/ClientCreateView";
import UsersView from "./views/UsersView/UsersView";
import UserCreateView from "./views/UsersView/UserCreateView/UserCreateView";
import UsersProvider from "./providers/UsersProvider";
import { UtilsProvider } from "./providers/UtilsProvider";

const App: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const state = location.state as { backgroundLocation: Location };

  const handleResize = () => {
    dispatch(setScreenWidthState(window.innerWidth));
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UtilsProvider>
      <div className={styles.App}>
        <ClientsProvider>
          <Routes location={state?.backgroundLocation || location}>
            <Route path="/" element={<AuthView />}></Route>

            <Route element={<AuthGuard />}>
              <Route path="home" element={<HomeLayout />}>
                <Route path="clients" element={<ViewLayout />}>
                  <Route index element={<ClientsView />}></Route>
                  <Route path=":id" element={<DetailAccountOwnerView />} />
                  <Route path="new" element={<ClientCreateView />} />
                </Route>

                <Route path="dashboard" element={<ViewLayout />}>
                  <Route
                    index
                    element={
                      <DashboardProvider>
                        <DashboardView />
                      </DashboardProvider>
                    }
                  ></Route>
                </Route>
                <Route path="events" element={<ViewLayout />}>
                  <Route
                    index
                    element={
                      <EventsProvider>
                        <EventsView />
                      </EventsProvider>
                    }
                  ></Route>
                </Route>
                <Route path="users" element={<ViewLayout />}>
                  <Route
                    index
                    element={
                      <UsersProvider>
                        <UsersView />
                      </UsersProvider>
                    }
                  ></Route>
                  <Route
                    path="new"
                    element={
                      <UsersProvider>
                        <UserCreateView />
                      </UsersProvider>
                    }
                  />
                </Route>
              </Route>
            </Route>
            <Route element={<Outlet />} />
          </Routes>
        </ClientsProvider>
      </div>
    </UtilsProvider>
  );
};

export default App;
