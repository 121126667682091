import {
  DashboardStatisticsResponse,
  StatisticsFiraTvByUserResponse,
  StatisticsFiraTvCountryResponse,
} from "../utils/types";

import { apiManagement } from "./api";

export const StatisticsEventService = (
  country: string,
  startDate: string,
  endDate: string
) => {
  return apiManagement.get<DashboardStatisticsResponse>(
    `/v1/super-admin/lives/dashboard-statistics/${country}/${startDate}/${endDate}`
  );
};

export const StatisticsCountryFiraTvService = (country: string) => {
  return apiManagement.get<StatisticsFiraTvCountryResponse>(
    `/v1/super-admin/fira-tv/get-summary-dashboard/${country}`
  );
};

export const StatisticsByUsersFiraTvService = (
  accountId: string,
  startDate: string,
  endDate: string
) => {
  return apiManagement.get<StatisticsFiraTvByUserResponse>(
    `/v1/super-admin/fira-tv/get-dashboard/${accountId}/${startDate}/${endDate}`
  );
};
