import * as React from "react";
import { SVGProps } from "react";

const PhoneSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 18H8V6h8m0-4H8a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2Z"
      fill="#D5DDE0"
    />
  </svg>
);

export default PhoneSvg;
