import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./DashboardView.module.scss";
import TabLayout from "../../layout/TabLayout/TabLayout";
import {
  AuthResponse,
  Countries,
  OptionsSelect,
  TabElement,
} from "../../utils/types.d";
import { RootState } from "../../utils/typesStates";
import { FiraNavBar } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import DateRangePickerComponent from "../../components/DateRangePickerComponent/DateRangePickerComponent";
import DashboardEventsView from "./DashboardEvents/DashboardEventsView";
import DashboardFiraTvView from "./DashboardFiraTv/DashboardFiraTvView";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { setCountryState } from "../../store/dashboard/dashboardSlice";
import { getAllCountriesActions } from "../../store/utils/utilsActions";
import { useAppDispatch } from "../../store/Store";

const getCurrentGreetings = (): string => {
  const currentHour = new Date().getHours();

  if (currentHour >= 0 && currentHour < 12) {
    return "views.dashboard.greetings.goodMorning";
  } else if (currentHour >= 12 && currentHour < 18) {
    return "views.dashboard.greetings.goodAfternoon";
  } else if (currentHour >= 18 && currentHour < 24) {
    return "views.dashboard.greetings.goodEvening";
  } else {
    return "views.dashboard.greetings.goodMorning";
  }
};

const DashboardView = () => {
  const { auth } = useSelector((state: RootState) => state);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllCountriesActions(auth.user.token));
  }, []);
  const clientsTabs: TabElement[] = [
    {
      titleTab: "Lives",
      element: <DashboardEventsView />,
      navbar: (
        <FiraNavBar
          titleSection={auth.user && <HeaderTitleGreetings user={auth.user} />}
          trailingOptions={[
            <CountrySelect key={"select"} />,

            <DateRangePickerComponent key={"date"} />,
          ]}
        ></FiraNavBar>
      ),
    },
    {
      titleTab: "Fira tv",
      element: <DashboardFiraTvView />,
      navbar: (
        <FiraNavBar
          titleSection={auth.user && <HeaderTitleGreetings user={auth.user} />}
          trailingOptions={<CountrySelect />}
        ></FiraNavBar>
      ),
    },
  ];

  return (
    <div>
      <TabLayout tabs={clientsTabs} />
    </div>
  );
};

const HeaderTitleGreetings: React.FC<{ user: AuthResponse }> = ({ user }) => {
  const [greeting, setGreeting] = useState(
    "views.dashboard.greetings.goodMorning"
  );

  const { t } = useTranslation();

  useEffect(() => {
    setGreeting(getCurrentGreetings());
  }, [setGreeting]);

  return (
    <div className={styles.GreetingsWrapper}>
      <div className={styles.TextWrapper}>
        <label className={styles.Title}>
          {t(greeting)},{" "}
          <b>
            {user.firstName
              ? user.firstName + " " + user.lastName
              : user.userName}
          </b>
        </label>
        <label className={styles.Info}>{t("views.dashboard.subtitle")}</label>
      </div>
    </div>
  );
};

const CountrySelect = () => {
  const { dashboard, utils } = useSelector((state: RootState) => state);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [countrySelected, setCountrySelected] = useState(
    t("views.dashboard.selectCountries.all") + ""
  );

  const handleSelectRowOption = (option: OptionsSelect) => {
    dispatch(setCountryState(option.value));
  };

  useEffect(() => {
    dispatch(setCountryState(Countries.none));
  }, []);

  useEffect(() => {
    rowOptions.map((item) => {
      if (item.value === dashboard.country) {
        setCountrySelected(item.name);
      }
    });
  }, [dashboard.country]);

  const [rowOptions, setRowOptions] = useState<OptionsSelect[]>([]);

  useEffect(() => {
    setRowOptions([]);
    setRowOptions((prevItems) => [
      ...prevItems,
      {
        id: 0,
        name: t("views.dashboard.selectCountries.all"),
        value: Countries.none,
      },
    ]);
    if (utils) {
      !utils.isLoading &&
        utils.countries.map((item, key) => {
          setRowOptions((prevItems) => [
            ...prevItems,
            {
              id: key,
              name: item.name,
              value: item.name,
            },
          ]);
        });
    }
  }, [utils.countries]);

  return (
    <div style={{ marginRight: "10px" }}>
      <CustomSelect
        width="140px"
        height="32px"
        selectId={"0"}
        options={rowOptions}
        value={countrySelected}
        handleSelected={handleSelectRowOption}
      />
    </div>
  );
};

export default DashboardView;
