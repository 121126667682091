import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { components, Theme } from "react-select";
import styles from "./SelectCheckbox.module.scss";
import DropdownIndicator from "./DropdownIndicator";

interface itemsOption {
  value: string;
  label: string;
}

interface SelectProps {
  name?: string;
  onChange(items: itemsOption): void;
  data: any[];
  itemSelected: itemsOption | undefined;
}
const SelectOptionBox = ({
  name,
  onChange,
  data,
  itemSelected,
}: SelectProps) => {
  const [selectedOption, setSelectedOption] = useState<itemsOption | null>(
    itemSelected || null
  );
  const { t } = useTranslation();
  //CUSTOM STYLE DEL COMPONENTE SELECT
  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      marginTop: 0,
      background: "#F7F8F9",
    }),
    container: (provided: any) => ({
      ...provided,
      width: "100%",
      fontSize: "14px",
      paddingTop: 0,
      paddingBottom: 0,
      color: "black",
    }),
    control: (provided: any) => ({
      ...provided,
      minHeight: "30px",
      height: "40px",
      boxShadow: "none",
      borderColor: "#e5e5e5",
      color: "#000000",
      fontWeigth: 500,

      "&:hover": {
        color: "#e5e5e5",
        border: "1px solid #ffde07",
      },
    }),
    input: (provided: any) => ({
      ...provided,
      margin: "0px",
      color: "black",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      height: "40px",
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      height: "30px",
      padding: "0 6px",
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      color: "#000000",
      fontWeight: 500,
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      //borderBottom: "1px dotted pink",
      color: "black",
      background: state.isSelected
        ? "#ffde07"
        : state.isFocused
        ? "#eeeeee"
        : "none",
    }),
  };

  useEffect(() => {
    //itemSelected
    itemSelected &&
      setSelectedOption({
        label: itemSelected?.label,
        value: itemSelected?.label,
      });
  }, []);
  const changeData = (item: any) => {
    onChange(item);
    setSelectedOption(item);
  };
  return (
    <div>
      <Select
        value={selectedOption}
        name={""}
        options={data}
        styles={customStyles}
        onChange={changeData}
        isSearchable={false}
        components={{
          DropdownIndicator,
        }}
        placeholder={""}
      />
    </div>
  );
};

export default SelectOptionBox;
