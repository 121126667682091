import { AuthResponse, RefreshTokenResponse } from "../utils/types";
import { apiManagement } from "./api";

export const loginMicrosoftService = (token: string) => {
  return apiManagement.put<AuthResponse>("/v1/super-admin/login-microsoft", {
    token,
  });
};

export const loginService = (token: string) => {
  return apiManagement.put<AuthResponse>("/v1/super-admin/login-google", {
    token,
  });
}

export const logoutService = (sessionId: string) => {
  return apiManagement.put(`/api/v1/fira-authentication/logout/${sessionId}`);
};

export const refreshTokenService = (sessionId: string) => {
  return apiManagement.put<RefreshTokenResponse>(
    `/v1/authentication-service/account-owner/refresh-token/${sessionId}`
  );
};
