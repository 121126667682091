import * as React from "react";
import { SVGProps } from "react";

const UserGroup = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 11.2a4 4 0 0 1 4 4V20H8v-4.8a4 4 0 0 1 4-4Zm-5.37 2.405c-.127.43-.202.872-.224 1.32L6.4 15.2V20H4v-3.6a2.8 2.8 0 0 1 2.495-2.784l.136-.011Zm10.74 0A2.801 2.801 0 0 1 20 16.4V20h-2.4v-4.8c0-.554-.08-1.09-.23-1.595ZM6.8 8.8a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm10.4 0a2 2 0 1 1 0 4 2 2 0 0 1 0-4ZM12 4a3.2 3.2 0 1 1 0 6.4A3.2 3.2 0 0 1 12 4Z"
      fill="#000"
    />
  </svg>
);

export default UserGroup;
