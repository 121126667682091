import * as React from "react";
import { Outlet } from "react-router-dom";

import "./ViewLayout.scss";

const ViewLayout = () => {
  return (
    <div className="ViewLayoutWrapper">
      <Outlet />
    </div>
  );
};

export default ViewLayout;
