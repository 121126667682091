import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import FiraNavBar from "../../../components/FiraNavBar/FiraNavBar";
import { useNavigate } from "react-router-dom";
import styles from "./ClientCreateView.module.scss";
import Input, { InputStyles } from "../../../components/Input/Input";
import Button, { ButtonStyle } from "../../../components/Button/Button";
import SelectCheckbox from "../../../components/SelectCheckBox/SelectCheckbox";
import {
  BusinessClientCreate,
  ClientContextData,
  FiraProducts,
  FiraSelectStyle,
  OptionEmployees,
  itemsOption,
} from "../../../utils/typesClients.d";
import { useTranslation } from "react-i18next";
import SelectOptionBox from "../../../components/SelectCheckBox/SelectOptionBox";
import FiraSelect from "../../../components/FiraSelect/FiraSelect";
import { PopUpAlertTypes } from "../../../utils/types.d";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store/Store";
import { phoneNumberFormat } from "../../../utils/phoneNumberFormat";

import {
  AlertType,
  usePopupAlert,
} from "../../../providers/AlertProvider/AlertProvider";
import { getAllCategoriesActions } from "../../../store/utils/utilsActions";
import { ClientsContext } from "../../../providers/clientsProvider";
import ModalLayout from "../../../layout/ModalLayout/ModalLayout";
import PopUpAlert, {
  PopUpButtonsTypes,
} from "../../../components/PopUpAlert/PopUpAlert";
import { useUtils } from "../../../providers/UtilsProvider";
import { CountryCode } from "../../../services/utils";

const ClientCreateView = () => {
  const { countryCodes } = useUtils();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const showAlert = usePopupAlert();
  const dispatch = useAppDispatch();
  const {
    responseCreate,
    createBusinessClient,
    isError,
    setError,
    loadingData,
  } = useContext(ClientsContext) as ClientContextData;
  const [isDisableForm, setIsDisableForm] = useState<boolean>(false);
  const { utils, auth } = useSelector((state: RootState) => state);
  const [invalidButton, setInvalidButton] = useState<boolean>(false);
  const [invalidUrl, setInvalidUrl] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [showErrorName, setShowErrorName] = useState<boolean>(false);
  const [showErrorPassword, setShowErrorPassword] = useState<boolean>(false);
  const [optionCategories, setOptionCategories] = useState<itemsOption[]>([]);
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [showOptionAlert, setShowOptionAlert] = useState<boolean>(false);
  const [isChangeForm, setIsChangeform] = useState<boolean>(false);
  const [selectedCode, setSelectedCode] = useState<CountryCode>();
  const [inputValues, setInputValues] = useState<BusinessClientCreate>({
    accountEmail: "",
    nameAccount: "",
    userName: "",
    country: "56",
    password: "",
    contactName: "",
    contactLastName: "",
    sizeType: "",
    sector: "",
    webPage: "",
    products: [],
  });
  const optionSelected: itemsOption[] = [
    {
      value: t("views.clients.create.form.phSizes"),
      label: t("views.clients.create.form.phSizes"),
    },
    {
      value: t("views.clients.create.form.phSector"),
      label: t("views.clients.create.form.phSector"),
    },
  ];
  const peopleWork: itemsOption[] = [
    {
      value: OptionEmployees.RANGE_1_TO_10,
      label: t("views.clients.create.optionEmployees.option10"),
    },
    {
      value: OptionEmployees.RANGE_11_TO_50,
      label: t("views.clients.create.optionEmployees.option50"),
    },
    {
      value: OptionEmployees.RANGE_51_TO_100,
      label: t("views.clients.create.optionEmployees.option100"),
    },
    {
      value: OptionEmployees.RANGE_101_TO_250,
      label: t("views.clients.create.optionEmployees.option250"),
    },
    {
      value: OptionEmployees.RANGE_251_TO_1000,
      label: t("views.clients.create.optionEmployees.option1000"),
    },
    {
      value: OptionEmployees.RANGE_1001_TO_5000,
      label: t("views.clients.create.optionEmployees.option5000"),
    },
    {
      value: OptionEmployees.RANGE_GREATER_THAN_5000,
      label: t("views.clients.create.optionEmployees.option10000"),
    },
  ];

  const planOptions: itemsOption[] = [
    {
      value: FiraProducts.firaWeb,
      label: t("views.clients.create.optionFiraWeb"),
      isSelected: true,
      isDisabled: true,
    },
    {
      value: FiraProducts.firaTv,
      label: t("views.clients.create.optionFiraTv"),
      isSelected: false,
      isDisabled: false,
    },
    {
      value: FiraProducts.proseller,
      label: t("views.clients.create.optionProseller"),
      isSelected: false,
      isDisabled: false,
    },
  ];

  const urlRegex = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /((([A-Za-z]{3,9}:(?:\/\/))(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)(\.){1,}[a-zA-Z0-9()]*\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=])*/gi
  );

  const isURL = (str: string) => {
    return urlRegex.test(str);
  };

  const handleBackButton = () => {
    onBack();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setIsChangeform(true);
    switch (name) {
      case "accountEmail":
        setShowError(false);
        setInputValues({
          ...inputValues,
          accountEmail: value,
        });
        break;
      case "nameAccount":
        setInputValues({
          ...inputValues,
          nameAccount: value,
        });
        break;
      case "userName":
        setShowErrorName(false);
        setInputValues({
          ...inputValues,
          userName: value,
        });
        break;
      case "country":
        setInputValues({
          ...inputValues,
          country: value,
        });
        break;
      case "password":
        setInputValues({
          ...inputValues,
          password: value,
        });
        break;
      case "passwordConfirm":
        setPasswordConfirm(value);
        if (value !== inputValues.password) {
          setShowErrorPassword(true);
        } else {
          setShowErrorPassword(false);
        }
        break;
      case "contactName":
        setInputValues({
          ...inputValues,
          contactName: value,
        });
        break;
      case "contactLastName":
        setInputValues({
          ...inputValues,
          contactLastName: value,
        });
        break;
      case "webPage":
        if (value !== "") {
          setInvalidUrl(!isURL(value));
        }
        setInputValues({
          ...inputValues,
          webPage: value,
        });
        break;
    }
  };

  const [mobilephone, setMobilephone] = useState<string>("");

  const phoneFormat = (mobile: string) => {
    const val = mobile.split(" ").join('');
    setInputValues((prevState) => ({
      ...prevState,
      phone: prevState.phone
        ? {
            ...prevState.phone,
            number: val,
          }
        : {
            number: val,
            country: "",
            operator: "",
            type: "MOBILE",
          },
    }));
  };

  const phoneMask = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMobilephone(phoneNumberFormat(value) || "");

    phoneFormat(value);
  };

  const handleCallingCodeSelect = (value: CountryCode) => {
    setSelectedCode(value);
    setInputValues((prevState) => ({
      ...prevState,
      country: value.name,
      phone: prevState.phone
        ? {
            ...prevState.phone,
            country: value.countryCode,
            operator: value.dialCode,
          }
        : {
            country: value.countryCode,
            operator: value.dialCode,
            number: "",
            type: "MOBILE",
          },
    }));
  };

  const handleInvalidForm = () => {
    if (
      inputValues.accountEmail === "" ||
      inputValues.userName === "" ||
      inputValues.password === "" ||
      inputValues.password === "" ||
      inputValues.contactName === "" ||
      inputValues.contactLastName === "" ||
      mobilephone === "" ||
      inputValues.nameAccount === "" ||
      passwordConfirm !== inputValues.password ||
      inputValues.sector === "" ||
      inputValues.sizeType === ""
    ) {
      setInvalidButton(true);
    } else {
      setInvalidButton(false);
    }
  };

  useEffect(() => {
    //
    dispatch(getAllCategoriesActions(auth.user.token));
    setIsDisableForm(false);
  }, []);

  useEffect(() => {
    if (utils) {
      !utils.isLoading &&
        utils.categories &&
        setOptionCategories(
          utils.categories.map((item, key) => ({
            value: item.description, // Convertir key a string si es necesario
            label: item.description,
          }))
        );
    }
  }, [utils.categories]);

  useEffect(() => {
    handleInvalidForm();
  }, [inputValues, passwordConfirm]);

  useEffect(() => {
    setInvalidButton(false);

    isError &&
      (responseCreate.message.includes("user name")
        ? setShowErrorName(true)
        : setShowError(true),
      showAlert(AlertType.error, responseCreate.message));
  }, [responseCreate.message, isError]);

  useEffect(() => {
    setInvalidButton(false);
    responseCreate.success &&
      (showAlert(AlertType.success, t("views.clients.create.messageSuccess")),
      navigate("/home/clients"));
  }, [responseCreate.success]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setInvalidButton(true);
    setShowErrorName(false);
    setShowError(false);
    createBusinessClient(inputValues);
  };

  const onChangeEmployees = (items: itemsOption) => {
    setInputValues({
      ...inputValues,
      sizeType: items.value,
    });
  };

  const onChangeSector = (items: itemsOption) => {
    setInputValues({
      ...inputValues,
      sector: items.value,
    });
  };

  const onChangeProducts = (items: itemsOption[]) => {
    setInputValues({
      ...inputValues,
      products: items.map((item) => {
        return item.value;
      }),
    });
  };

  const handleCancelPopup = () => {
    setShowOptionAlert(false);
  };
  const handleContinuePopup = () => {
    setShowOptionAlert(false);
    navigate("/home/clients");
  };

  const popUpButtons: PopUpButtonsTypes[] = [
    {
      title: t("views.users.new.popupContinue"),
      handleClick: handleContinuePopup,
      type: "border",
    },
    {
      title: t("views.users.new.popupCancel"),
      handleClick: handleCancelPopup,
      type: "fill",
    },
  ];

  const onBack = () => {
    isChangeForm ? setShowOptionAlert(isChangeForm) : navigate("/home/clients"),
      setError(false);
  };
  return (
    <div className={styles.ContainerLayout}>
      <FiraNavBar
        backButtonHandler={handleBackButton}
        titleSection={<label>{t("views.clients.create.header")}</label>}
        trailingOptions={[]}
      ></FiraNavBar>
      <div className={styles.CreateViewWrapper}>
        {showOptionAlert && (
          <ModalLayout>
            <PopUpAlert
              type={PopUpAlertTypes.warning}
              width={"620px"}
              title={t("views.clients.create.popupTitle") + ""}
              message={t("views.clients.create.popupSubTitle") + ""}
              buttons={popUpButtons}
            />
          </ModalLayout>
        )}
        <div className={styles.mainContainer}>
          <label className={styles.itemTextTitle}>
            {t("views.clients.create.title")}
          </label>
          <label className={styles.itemTextSubtitle}>
            {t("views.clients.create.subtitle")}
          </label>
          <form onSubmit={handleSubmit}>
            <div className={styles.containerForm}>
              <div className={styles.itemInput}>
                <label className={styles.itemText}>
                  {t("views.clients.create.form.accountName")}
                </label>
                <Input
                  inputName={"nameAccount"}
                  inputId={"nameAccount"}
                  width="100%"
                  height="40px"
                  inputStyle={InputStyles.profile}
                  value={inputValues.nameAccount}
                  onChange={handleInputChange}
                  isDisabled={isDisableForm}
                  placeholder={
                    t("views.clients.create.form.phAccountName") + ""
                  }
                />
              </div>
              <div className={styles.itemInput}>
                <label className={styles.itemText}>
                  {t("views.clients.create.form.userName")}
                </label>
                <Input
                  inputName={"userName"}
                  inputId={"userName"}
                  width="100%"
                  height="40px"
                  inputStyle={InputStyles.profile}
                  value={inputValues.userName}
                  onChange={handleInputChange}
                  isDisabled={isDisableForm}
                  placeholder={t("views.clients.create.form.phUserName") + ""}
                />
                {showErrorName && (
                  <p className={styles.errorMessage}>
                    {t("views.clients.create.errorAccount")}
                  </p>
                )}
              </div>
              <div className={styles.itemInput}>
                <label className={styles.itemText}>
                  {t("views.clients.create.form.email")}
                </label>
                <Input
                  inputName={"accountEmail"}
                  inputId={"accountEmail"}
                  width="100%"
                  height="40px"
                  inputStyle={InputStyles.profile}
                  value={inputValues.accountEmail}
                  onChange={handleInputChange}
                  isDisabled={isDisableForm}
                  placeholder={t("views.clients.create.form.phEmail") + ""}
                />
                {showError && (
                  <p className={styles.errorMessage}>
                    {t("views.clients.create.errorEmail")}
                  </p>
                )}
              </div>
              <div className={styles.itemInput}>
                <label className={styles.itemText}>
                  {t("views.clients.create.form.cellphone")}
                </label>
                <div className={styles.phoneNumberArea}>
                  <div className={styles.itemCode}>
                    <FiraSelect
                      currentSelection={selectedCode}
                      selectStyle={FiraSelectStyle.phonecode}
                      width="100%"
                      height="100%"
                      renderSelection={(val) =>
                        val ? <div>{val.dialCode}</div> : <div>00</div>
                      }
                    >
                      <>
                        {countryCodes.map((val, i) => {
                          return (
                            <div
                              key={i}
                              onClick={() => handleCallingCodeSelect(val)}
                              style={{
                                gap: "20px",
                                alignItems: "center",
                              }}
                            >
                              <img src={val.flagUrl} alt={val.name} />
                              {val.name}
                            </div>
                          );
                        })}
                      </>
                    </FiraSelect>
                  </div>
                  <div className={styles.itemNumber}>
                    <Input
                      inputName={"mobilephone"}
                      inputId={"mobilephone"}
                      inputStyle={InputStyles.profile}
                      value={mobilephone}
                      onChange={(e) => {
                        phoneMask(e);
                      }}
                      width="100%"
                      height="100%"
                      placeholder={
                        t("views.clients.create.form.phMobilephone") + ""
                      }
                      isDisabled={isDisableForm}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.itemInput}>
                <label className={styles.itemText}>
                  {t("views.clients.create.form.password")}
                </label>
                <Input
                  inputName={"password"}
                  inputId={"password"}
                  width="100%"
                  height="40px"
                  showPswButton={true}
                  type="password"
                  inputStyle={InputStyles.auth}
                  value={inputValues.password}
                  onChange={handleInputChange}
                  isDisabled={isDisableForm}
                  placeholder={t("views.clients.create.form.phPassword") + ""}
                />
              </div>
              <div className={styles.itemInput}>
                <label className={styles.itemText}>
                  {t("views.clients.create.form.passwordConfirm")}
                </label>
                <Input
                  inputName={"passwordConfirm"}
                  inputId={"passwordConfirm"}
                  width="100%"
                  height="40px"
                  showPswButton={true}
                  type="password"
                  inputStyle={InputStyles.auth}
                  value={passwordConfirm}
                  onChange={handleInputChange}
                  isDisabled={isDisableForm}
                  placeholder={t("views.clients.create.form.phPassword") + ""}
                />
                {showErrorPassword && (
                  <p className={styles.errorMessage}>
                    {t("views.clients.create.errorPassword")}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.containerForm}>
              <div className={styles.itemInput}>
                <label className={styles.itemText}>
                  {t("views.clients.create.form.ownerName")}
                </label>
                <Input
                  inputName={"contactName"}
                  inputId={"contactName"}
                  width="100%"
                  height="40px"
                  inputStyle={InputStyles.profile}
                  value={inputValues.contactName}
                  onChange={handleInputChange}
                  isDisabled={isDisableForm}
                  placeholder={t("views.clients.create.form.phOwnerName") + ""}
                />
              </div>
              <div className={styles.itemInput}>
                <label className={styles.itemText}>
                  {t("views.clients.create.form.ownerLastName")}
                </label>
                <Input
                  inputName={"contactLastName"}
                  inputId={"contactLastName"}
                  width="100%"
                  height="40px"
                  inputStyle={InputStyles.profile}
                  value={inputValues.contactLastName}
                  onChange={handleInputChange}
                  isDisabled={isDisableForm}
                  placeholder={
                    t("views.clients.create.form.phOwnerLastName") + ""
                  }
                />
              </div>
              <div className={styles.itemInput}>
                <label className={styles.itemText}>
                  {t("views.clients.create.form.peopleWork")}
                </label>
                <SelectOptionBox
                  onChange={onChangeEmployees}
                  data={peopleWork}
                  itemSelected={optionSelected[0]}
                />
              </div>
              <div className={styles.itemInput}>
                <label className={styles.itemText}>
                  {t("views.clients.create.form.sector")}
                </label>
                <SelectOptionBox
                  onChange={onChangeSector}
                  data={optionCategories}
                  itemSelected={optionSelected[1]}
                />
              </div>
              <div className={styles.itemInput}>
                <label className={styles.itemText}>
                  {t("views.clients.create.form.url")}
                </label>
                <Input
                  inputName={"webPage"}
                  inputId={"webPage"}
                  width="100%"
                  height="40px"
                  inputStyle={InputStyles.profile}
                  value={inputValues.webPage}
                  onChange={handleInputChange}
                  isDisabled={isDisableForm}
                  placeholder={t("views.clients.create.form.phUrl") + ""}
                  invalid={invalidUrl}
                />
              </div>
              <div className={styles.itemInput}>
                <label className={styles.itemText}>
                  {t("views.clients.create.form.plan")}
                </label>
                <SelectCheckbox
                  onChange={onChangeProducts}
                  itemsOptions={planOptions}
                />
              </div>
            </div>
            <div className={styles.containerButton}>
              <Button
                width="92px"
                height="32px"
                type={"reset"}
                buttonStyle={ButtonStyle.border}
                clickHandler={onBack}
                isDisabled={loadingData["loadCreate"]}
              >
                <span className="lead-14-text">
                  {" "}
                  {t("views.clients.create.back")}
                </span>
              </Button>
              <Button
                width="150px"
                height="32px"
                buttonStyle={ButtonStyle.main}
                type={"submit"}
                isDisabled={loadingData["loadCreate"] || invalidButton}
              >
                <span className="lead-14-text">
                  {t("views.clients.create.create")}
                </span>
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ClientCreateView;
