import React, { ReactElement } from "react";
import stylesheet from "./FiraUserRoleComponent.module.scss";
import { ItemRole } from "../../utils/types.d";

const {
  FiraUserRoleWrapper,
  Selected,
  Disabled,
  TitleWrapper,
  IconWrapper,
  TextWrapper,
  DescriptionWrapper,
  ComponentBorder,
  IconWrapperDisabled,
  TextWrapperDisabled,
  DescriptionWrapperDisabled,
  ComponentBorderDisabled,
} = stylesheet;

interface Props {
  role: ItemRole;
  onClick(role: string): void;
  isSelected?: boolean;
  isDisabled?: boolean;
}
const FiraUserRoleComponent: React.FC<Props> = ({
  role,
  onClick,
  isSelected = false,
  isDisabled = false,
}) => {
  const { icon, name, description } = role;
  return (
    <div
      className={`${FiraUserRoleWrapper} ${isSelected ? Selected : ""} ${
        isDisabled ? Disabled : ""
      }`}
      onClick={() => onClick(role.name)}
    >
      <div
        className={`${isDisabled ? ComponentBorderDisabled : ComponentBorder} ${
          isSelected ? Selected : ""
        } `}
      ></div>
      <div className={TitleWrapper}>
        <div className={isDisabled ? IconWrapperDisabled : IconWrapper}>
          {icon}
        </div>
        <div className={isDisabled ? TextWrapperDisabled : TextWrapper}>
          {name}
        </div>
      </div>

      <div
        className={isDisabled ? DescriptionWrapperDisabled : DescriptionWrapper}
      >
        {description}
      </div>
    </div>
  );
};

export default FiraUserRoleComponent;
