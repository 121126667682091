export const TRANSLATIONS_EN = {
  auth: {
    title: "Welcome!",
    subtitle: "Log in to your administrative account with",
    msgErrorValidToken:
      "Unauthorized mail, to access it is required additional permissions.",
  },
  components: {
    navBar: {
      logoutButton: "Sign off",
      settingButton: "Settings",
    },
    table: {
      searchPlaceHolder: "Tap to search...",
      filteringTitle: "Order",
    },
    dateRangePicker: {
      from: "From",
      to: "To",
      january: "January",
      february: "February",
      march: "March",
      april: "April",
      may: "May",
      june: "June",
      july: "July",
      august: "August",
      september: "September",
      october: "October",
      november: "November",
      december: "December",
      sunday: "S",
      monday: "M",
      tuesday: "T",
      wednesday: "W",
      thursday: "T",
      friday: "F",
      saturday: "S",
    },
  },
  views: {
    clients: {
      title: "Clients",
      exportButton: "Export",
      createButton: "Create account",
      enterButton: "Enter account",
      filterOptions: {
        recent: "Recent",
        older: "Older",
        alphabet: "A-Z",
        reverse: "Z-A",
        live: "Live",
        nolive: "No live",
      },
      table: {
        account: "Account",
        state: "Status",
        subscription: "Subscription",
        creationDate: "Creation Date",
        country: "Country",
        options: {
          active: "Active",
          inactive: "Inactive",
        },
        button: "See detail",
      },
      tabs: {
        clients: "Clients",
      },
      details: {
        firatv: "Fira TV",
        proseller: "Proseller",
        firaweb: "Fira web",
        cards: {
          livesCount: "Lives count",
          minutes: "Minutes consumed",
          stores: "Number of stores",
          users: "Number of operators",
        },
        details: "See details",
        edit: "Edit",
        createdOn: "Created on ",
        contact: "Contact",
        country: "Country",
        state: "State",
        services: "Services",
        transfer: "Transfer ownership",
        desactivate: "Deactivate account",
        active: "Active",
        inactive: "Inactive",
        seeEvent: "See events",
        table: {
          storeName: "Store name",
          operators: "Operators",
          idStore: "Store ID",
          state: "Status",
        },
        tableModal: {
          operatorName: "Operator name",
          state: "status",
          role: "Role",
          idOper: "Operator ID",
        },
        editService: {
          title: "Service Edition",
          subtitle: "Activate or deactivate account services",
          managerWeb: "Web manager",
          descWeb1:
            "By disabling the switch you will void the use of this product and the permissions that come with it.",
          descWeb2: "An email will be sent to the owner with the changes made.",
          proseller: "Proseller",
          descproseller1:
            "By toggling the switch you will allow the use of this product and the permissions that come with it.",
          descproseller2:
            "An email will be sent to the owner with the changes made.",
          firatv: "Fira TV",
          descfiratv1:
            "By toggling the switch you will allow the use of this product and the permissions that come with it.",
          descfiratv2:
            "An email will be sent to the owner with the changes made.",
          messageSuccessEdit: "Account Services Edited!",
          messageErrorEdit: "Services not edited, please try again!",
          save: "Save",
          cancel: "Cancel",
        },
      },

      create: {
        header: "Create a new account",
        title: "Account information",
        subtitle:
          "Complete the general account information to proceed with the operation.",
        optionFiraWeb: "Fira web",
        optionFiraTv: "Fira TV",
        optionProseller: "Proseller",
        form: {
          accountName: "Account Name*",
          phAccountName: "E.g. Fira Onlive",
          userName: "Username*",
          phUserName: "E.g. Fabio1234",
          email: "Email Address*",
          phEmail: "Email@address.com",
          cellphone: "Phone Number*",
          phCellphone: "Number",
          password: "Password*",
          phPassword: "Password",
          passwordConfirm: "Confirm Password*",
          ownerName: "Name of the person managing the account*",
          phOwnerName: "E.g. Jack",
          ownerLastName: "Last Name*",
          phOwnerLastName: "E.g. Correa",
          url: "Website URL",
          phUrl: "Link",
          phSizes: "Select the range",
          peopleWork: "In which sector does the company operate*",
          phSector: "Select the sector",
          sector: "In which sector does the company operate*",
          plan: "Plan*",
          phPlan: "Select packages",
          optionCategory: "Tecnology",
        },
        optionEmployees: {
          option10: "1 to 10 employees",
          option50: "11 to 50 employees",
          option100: "51 to 100 employees",
          option250: "101 to 250 employees",
          option1000: "251 to 1.000 employees",
          option5000: "1.001 to 5.000 employees",
          option10000: "More than 5.000 employees",
        },
        create: "Create Account",
        back: "Back",
        popupTitle: "Are you sure you want to abandon the account creation?",
        popupSubTitle:
          "By continuing you will lose the information entered and if you want to continue the process you will have to start from scratch.",
        messageSuccess: "You have successfully created the account!",
        errorEmail: "Invalid email, try again",
        errorPassword: "UPS! it seems that the passwords do not match",
        errorAccount: "This user already exists, try a new one",
      },
    },
    dashboard: {
      title: "Dashboard",
      subtitle: "View the summary of your statistics",
      greetings: {
        goodMorning: "Good morning",
        goodAfternoon: "Good afternoon",
        goodEvening: "Good evening",
      },
      selectCountries: {
        all: "All countries",
        chile: "Chile",
        peru: "Perú",
        argentina: "Argentina",
        mexico: "México",
      },
      cards: {
        totalAudience: "Total audience",
        numberOfLives: "Number of lives",
        testLives: "Test lives",
        averageViews: "Average views",
        transmissionTime: "Transmision time",
        vodTotal: "VOD total",
        vodVisible: "VOD visible",
        vodHidden: "VOD hidden",
        averageTimeViews: "Average view time ",
        totalViews: "Total views",
      },
      subContainer: {
        generalData: "General Data",
        subtitle: " Space to perform in versus between specific metrics",
      },
    },
    events: {
      title: "Events",
      tableHeaders: {
        eventName: "Event name",
        date: "Transmision date",
        storeName: "Store",
        status: "State",
        country: "Country",
        account: "Account",
      },
      filterOptions: {
        recent: "Recent",
        accountAlphabet: "Account",
        alphabet: "A-Z",
        reverse: "Z-A",
      },
      details: "See details",
      done: "Ended",
    },
    users: {
      title: "Fira users",
      details: "View profile",
      btnCreate: "Create user",
      tableHeaders: {
        userName: "Username",
        email: "Email",
        rol: "Role",
        lastSignIn: "Last sign in",
      },
      filterOptions: {
        alphabet: "A-Z",
        reverse: "Z-A",
      },
      optionRole: {
        superAdmin: "Super admin",
        support: "Soporte",
        firaUser: "Fira user",
      },
      new: {
        title: "Create a new Fira user",
        sectionEmail: {
          title: "User Information",
          subtitle:
            "Complete the general information of the new user to proceed with the operation.",
          email: "Email",
          errorEmail: "User not found",
        },
        sectionRole: {
          title: "User Role",
          subtitle: "Choose the role that the user you are creating will have.",
          commercial: "Commercial Agent",
          commercialDesc:
            "This profile will be responsible for managing different accounts under their care.",
          support: "Support",
          supportDesc:
            "This profile will be responsible for providing support to Fira users or accounts as needed.",
          marketing: "Marketing",
          marketingDesc:
            "This profile will only be able to view dashboard data for their job performance.",
        },
        back: "Back",
        next: "Next",
        invite: "Invite",
        successMessage: "You have successfully created the user!",
        popupTitle: "Are you sure you want to abandon user creation?",
        popupMessage:
          "By continuing, you will lose the entered information, and if you wish to proceed, you will need to start over.",
        popupCancel: "Cancel",
        popupContinue: "Continue",
        errorMessages: {
          pending: "There is another invitation pending for the user",
          errorOwner: "Manager users must be created by an account owner",
          userExist: "User exist!",
          errorService: "Error: please try again",
        },
      },
    },
  },
  widgets: {
    interactions: {
      title: "Interactions",
      subtitle: "In this space you can see all the",
      subtitle_2: "Total amount by type of interaction",
      likes: "Likes",
      shares: "Shares",
      messages: "Messages",
      clicks: "Clicks",
      total: "Total",
    },
  },
  toggle: {
    yes: "Yes",
    no: "No",
    inactive: "Inactive",
    active: "Active",
  },
};
