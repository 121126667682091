import React, { useState } from "react";
import styles from "./TabLayout.module.scss";
import { TabElement } from "../../utils/types";
import { isJSDoc } from "typescript";

/**
 * Description of Button component
 * @prop {function} clickHandler - handler for the action of the button
 * @prop {ButtonTypes} type - type of button to show: MAIN | BORDER | TRANSPARENT
 * @prop {boolean} isDisabled - tells the button to be disable
 * @prop {string} width - width of the button: px | em | %
 * @prop {string} height - height of the button: px | em | %
 * @prop {ReactElement} children - text in the button
 * @prop {string} className - the classname of the button
 */
interface Props {
  tabs: TabElement[];
}
const TabLayout: React.FC<Props> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const onTabPressed = (tabSelectedName: string) => {
    setActiveTab((previousTabSelected) => {
      const tabSelected = tabs.find((tab) => tab.titleTab === tabSelectedName);
      if (tabSelected) return tabSelected;

      return previousTabSelected;
    });
  };

  return (
    <div className={styles.TabWrapper}>
      {activeTab.navbar !== undefined ? activeTab.navbar : tabs[0].navbar}
      <div className={styles.TabsWrapper}>
        {tabs.map((tab, index) => (
          <TabButton
            text={tab.titleTab}
            tabIsActive={tab.titleTab === activeTab.titleTab}
            onTabSelected={onTabPressed}
            key={index}
          />
        ))}
      </div>
      <div className={styles.Content}>{activeTab.element}</div>
    </div>
  );
};

interface TabButtonProps {
  text: string;
  tabIsActive: boolean;
  onTabSelected(tabText: string): void;
}
export const TabButton: React.FC<TabButtonProps> = ({
  text,
  tabIsActive,
  onTabSelected,
}) => {
  return (
    <button
      className={`${styles.TabButtonWrapper} ${
        tabIsActive ? styles.Active : ""
      }`}
      onClick={() => onTabSelected(text)}
    >
      {text}
    </button>
  );
};

export default TabLayout;
