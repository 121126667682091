import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { Button, FiraNavBar } from "../../components";
import styles from "./EventsView.module.scss";
import { useTranslation } from "react-i18next";
import {
  InfiniteTable,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  orderingType,
} from "../../components/Table/Table";
import { useDebounce } from "../../utils/useDebounce";
import { EventsFilter } from "../../utils/typesClients.d";
import { EventStatus, OptionsSelect } from "../../utils/types.d";
import EmptyPieChartWidget from "../../components/FiraIcons/EmptyPieChartWidget";
import { ButtonStyle } from "../../components/Button/Button";

import Skeleton from "../../components/Skeleton/Skeleton";
import {
  EventsContext,
  EventsContextData,
} from "../../providers/EventsProvider";
import { dateFormat } from "../../utils/handleDates";
import { EventsData } from "../../utils/typesEvents";

const EventsView = () => {
  const { t } = useTranslation();
  const [filterValue, setFilterValue] = useState("");
  const debounced = useDebounce(filterValue, 800);
  const { isLoading, eventsList, getEventList, getNextEventList, errorEvents } =
    useContext(EventsContext) as EventsContextData;
  const [firstLoad, setFirstLoad] = useState(true);
  const [eventListItem, setEventListItem] = useState<EventsData[]>([]);
  const [pivot, setPivot] = useState(15);
  const [optionValue, setOptionValue] = useState("none");
  const eventsHeader = [
    { name: "", size: "5px" },
    { name: t("views.events.tableHeaders.eventName"), size: "100px" },
    { name: t("views.events.tableHeaders.date"), size: "100px" },
    { name: t("views.events.tableHeaders.account"), size: "50px" },
    { name: t("views.events.tableHeaders.storeName"), size: "50px" },
    { name: t("views.events.tableHeaders.country"), size: "15%" },
    { name: t("views.events.tableHeaders.status"), size: "50px" },
    { name: "", size: "50px" },
    { name: "", size: "5px" },
  ];

  const handleSelectRowOption = () => {
    //agregar opcion de ver detalle
  };

  const handleDebouncedFilter = () => {
    if (filterValue.length != 0) {
      setEventListItem([]);
      getEventList(0, 25, "NEWER_STREAM_DATE", "EVENT_NAME", filterValue);
    } else {
      getEventList(0, 25, "NEWER_STREAM_DATE", "", "");
    }
  };

  const orderingList = [
    {
      id: 1,
      name: t("views.events.filterOptions.recent"),
      value: EventsFilter.newerStreamDate,
    },
    {
      id: 2,
      name: t("views.events.filterOptions.accountAlphabet"),
      value: EventsFilter.ascendAlphabetAccount,
    },
    {
      id: 3,
      name: t("views.events.filterOptions.alphabet"),
      value: EventsFilter.ascendAlphabetEvent,
    },
    {
      id: 4,
      name: t("views.clients.filterOptions.reverse"),
      value: EventsFilter.descendAlphabetEvent,
    },
  ];
  const [selectedFilter, setSelectedFilter] = useState<orderingType>(
    orderingList[0]
  );

  const handleVideoFiltering = (event: ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    setFilterValue(keyword);
  };

  const handleOrdering = (id: number) => {
    const selected = orderingList.filter((p) => {
      return p.id === id;
    });
    setFilterValue("");
    setOptionValue("none");
    setSelectedFilter(selected[0]);
  };

  const onChangeOption = (option: OptionsSelect) => {
    //
    setFilterValue("");
    setEventListItem([]);

    setOptionValue(option.value);
    option.value === "none"
      ? getEventList(0, 15, "NEWER_STREAM_DATE", "", "")
      : getEventList(0, 25, "NEWER_STREAM_DATE", "COUNTRY", option.value);
  };

  const loadMoreData = () => {
    if (filterValue.length != 0) {
      getEventList(
        eventListItem.length + 1,
        25,
        "NEWER_STREAM_DATE",
        "EVENT_NAME",
        filterValue
      );
    } else {
      if (optionValue !== "none") {
        getNextEventList(
          eventListItem.length + 1,
          15,
          "NEWER_STREAM_DATE",
          "COUNTRY",
          optionValue
        );
      } else {
        getNextEventList(
          eventListItem.length + 1,
          15,
          selectedFilter.value + "",
          "",
          ""
        );
      }
    }
  };

  useEffect(() => {
    !firstLoad && handleDebouncedFilter();
  }, [debounced]);

  useEffect(() => {
    getEventList(0, 15, "NEWER_STREAM_DATE", "", "");
  }, []);

  useEffect(() => {
    !firstLoad &&
      (setEventListItem([]),
      getEventList(0, 15, selectedFilter.value + "", "", ""));
  }, [selectedFilter]);

  useEffect(() => {
    setEventListItem((prevState) => [...prevState, ...eventsList]);
    setFirstLoad(false);
    setPivot(eventListItem.length);
  }, [eventsList]);

  const body = eventListItem.map((event, i) => {
    const handleClickDetails = () => {
      //navigate(`${store.storeId}`);
      //setShowOperModal(true);
    };

    return (
      <TableRow key={i}>
        <TableCell></TableCell>
        <TableCell>{event.eventName}</TableCell>
        <TableCell centerContent>
          {event.startDate && dateFormat(new Date(event.startDate))}
        </TableCell>
        <TableCell centerContent>{event.accountName}</TableCell>
        <TableCell centerContent>{event.storeName}</TableCell>
        <TableCell centerContent>{event.country}</TableCell>
        <TableCell centerContent>
          {event.status === EventStatus.STARTED ? (
            <div className={styles.itemBadge}>
              <label className={styles.badgeLive}>Live</label>
            </div>
          ) : event.status === EventStatus.DONE ? (
            t("views.events.done")
          ) : (
            ""
          )}
        </TableCell>
        <TableCell RightContent>
          <div className={styles.buttonTd}>
            <Button
              clickHandler={handleClickDetails}
              buttonStyle={ButtonStyle.transparent}
              width="80%"
              height="48px"
            >
              <label className={styles.labelDetail}>
                {t("views.events.details")}
              </label>
            </Button>
          </div>
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
    );
  });
  const header = (
    <TableRow>
      {eventsHeader.map((event, i) => (
        <TableHead key={i} size={event.size} centerContent={i !== 1}>
          {event.name}
        </TableHead>
      ))}
    </TableRow>
  );
  const titleSection = (
    <span className={`${styles.title}`}>{t("views.events.title")}</span>
  );
  return (
    <div>
      <FiraNavBar titleSection={titleSection}></FiraNavBar>
      <TableHeader
        hideCheckBox
        searchInputValue={filterValue}
        searchInputChangeHandle={handleVideoFiltering}
        orderingList={orderingList}
        orderingValue={selectedFilter}
        orderingClick={handleOrdering}
        onChange={onChangeOption}
        optionSelected={optionValue}
      />
      <div className={styles.infiniteTableWrapper}>
        {isLoading ? (
          <InfiniteTable
            header={header}
            body={SkeletonTableBody}
            rowsNumber={eventListItem.length}
            loadData={() => ({})}
            rowLoader={<RowLoader />}
            hasMore={eventListItem.length >= 150}
          />
        ) : eventListItem.length > 0 ? (
          <InfiniteTable
            header={header}
            body={body}
            rowsNumber={eventListItem.length}
            loadData={() => loadMoreData()}
            rowLoader={<RowLoader />}
            hasMore={eventListItem.length >= 15 && !errorEvents}
          />
        ) : (
          <div className={styles.emptyInfiniteTableWrapper}>
            <EmptyEventsView />
          </div>
        )}
      </div>
    </div>
  );
};

const EmptyEventsView = () => {
  return (
    <div className={styles.emptyWrapper}>
      <EmptyPieChartWidget />
    </div>
  );
};
const placeholder = [
  { id: 1, name: "placeholder" },
  { id: 2, name: "placeholder" },
  { id: 3, name: "placeholder" },
  { id: 4, name: "placeholder" },
  { id: 5, name: "placeholder" },
  { id: 6, name: "placeholder" },
  { id: 7, name: "placeholder" },
];
const SkeletonTableBody = placeholder.map((data) => {
  return (
    <TableRow key={data.id}>
      <TableCell size="8px"></TableCell>
      <TableCell>
        <Skeleton width="50px" height="50px" />
      </TableCell>
      <TableCell size="300px">
        <div className={styles.nameCell}>
          <Skeleton width="300px" />
        </div>
      </TableCell>
      <TableCell centerContent>
        <div className={`${styles.topCells} ${styles.selectVisibility} `}>
          <Skeleton />
        </div>
      </TableCell>
      <TableCell centerContent>
        <div className={styles.topCells}>
          <Skeleton width="200px" />
        </div>
      </TableCell>
      <TableCell size="50px">
        <Skeleton width="100px" />
      </TableCell>
      <TableCell size="50px">
        <Skeleton width="100px" />
      </TableCell>
      <TableCell size="5px"></TableCell>
    </TableRow>
  );
});

const RowLoader = () => {
  return (
    <table className={styles.fakeTable}>
      <tbody>
        <tr className={styles.fakeRow} key={"loader"}>
          <td
            className={`${styles.first} ${styles.fakeCell}`}
            style={{ width: "8px" }}
          ></td>
          <td
            className={styles.fakeCell}
            style={{ width: "57px", minWidth: "50px" }}
          >
            <Skeleton width="50px" height="50px" />
          </td>
          <td
            className={styles.fakeCell}
            style={{ width: "300px", minWidth: "300px" }}
          >
            {/* por alguna razon esta celda agrega un margen interno, asi que lo cancelamos con marginRight */}
            <div style={{ marginRight: "-41px", width: "fit-content" }}>
              <Skeleton width="300px" />
            </div>
          </td>
          <td className={styles.fakeCell} style={{ minWidth: "100px" }}>
            <div className={styles.centered}>
              <Skeleton />
            </div>
          </td>
          <td className={styles.fakeCell} style={{ minWidth: "200px" }}>
            <div className={styles.centered}>
              <Skeleton width="200px" />
            </div>
          </td>
          <td className={styles.fakeCell} style={{ minWidth: "200px" }}>
            <div className={styles.centered}>
              <Skeleton width="150px" />
            </div>
          </td>
          <td className={styles.fakeCell} style={{ minWidth: "200px" }}>
            <div className={styles.centered}>
              <Skeleton width="100px" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
export default EventsView;
