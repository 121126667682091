import axios, { AxiosError } from "axios";
import { Dispatch, Store } from "redux";

import { RootState, store } from "../store/Store";
import { apiManagement, apiCmsPrivate } from "./api";
import { logoutAction, updateToken } from "../store/auth/authSlice";

import { logoutService, refreshTokenService } from "./authentication";
import { refreshTokenAction } from "../store/auth/authActions";

const interceptorSetup = (store: Store<RootState>) => {
  const { dispatch } = store;

  apiManagement.interceptors.request.use(
    (config) => {
      const { auth } = store.getState();
      const token = auth.user?.token as string;

      if (config.headers) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  apiCmsPrivate.interceptors.request.use(
    (config) => {
      const { auth } = store.getState();
      const token = auth.user?.token as string;

      if (config.headers) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  apiManagement.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error: Error | AxiosError) => {
      const { auth } = store.getState();
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          refreshTokenProcess(dispatch, auth.user.sessionId);
        }

        return Promise.reject(error);
      }
      auth.user.sessionId && logout(dispatch);
      return Promise.reject(error);
    }
  );
};

const refreshTokenProcess = async (dispatch: Dispatch, sessionId: string) => {
  try {
    const refreshedTokenResponse = await refreshTokenService(sessionId);

    const newToken = refreshedTokenResponse.data.token;
    dispatch(updateToken(newToken));
  } catch (refreshError) {
    if (axios.isAxiosError(refreshError) && refreshError.response) {
      if (refreshError.response.status === 400) {
        console.error("Error 400 :", refreshError);
        logout(dispatch);
      } else {
        console.error("Error axios al refrescar el token:", refreshError);
      }
    } else {
      console.error("Error al refrescar el token:", refreshError);
    }
  }
};

const logout = async (dispatch: Dispatch) => {
  dispatch(logoutAction());
};

export default interceptorSetup;
