import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { NavLink } from "../../utils/types";
import { RootState } from "../../utils/typesStates";
import "./HomeLayout.scss";
import FiraTopBar from "../../components/FiraTopBar/FiraTopBar";
import FiraSideBar from "../../components/FiraSideBar/FiraSideBar";
import FiraTopContextMenu from "../../components/FiraTopBar/FiraTopContextMenu/FiraTopContextMenu";
import Logout from "../../components/FiraIcons/Logout";
import { handleImageLoadError } from "../../utils/handleImageLink";
import SvgStoreIcon from "../../components/FiraIcons/StoreIcon";
import { logoutAction } from "../../store/auth/authSlice";
import { useAppDispatch } from "../../store/Store";
import Dashboard from "../../components/FiraIcons/Dashboard";
import CalendarMaterial from "../../components/FiraIcons/CalendarMaterial";
import CalendarBlack from "../../components/FiraIcons/CalendarBlack";
import UserGroup from "../../components/FiraIcons/UserGroup";
import { useUtils } from "../../providers/UtilsProvider";

const HomeLayout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [navButtons, setNavButtons] = useState<NavLink[]>([]);
  const dispatch = useAppDispatch();
  const {loadCountryCodes, countryCodes} = useUtils();

  const optionsMenuLinks = [
    {
      name: t("components.navBar.logoutButton"),
      icon: <Logout />,
      clickHandler: () => {
        logoutHandler();
      },
      to: "",
    },
  ];
  const clients: NavLink = {
    icon: <SvgStoreIcon />,
    name: t("views.clients.title"),
    to: "/home/clients",
  };
  const dashboard: NavLink = {
    icon: <Dashboard width={22} height={22} />,
    name: t("views.dashboard.title"),
    to: "/home/dashboard",
  };
  const events: NavLink = {
    icon: <CalendarBlack width={22} height={22} />,
    name: t("views.events.title"),
    to: "/home/events",
  };
  const users: NavLink = {
    icon: <UserGroup width={22} height={22} />,
    name: t("views.users.title"),
    to: "/home/users",
  };
  const logoutHandler = async () => {
    // instance.logoutRedirect();
    dispatch(logoutAction());
    //authentication.sessionId && (await logoutService(authentication.sessionId));
    //dispatch(logoutActionCreator());
  };

  const titleNavlinks = ["clients", "dashboard", "events"];

  const setValueItemPermissions = (item: string) => {
    setNavButtons((prevItems) => [...prevItems, dashboard]);
    setNavButtons((prevItems) => [...prevItems, clients]);
    setNavButtons((prevItems) => [...prevItems, events]);
    setNavButtons((prevItems) => [...prevItems, users]);
    navigate("dashboard");
  };

  useEffect(() => {
    setNavButtons([]);
    setValueItemPermissions("");
    if (countryCodes.length === 0) {
      loadCountryCodes();
    }
    /*titleNavlinks.map((key) => {
      setValueItemPermissions(key);
    });*/
  }, []);

  return (
    <>
      <FiraTopBar>
        <UserInfo options={optionsMenuLinks} />
      </FiraTopBar>
      <div className="Home">
        <FiraSideBar topNavButtons={navButtons} bottomNavButtons={[]} />
        <Outlet />
        {/*authentication.isLoading && <MainLoader />*/}
      </div>
    </>
  );
};

interface UserInfoProps {
  options: NavLink[];
}

const UserInfo = ({ options }: UserInfoProps) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const getInitials = () => {
    const firstName = user?.firstName.charAt(0);
    const lastName = user?.lastName.charAt(0);

    if (firstName && lastName) {
      return firstName + lastName;
    }
  };

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <>
      <div className={"UserSection"}>
        <div className={"FiraUserWrapper"} onClick={toggleMenu}>
          <div className={"FiraUserWrapper__Avatar"}>
            {user?.avatar ? (
              <img
                src={user?.avatar}
                alt="avatar"
                onError={handleImageLoadError}
              />
            ) : (
              getInitials()
            )}
          </div>

          <div className={"FiraUserWrapper__Info"}>
            {user?.firstName && user.lastName ? (
              <span>
                {user.firstName} {user.lastName}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        {openMenu && (
          <FiraTopContextMenu
            options={options}
            closeModal={() => setOpenMenu(false)}
          />
        )}
      </div>
    </>
  );
};

export default HomeLayout;
