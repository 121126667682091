import * as React from "react";
import { SVGProps } from "react";

const UserCommercial = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={29}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="currentColor">
      <path d="M22.39 8c0 4.418-3.533 8-7.89 8-4.357 0-7.89-3.582-7.89-8s3.533-8 7.89-8c4.357 0 7.89 3.582 7.89 8ZM1.36 26.641C2.664 21.054 8.133 18 13.795 18h1.41c5.662 0 11.13 3.054 12.435 8.641a26.04 26.04 0 0 1 .565 3.362c.108 1.1-.782 1.997-1.871 1.997H2.666c-1.09 0-1.98-.898-1.871-1.997.112-1.15.313-2.28.565-3.362Z" />
    </g>
    <g fill="#fff">
      <path d="M14.892 21.214a.5.5 0 0 1-.79 0l-1.146-1.477a.5.5 0 0 1 .396-.806h2.29a.5.5 0 0 1 .395.806l-1.145 1.477Z" />
      <path d="M14.017 21.72c.14-.48.82-.48.96 0l1.499 5.158a.5.5 0 0 1-.48.64h-2.998a.5.5 0 0 1-.48-.64l1.498-5.157Z" />
    </g>
  </svg>
);

export default UserCommercial;
