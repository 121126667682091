import React from "react";
import styles from "./Skeleton.module.scss";
React;
interface Props {
  width?: string;
  height?: string;
}

const Skeleton = (props: Props) => {
  const { width = "100px", height = "30px" } = props;

  const style = {
    height,
    width,
  };

  return (
    <div style={style} className={styles.slideset}>
      <div className={`${styles.child} ${styles.one}`}></div>
      <div className={`${styles.child} ${styles.two}`}></div>
    </div>
  );
};

export default Skeleton;
