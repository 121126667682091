import React, {
  CSSProperties,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { useClickOutside } from "../../utils/useClickOutside";

import styles from "./FiraSelect.module.scss";
import ArrowDownSolid from "../FiraIcons/ArrowDownSolid";

export interface FiraSelectOption {
  id?: string;
  name?: string;
  value?: any;
  icon?: string;
}

export enum FiraSelectStyle {
  default = "",
  outline = "Outline",
  profile = "Profile",
  phonecode = "Phonecode",
}

interface Props<T> {
  renderSelection(value?: T): React.ReactNode;
  width?: string;
  height?: string;
  currentSelection?: T;
  children?: ReactElement;
  selectStyle?: FiraSelectStyle;
  isDisabled?: boolean;
  isDateInput?: boolean;
}

function FiraSelect<T>(props: Props<T>): JSX.Element {
  const {
    width,
    height,
    children,
    currentSelection,
    selectStyle = FiraSelectStyle.default,
    isDisabled = false,
    isDateInput = false,
    renderSelection,
  } = props;
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const domNode = useClickOutside(() => setShowOptions(false));
  const selectedObjectRef = useRef<HTMLLIElement>(null);
  const selectClass = selectStyle || FiraSelectStyle.default;

  const classes = `${styles.Input} ${
    styles[selectClass as keyof typeof styles]
  } ${showOptions ? styles.OptionsOpen : ""}`;

  const handleShowOptions = () => {
    setShowOptions(!showOptions);
  };

  const scrollDown = () => {
    if (selectedObjectRef.current) {
      selectedObjectRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  useEffect(() => {
    if (isDateInput && currentSelection) {
      scrollDown();
    }
  }, [currentSelection]);

  return (
    <>
      <div
        style={{ width: width, height: height, position: "relative" }}
        ref={domNode}
      >
        <div
          className={`${classes} ${isDisabled ? styles.Disabled : ""}`}
          onClick={handleShowOptions}
        >
          {renderSelection(currentSelection)}
        </div>
        {props.children && (
          <>
            {showOptions && (
              <div
                onClick={() => setShowOptions(false)}
                className={styles.FiraSelect}
                style={{ top: height }}
              >
                <div className={styles.options}>{children}</div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default FiraSelect;
