import { ReactElement } from "react";
import type { To } from "react-router-dom";

export interface NavLink {
  name: string;
  icon: ReactElement;
  to: To;
  clickHandler?(): void;
  subLink?: subNavLink[];
}

export interface subNavLink {
  name: string;
  to: To;
}

export interface SettingSubElements {
  title: string;
  component: ReactElement;
  isActive?: boolean;
  id: string;
}

export interface SettingElements {
  title: string;
  subElements: SettingSubElements[];
}

export interface FiraUser {
  firstName: string;
  lastName: string;
  role: string;
  avatar: string;
  email: string;
}

export interface Credentials {
  username: string;
  password: string;
}

export interface LoginResponse {
  id: string;
  firstName: string;
  lastName: string;
  token: string;
  sessionId: string;
  username: string;
}

export interface AuthResponse {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  token: string;

  sessionId: string;
  userName: string;
  businessClientId?: string | null;
  permissionsStore?: [];
  avatar?: string;
}

export interface DashboardStatisticsResponse {
  audience: number;
  events: number;
  tests: number;
  likes: number;
  shares: number;
  clicks: number;
  messages: number;
  eventTime: number;
  averageVisualizationTime: number;
}

export interface StatisticsFiraTvCountryResponse {
  totalVods: number;
  visibleVods: number;
  hideVods: number;
}

export interface StatisticsFiraTvByUserResponse {
  totalAudience: number;
  averagePlayTime: number;
  totalPlayBack: number;
  likes: number;
  shares: number;
  clicks: number;
}

export enum FiraAccountState {
  active = "ACTIVE",
  onHold = "ONHOLD",
}

export interface TabElement {
  titleTab: string;
  element: ReactElement;
  navbar: ReactElement | undefined;
}

interface OptionsSelect {
  name: string;
  value: StatusSelectType;
  id: number;
}

export interface ProviderProps {
  children: React.ReactNode;
}

export enum Countries {
  none = "none",
  chile = "chile",
  peru = "peru",
  colombia = "colombia",
  mexico = "mexico",
  argentina = "argentina",
}

export interface AllCountries {
  alpha3Code: string;
  callingCodes: string[];
  name: string;
}

export enum EventStatus {
  SCHEDULED = "SCHEDULED",
  STARTED = "STARTED",
  NOT_STARTED = "NOT_STARTED",
  CANCELLED = "CANCELLED",
  DONE = "DONE",
}

export interface NextEventList {
  eventId: string;
  eventName: string | undefined;
  storeName: string;
  status: EventStatus;
}

export enum ServicesName {
  PROSELLER = "PROSELLER",
  FIRA_TV = "FIRA_TV",
  FIRA_WEB = "FIRA_WEB",
}

export interface CategoryItem {
  id: string;
  description: string;
}

export interface UsersData {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  profile: string;
  lastSignedId: string;
}

export enum UsersFilter {
  descend = "DESCEND_ALPHABET",
  ascend = "ASCEND_ALPHABET",
}
export enum UsersRole {
  firaUser = "FIRA_USER",
  open = "OPEN",
  accountOwner = "ACCOUNT_OWNER",
  superAdmin = "SUPER_ADMIN",
  internal = "INTERNAL",
  userStaff = "USER_STAFF",

  marketing = "MARKETING",
  commercial = "COMMERCIAL",
  support = "SUPPORT",
}

export interface ItemRole {
  icon: ReactElement;
  name: string;
  description: string;
}

export enum PopUpAlertTypes {
  error = "error",
  success = "success",
  warning = "warning",
}

export interface RefreshTokenResponse {
  id: string;

  firstName: string;

  birthdate: string;

  lastName: string;

  email: string;

  username: string;

  businessClientId: null;

  token: string;

  sessionId: "6545050f5d68596f60d9fe12";
}
