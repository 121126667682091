/* eslint-disable react/react-in-jsx-scope */
import { MouseEventHandler, ReactElement } from "react";
import "./Button.scss";

export enum ButtonStyle {
  main = "Main",
  border = "Border",
  transparent = "Transparent",
  inactivate = "Inactivate",
  load = "Load",
  light = "Light",
  danger = "Danger",
  delete = "Delete",
  borderYellow = "BorderYellow",
  borderRed = "BorderRed",
}
interface Props {
  clickHandler?: MouseEventHandler<HTMLButtonElement>;
  type?: "submit" | "reset" | "button" | undefined;
  buttonStyle?: ButtonStyle;
  isDisabled?: boolean;
  width?: string;
  height?: string;
  children?: ReactElement;
  className?: string;
}
/**
 * Description of Button component
 * @prop {function} clickHandler - handler for the action of the button
 * @prop {ButtonTypes} type - type of button to show: MAIN | BORDER | TRANSPARENT
 * @prop {boolean} isDisabled - tells the button to be disable
 * @prop {string} width - width of the button: px | em | %
 * @prop {string} height - height of the button: px | em | %
 * @prop {ReactElement} children - text in the button
 * @prop {string} className - the classname of the button
 */
const Button = (props: Props) => {
  const {
    className,
    clickHandler,
    type,
    buttonStyle = ButtonStyle.main,
    isDisabled = false,
    width = "128px",
    height = "30px",
    children,
  } = props;

  const size = {
    width: width,
    height,
  };
  return (
    <button
      disabled={isDisabled}
      className={`Button ${buttonStyle} ${className}`}
      onClick={clickHandler}
      type={type}
      style={size}
    >
      {children}
    </button>
  );
};

export default Button;
