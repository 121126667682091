import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { GOOGLE_CLIENT_ID } from "./utils/OAuthConfig";
import "./translations/i18n";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { store } from "./store/Store";
import { BrowserRouter } from "react-router-dom";
import { AlertProvider } from "./providers/AlertProvider/AlertProvider";
import interceptorSetup from "./services/interceptors";
import { GoogleOAuthProvider } from "@react-oauth/google";
export const persistor = persistStore(store);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AlertProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AlertProvider>
      </PersistGate>
    </Provider>
  </GoogleOAuthProvider>
);

interceptorSetup(store);
