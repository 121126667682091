import React, { createContext, useState } from "react";
import { ProviderProps, UsersData } from "../utils/types";
import { SendInvitationService, UsersListService } from "../services/users";

export interface UsersContextData {
  usersList: UsersData[];
  isLoading: boolean;
  fetchUsers: (
    filterType: string,
    valueFilter: string,
    sortType: string
  ) => void;
  sendInvitation: (username: string, email: string) => void;
  errorMessage: string | undefined;
  successMessage: string;
  setValuesProvider: () => void;
}

const UsersContext = createContext<UsersContextData | undefined>(undefined);

export const UsersProvider: React.FC<ProviderProps> = ({ children }) => {
  const [usersList, setUsersList] = useState<UsersData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  const fetchUsers = async (
    filterType: string,
    valueFilter: string,
    sortType: string
  ) => {
    try {
      setIsLoading(true);

      const response = await UsersListService(
        filterType,
        valueFilter,
        sortType
      );
      setIsLoading(false);
      setUsersList(response.data);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching clients:", error);
    }
  };

  const setValuesProvider = () => {
    setErrorMessage("");
    setSuccessMessage("");
  };

  const sendInvitation = async (username: string, email: string) => {
    try {
      setIsLoading(true);
      const response = await SendInvitationService(username, email);
      setIsLoading(false);
      setSuccessMessage(response.data);
    } catch (error: any) {
      setIsLoading(false);
      setErrorMessage(error + "");
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message as string);
      } else {
        setErrorMessage(error as string);
      }
      console.error("Error sending invitation:", error);
    }
  };

  const contextValue: UsersContextData = {
    usersList,
    isLoading,
    fetchUsers,
    sendInvitation,
    errorMessage,
    successMessage,
    setValuesProvider,
  };

  return (
    <UsersContext.Provider value={contextValue}>
      {children}
    </UsersContext.Provider>
  );
};

export default UsersProvider;
export { UsersContext };
