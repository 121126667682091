import React from "react";
import { FiraLiveLogo } from "../FiraIcons";
import Hamburger from "hamburger-react";
import styles from "./FiraTopBar.module.scss";
import { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNavbarState } from "../../store/utils/utilsSlice";
import { RootState } from "../../utils/typesStates";

interface FiraTopBarProps {
  children: ReactNode;
}

const FiraTopBar = ({ children }: FiraTopBarProps) => {
  const { utils } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const ToggleNavBarHandler = () => {
    dispatch(setNavbarState(!utils.navBarIsClosed));
  };

  return (
    <>
      <div className={`${styles.FiraTopBarWrapper}`}>
        <div className={styles.FiraTopBarWrapper__LeadingSection}>
          <div
            className={`${styles.FiraTopBarWrapper__LeadingSection__Hamburger}`}
          >
            <Hamburger
              toggled={!utils.navBarIsClosed}
              color={"#FFF"}
              size={20}
              onToggle={ToggleNavBarHandler}
            />
          </div>

          <div className={`${styles.FiraTopBarWrapper__LeadingSection__Logo}`}>
            <FiraLiveLogo />
          </div>
        </div>
        <div className={styles.FiraTopBarWrapper__TrailingSection}>
          {children}
        </div>
      </div>
    </>
  );
};

export default FiraTopBar;
