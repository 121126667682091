import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { Calendar, DateObject } from "react-multi-date-picker";

import CalendarMaterial from "../../components/FiraIcons/CalendarMaterial";
import Input from "../Input/Input";
import {
  completeDate,
  convertDateUTC,
  hyphenDateFormat,
  shortDate,
  shortYearDate,
  timeZoneDate,
} from "../../utils/handleDates";
import { RootState } from "../../utils/typesStates";
//import { setDateRangeActionCreator } from "../../store/Dashboard/DashboardActions";

import stylesheet from "./DateRangePickerComponent.module.scss";
import "./calendar-picker.scss";
import ArrowRight from "../FiraIcons/ArrowRight";
import ArrowLeft from "../FiraIcons/ArrowLeft";
import { setDatesRange } from "../../store/dashboard/dashboardSlice";
import { useAppDispatch } from "../../store/Store";
import { useSelector } from "react-redux";

const {
  DateRangePicker,
  pickerButton,
  opened,
  dateText,
  calendarContainer,
  selectFilter,
  rangeInputs,
  calendarRange,
  icon,
} = stylesheet;

const DateRangePickerComponent: React.FC = () => {
  const [opened, setOpened] = useState(false);
  const { firstDate, lastDate } = useSelector(
    (state: RootState) => state.dashboard
  );
  const dispatch = useAppDispatch();
  //const { firstDate, lastDate } = dashboard;
  const firstDateObj = new Date(firstDate);
  const lastDateObj = new Date(lastDate);
  const openRange = () => {
    setOpened(!opened);
  };

  const onClickOutsideListener = () => {
    setOpened(false);
    document.removeEventListener("click", onClickOutsideListener);
  };

  const showDate = () => {
    if (firstDateObj == lastDateObj) {
      return `${completeDate(lastDateObj ? lastDateObj : new Date())}`;
    } else {
      return `${shortDate(
        firstDateObj ? firstDateObj : new Date()
      )} - ${shortYearDate(lastDateObj ? lastDateObj : new Date())}`;
    }
  };

  const handleChangeDate = (firstDate: Date, lastDate: Date) => {
    dispatch(
      setDatesRange({
        firstDate: firstDate.getTime(),
        lastDate: lastDate.getTime(),
      })
    );
  };

  return (
    <div className={DateRangePicker}>
      <div
        className={`${pickerButton} ${opened ? opened : ""}`}
        onClick={openRange}
      >
        <CalendarMaterial className={icon} />
        <div className={dateText}>{"" + showDate()}</div>
      </div>
      {opened && (
        <div
          className={calendarContainer}
          onMouseLeave={() => {
            document.addEventListener("click", onClickOutsideListener);
          }}
          onMouseEnter={() => {
            document.removeEventListener("click", onClickOutsideListener);
          }}
        >
          <Personalized
            initDate={new Date()}
            endDate={new Date()}
            onChange={handleChangeDate}
          />
        </div>
      )}
    </div>
  );
};

interface PersonalizedProps {
  initDate: Date;
  endDate: Date;
  onChange(init: Date, end: Date): void;
}
const Personalized = ({ initDate, endDate, onChange }: PersonalizedProps) => {
  //const { brand } = useSelector((state: RootState) => state);
  const [startDate, setStartDate] = useState<Date>(initDate);
  const [endedDate, setEndedDate] = useState<Date>(endDate);
  const [values, setValues] = useState([new DateObject(), new DateObject()]);
  const { t } = useTranslation();
  const weekDays = [
    t("components.dateRangePicker.sunday"),
    t("components.dateRangePicker.monday"),
    t("components.dateRangePicker.tuesday"),
    t("components.dateRangePicker.wednesday"),
    t("components.dateRangePicker.thursday"),
    t("components.dateRangePicker.friday"),
    t("components.dateRangePicker.saturday"),
  ];
  const months = [
    t("components.dateRangePicker.january"),
    t("components.dateRangePicker.february"),
    t("components.dateRangePicker.march"),
    t("components.dateRangePicker.april"),
    t("components.dateRangePicker.may"),
    t("components.dateRangePicker.june"),
    t("components.dateRangePicker.july"),
    t("components.dateRangePicker.august"),
    t("components.dateRangePicker.september"),
    t("components.dateRangePicker.october"),
    t("components.dateRangePicker.november"),
    t("components.dateRangePicker.december"),
  ];

  const callRequest = (init: Date, end: Date | null) => {
    if (init && end) {
      end.setHours(23);
      end.setMinutes(59);
      callRequestMode(init, end);
    } else {
      if (init) {
        const newEnd = init;
        newEnd.setHours(23);
        newEnd.setMinutes(59);
        callRequestMode(init, newEnd);
      }
    }
  };

  const callRequestMode = (init: Date, end: Date) => {
    onChange(init, end);
    const current = new Date();
    current.setHours(0);
    current.setMinutes(0);
  };

  const handleChange = (objects: DateObject[]) => {
    if (objects[1]) {
      setEndedDate(new Date(objects[1].toString()));
    } else {
      setEndedDate(new Date(objects[0].toString()));
    }
    if (objects[0]) {
      setStartDate(new Date(objects[0].toString()));
    }

    callRequest(
      new Date(objects[0].toString()),
      objects[1] ? new Date(objects[1].toString()) : null
    );
  };

  return (
    <>
      <div className={rangeInputs}>
        <div>
          <h6>{t("components.dateRangePicker.from")}</h6>
          <Input
            key={"start"}
            value={hyphenDateFormat(startDate)}
            width="196px"
            height="36px"
            inputName="startDate"
            onChange={() => ({})}
          />
        </div>
        <div>
          <h6>{t("components.dateRangePicker.to")}</h6>
          <Input
            key={"end"}
            value={hyphenDateFormat(endedDate)}
            width="196px"
            height="36px"
            inputName="endedDate"
            onChange={() => ({})}
          />
        </div>
      </div>
      <div>
        <Calendar
          value={values}
          onChange={handleChange}
          numberOfMonths={2}
          range
          hideYear
          weekDays={weekDays}
          months={months}
          shadow={false}
          renderButton={CustomButton}
          className="yellow custom-calendar "
        />
      </div>
    </>
  );
};

const CustomButton = (
  direction: string,
  handleClick: any,
  disabled: boolean
) => {
  return (
    <div
      onClick={handleClick}
      style={{
        padding: "0 10px",
        fontWeight: "bold",
        color: disabled ? "black" : "black",
        cursor: "pointer",
      }}
      className={"cursor-pointer"}
    >
      {direction === "right" ? (
        <ArrowRight className="icon" />
      ) : (
        <ArrowLeft className="icon" />
      )}
    </div>
  );
};

export default DateRangePickerComponent;
