import React, { ChangeEvent, useState } from "react";
import styles from "./Checkbox.module.scss";

interface Props {
  clickHandler?(name?: string): void;
  onChange?(event: ChangeEvent<HTMLInputElement>): void;
  isActive?: boolean;
  isDisabled?: boolean;
  inputName?: string;
}
/**
 * Checkbox description
 * @prop {function} clickHandler - optional function to trigger a click in the Checkbox
 * @prop {boolean} isActive - set the Checkbox to an active state
 * @prop {boolean} isDisabled - disabled the Checkbox button so it cannot be used
 */
const SelectButton = (props: Props) => {
  const {
    clickHandler,
    isActive = false,
    isDisabled = false,
    inputName,
  } = props;
  const [isFocus, setIsFocus] = useState(false);
  const [change, setChanged] = useState(false);

  const handleChange = (e: Event) => {
    setChanged(true);
  };

  return (
    <div
      onClick={
        isDisabled
          ? undefined
          : () => {
              clickHandler && clickHandler(inputName);
            }
      }
      className={`${styles.CheckboxWrapper} ${
        isActive ? styles.IsActive : ""
      } ${isFocus ? styles.IsFocus : ""} ${
        isDisabled ? styles.IsDisabled : ""
      }`}
    >
      <input
        className={styles.Checkbox}
        name={inputName}
        type="checkbox"
        checked={isActive}
        onClick={
          isDisabled
            ? undefined
            : () => {
                clickHandler && clickHandler(inputName);
              }
        }
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        disabled={isDisabled}
        onChange={(e) => {
          handleChange;
        }}
      />
      <div className={styles.CheckboxButton}></div>
    </div>
  );
};

export default SelectButton;
