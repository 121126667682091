import React, { ChangeEvent, useState } from "react";
import styles from "./InputSearch.module.scss";
import { useTranslation } from "react-i18next";

export interface FiraInputSearchType {
  id?: string;
  name: string;
  value: string;
}

interface FiraInputSearchProps {
  placeholder?: string;
  options: FiraInputSearchType[];
  onSelect(option: FiraInputSearchType): void;
  onchange(event: ChangeEvent<HTMLInputElement>): void;
  onError(isError: boolean): void;
  currentValue: string;
}

const FiraInputSearch = ({
  placeholder,
  options,
  onSelect,
  onchange,
  currentValue = "",
  onError,
}: FiraInputSearchProps) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>(currentValue);
  const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false);
  const [isOptionsError, setIsOptionsError] = useState<boolean>(false);
  const [filteredOptions, setFilteredOptions] = useState<FiraInputSearchType[]>(
    []
  );

  const handleFiltering = (event: ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    onchange(event);
    setSearchValue(keyword);
    onError(true);
    if (keyword.trim() === "") {
      setFilteredOptions([]);
      setIsOptionsOpen(false);
      setIsOptionsError(false);
    } else {
      const matchingOptions = options.filter((option) =>
        option.value.toLowerCase().includes(keyword.toLowerCase())
      );

      setFilteredOptions(matchingOptions);
      setIsOptionsOpen(matchingOptions.length > 0);
      if (matchingOptions.length == 0) {
        setIsOptionsError(true);
      }
    }
  };

  const handleOptionClick = (option: FiraInputSearchType) => {
    setSearchValue(option.value + "");
    setIsOptionsOpen(false);
    onSelect(option);
    onError(false);
  };

  return (
    <div
      className={`${isOptionsOpen ? styles.isOpen : ""} ${
        styles.FiraInputSearchWrapper
      }`}
    >
      <div className={styles.FiraInputSearch}>
        <input
          width="100%"
          height="40px"
          type="text"
          placeholder={placeholder || ""}
          value={searchValue}
          onChange={handleFiltering}
        />
      </div>
      <span className={styles.inputPlaceholder}>@firaonlive.com</span>
      {isOptionsOpen && (
        <div className={styles.FiraInputSearchOptions}>
          {filteredOptions.map((option, index) => (
            <div
              className={`${styles.FiraInputSearchOptions__Item} `}
              key={index}
              onClick={() => handleOptionClick(option)}
            >
              {option.name}
            </div>
          ))}
        </div>
      )}
      {isOptionsError && (
        <p className={styles.errorMessage}>
          {t("views.users.new.sectionEmail.errorEmail")}
        </p>
      )}
    </div>
  );
};

export default FiraInputSearch;
