import React, {
  ChangeEvent,
  FocusEvent,
  HTMLInputTypeAttribute,
  MouseEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";

import "./Input.scss";
import LineCircleFill from "../FiraIcons/LineCircleFill";
import CheckCircleFill from "../FiraIcons/CheckCircleFill";
import Eye from "../FiraIcons/Eye";
import EyeClose from "../FiraIcons/EyeClose";

export enum InputTypes {
  string = "String",
  calendar = "Calendar",
}

export enum InputStyles {
  normal = "",
  auth = "AuthStyleInput",
  url = "URL",
  configuration = "Configuration",
  profile = "Profile",
}

interface Props {
  onChange?(event: ChangeEvent<HTMLInputElement>): void;
  onBlur?(event: FocusEvent<HTMLInputElement>): void;
  onFocus?(event: FocusEvent<HTMLInputElement>): void;
  type?: HTMLInputTypeAttribute | undefined;
  isDisabled?: boolean;
  value?: string;
  haveError?: boolean | undefined;
  isValidated?: boolean;
  width?: string;
  height?: string;
  icon?: ReactElement;
  biggerIcon?: boolean;
  showPswButton?: boolean;
  inputName?: string;
  inputId?: string;
  placeholder?: string;
  inputStyle?: InputStyles;
  isTextCenter?: boolean;
  onKeyDown?(event: React.KeyboardEvent<HTMLInputElement>): void;
  textLimit?: number;
  fontSize?: string;
  currency?: string;
  fontWeight?: string;
  invalid?: boolean;
  placeholderRight?: boolean;
}
/**
 * Description of Input component
 * @prop {function} onChange - handler for the action of the input
 * @prop {InputTypes} type - type of input to show: STRING | CALENDAR
 * @prop {boolean} isDisabled - tells the input to be disabled
 * @prop {string} value - tells the input value
 * @prop {boolean} isValidated - tells the input have validation
 * @prop {boolean | undefined} haveError - tells the input have an error
 * @prop {string} width - tells the input width
 * @prop {string} height - tells the input heigth
 * @prop {ReactElement} icon - react element to be use as a icon in the input
 * @prop {ReactElement} biggerIcon - boolean to increase icon size
 * @prop {boolean} showPswButton - tells the input activate button to preview password
 * @prop {string} inputName - the input name
 * @prop {string} inputId - the input id
 * @prop {KeyboardEvent} onKeyDown - adds a keydown event
 * @prop {boolean} isTextCenter - tells the text align
 * @prop {number} textLimit - tells the limit of characters
 * @prop {currency} currency - tells the currency to be used
 */
const Input = (props: Props) => {
  const {
    onChange,
    onBlur,
    onFocus,
    inputName,
    inputId,
    type,
    isDisabled = false,
    value = "",
    isValidated = false,
    haveError = false || null,
    icon,
    biggerIcon,
    height = "30px",
    width = "150px",
    showPswButton = false,
    placeholder = "",
    inputStyle = InputStyles.normal,
    onKeyDown,
    isTextCenter,
    textLimit,
    fontSize,
    currency,
    fontWeight,
    invalid = false,
    placeholderRight = false,
  } = props;

  const [stateType, setStateType] = useState(type);
  const inputRef = useRef<HTMLInputElement>(null);
  const currencyLabel = useRef<HTMLSpanElement>(null);
  const [inputPaddingLeft, setInputPaddingLeft] = useState("");
  let rightIconInput;
  let inputClass = "";
  let leftIconInput;
  let passwordButton;
  let circleProgress;
  const circleStyle = `radial-gradient(closest-side,white 73%,transparent 0 100%,white 0),
    conic-gradient(var(--fg) ${
      textLimit && (360 / textLimit) * value.length
    }deg, var(--bg) 0)`;

  if (isValidated) {
    if (haveError === true) {
      rightIconInput = (
        <span className="icon right">
          <LineCircleFill className="error" />
        </span>
      );
      inputClass = "Error";
    } else if (haveError === false) {
      rightIconInput = (
        <span className="icon right">
          <CheckCircleFill className="check" />
        </span>
      );
      inputClass = "Check";
    }
  }

  if (showPswButton) {
    const passwordTypeHandler = (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      inputRef.current?.focus();
      setStateType(stateType === "text" ? "password" : "text");
    };

    passwordButton = (
      <button
        type="button"
        onClick={passwordTypeHandler}
        className="passwordButton"
      >
        {stateType === "password" ? <Eye /> : <EyeClose />}
      </button>
    );
  }

  if (icon) {
    leftIconInput = (
      <span
        className={`icon left`}
        style={biggerIcon ? { fontSize: "24px" } : {}}
      >
        {icon}
      </span>
    );
  }

  if (textLimit) {
    circleProgress = (
      <div
        className="progress-circle icon right"
        style={{ background: circleStyle }}
      ></div>
    );
  }

  const style = {
    height,
    width,
    fontSize,
    fontWeight,
  };

  useEffect(() => {
    if (currencyLabel.current) {
      const element = currencyLabel.current;
      const width = element.offsetWidth;
      setInputPaddingLeft(`${width + 15}px`);
    }
  }, [currencyLabel, currency]);

  const input_styles = {
    paddingLeft: inputPaddingLeft,
  };

  const span_styles = {
    position: "absolute" as const,
    zIndex: 1,
    fontSize: "12px",
    left: `calc((${inputPaddingLeft} / 3))`,
  };

  return (
    <div className="InputWrapper" style={style}>
      {currency && (
        <span
          className="currency"
          id="currencyLabel"
          ref={currencyLabel}
          style={{ ...span_styles }}
        >
          {currency}
        </span>
      )}
      <input
        name={inputName}
        id={inputId}
        ref={inputRef}
        style={{ ...style, ...input_styles }}
        className={`Input ${inputClass} ${icon ? "left" : ""} ${
          showPswButton ? "right" : ""
        } ${inputStyle} ${isTextCenter ? "center-text" : ""} ${
          invalid ? "Invalid" : ""
        } ${placeholderRight ? "placeholderRight" : ""}`}
        disabled={isDisabled}
        type={stateType}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        maxLength={textLimit}
      />
      {circleProgress}
      {passwordButton}
      {leftIconInput}
      {rightIconInput}
    </div>
  );
};

export default Input;
