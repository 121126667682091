import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import FiraNavBar from "../../../components/FiraNavBar/FiraNavBar";
import { useTranslation } from "react-i18next";
import styles from "./UserCreateView.module.scss";
import { useNavigate } from "react-router-dom";
import Button, { ButtonStyle } from "../../../components/Button/Button";
import UserCommercial from "../../../components/FiraIcons/UserCommercial";
import UserSupport from "../../../components/FiraIcons/UserSupport";
import UserMarketing from "../../../components/FiraIcons/UserMarketing";
import {
  AlertType,
  usePopupAlert,
} from "../../../providers/AlertProvider/AlertProvider";
import { ItemRole, PopUpAlertTypes, UsersRole } from "../../../utils/types.d";
import FiraUserRoleComponent from "../../../components/FiraUserRoleComponent/FiraUserRoleComponent";
import PopUpAlert, {
  PopUpButtonsTypes,
} from "../../../components/PopUpAlert/PopUpAlert";
import ModalLayout from "../../../layout/ModalLayout/ModalLayout";
import FiraInputSearch, {
  FiraInputSearchType,
} from "../../../components/InputSearch/InputSearch";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/Store";
import {
  UsersContext,
  UsersContextData,
} from "../../../providers/UsersProvider";
import { findTranslationByValue } from "../../../utils/findTranslate";

const UserCreateView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const showAlert = usePopupAlert();
  const { utils } = useSelector((state: RootState) => state);
  const [showErrorEmail, setShowErrorEmail] = useState<boolean>(false);
  const [isActiveCommercial, setIsActiveCommercial] = useState<boolean>(false);
  const [isSendCheck, setIsSendCheck] = useState<boolean>(true);
  const [stepInit, setStepInit] = useState<boolean>(true);
  const [showOptionAlert, setShowOptionAlert] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<string>("");
  const {
    successMessage,
    errorMessage,
    sendInvitation,
    isLoading,
    setValuesProvider,
  } = useContext(UsersContext) as UsersContextData;
  const [roleList, setRoleList] = useState<ItemRole[]>([
    {
      icon: <UserCommercial />,
      name: t("views.users.new.sectionRole.commercial"),
      description: t("views.users.new.sectionRole.commercialDesc"),
    },
    {
      icon: <UserSupport />,
      name: t("views.users.new.sectionRole.support"),
      description: t("views.users.new.sectionRole.supportDesc"),
    },
    {
      icon: <UserMarketing />,
      name: t("views.users.new.sectionRole.marketing"),
      description: t("views.users.new.sectionRole.marketingDesc"),
    },
  ]);
  const handleBackButton = () => {
    if (selectedUser || isActiveCommercial) {
      setShowOptionAlert(true);
    } else {
      navigate("/home/users");
    }
  };

  let titleSection;
  {
    titleSection = (
      <span className={`${styles.title}`}>{t("views.users.new.title")}</span>
    );
  }

  useEffect(() => {
    setValuesProvider();
  }, []);

  useEffect(() => {
    errorMessage &&
      (showAlert(
        AlertType.error,
        t(
          "views.users.new.errorMessages." +
            findTranslationByValue(errorMessage)
        )
      ),
      setValuesProvider());
  }, [errorMessage]);

  useEffect(() => {
    successMessage &&
      (navigate("/home/users"),
      setValuesProvider(),
      showAlert(AlertType.success, t("views.users.new.successMessage")));
  }, [successMessage]);

  const sendData = () => {
    sendInvitation(selectedUser, selectedUser + "@firaonlive.com");
  };

  const handleclick = () => {
    if (stepInit) {
      setStepInit(false);
      isActiveCommercial ? setIsSendCheck(false) : setIsSendCheck(true);
    } else {
      sendData();
    }
  };
  const handleclickBack = () => {
    setStepInit(true);
    setIsSendCheck(false);
  };

  const selectRole = (item: string) => {
    setIsSendCheck(isActiveCommercial);
    setIsActiveCommercial(!isActiveCommercial);
  };

  const handleCancelPopup = () => {
    setShowOptionAlert(false);
  };
  const handleContinuePopup = () => {
    setShowOptionAlert(false);
    setValuesProvider();
    navigate("/home/users");
  };
  const popUpButtons: PopUpButtonsTypes[] = [
    {
      title: t("views.users.new.popupContinue"),
      handleClick: handleContinuePopup,
      type: "border",
    },
    {
      title: t("views.users.new.popupCancel"),
      handleClick: handleCancelPopup,
      type: "fill",
    },
  ];
  const formatObjectEmail = (email: string) => {
    const atIndex = email.indexOf("@");
    const name = email.slice(0, atIndex);
    const id = (utils.allUsers.indexOf(email) + 1).toString(); // Obtén el índice + 1 como ID
    return { id, name, value: name };
  };
  const [usersList, setUsersList] = useState<FiraInputSearchType[]>(
    utils.allUsers.map(formatObjectEmail)
  );

  const handleSearchInput = (option: FiraInputSearchType) => {
    setSelectedUser(option.value + "");
    setIsSendCheck(false);
    setShowErrorEmail(false);
  };
  const onChangeinput = (event: ChangeEvent<HTMLInputElement>) => {
    setIsSendCheck(true);
  };
  const onError = (isError: boolean) => {
    setShowErrorEmail(isError);
  };
  return (
    <div>
      <FiraNavBar
        backButtonHandler={handleBackButton}
        titleSection={titleSection}
        trailingOptions={[]}
      ></FiraNavBar>
      <div className={styles.CreateViewWrapper}>
        {showOptionAlert && (
          <ModalLayout>
            <PopUpAlert
              type={PopUpAlertTypes.warning}
              width={"620px"}
              title={t("views.users.new.popupTitle") + ""}
              message={t("views.users.new.popupMessage") + ""}
              buttons={popUpButtons}
            />
          </ModalLayout>
        )}

        {stepInit ? (
          <div className={styles.emailSection}>
            <label className={styles.title}>
              {t("views.users.new.sectionEmail.title")}
            </label>
            <label className={styles.subtitle}>
              {t("views.users.new.sectionEmail.subtitle")}
            </label>
            <div className={styles.itemInput}>
              <label className={"lead-14-text"}>
                {t("views.users.new.sectionEmail.email")}
              </label>

              <FiraInputSearch
                placeholder={""}
                options={usersList}
                onSelect={handleSearchInput}
                onchange={onChangeinput}
                currentValue={selectedUser + ""}
                onError={onError}
              />
            </div>
          </div>
        ) : (
          <div className={styles.roleSection}>
            <label className={styles.title}>
              {t("views.users.new.sectionRole.title")}
            </label>
            <label className={styles.subtitle}>
              {t("views.users.new.sectionRole.subtitle")}
            </label>
            <div className={styles.optionsRole}>
              <FiraUserRoleComponent
                role={roleList[0]}
                onClick={() => selectRole(UsersRole.commercial)}
                isSelected={isActiveCommercial}
                isDisabled={true}
              />
              <FiraUserRoleComponent
                role={roleList[1]}
                onClick={() => ({})}
                isSelected={false}
                isDisabled={true}
              />
              <FiraUserRoleComponent
                role={roleList[2]}
                onClick={() => ({})}
                isSelected={false}
                isDisabled={true}
              />
            </div>
          </div>
        )}

        <div className={styles.containerButton}>
          {!stepInit && (
            <Button
              width="110px"
              height="32px"
              buttonStyle={ButtonStyle.border}
              clickHandler={handleclickBack}
              isDisabled={isLoading}
            >
              <span className="lead-14-text">{t("views.users.new.back")}</span>
            </Button>
          )}

          <Button
            width="100px"
            height="32px"
            buttonStyle={ButtonStyle.main}
            clickHandler={handleclick}
            isDisabled={isLoading || showErrorEmail}
          >
            <span className="lead-14-text">
              {stepInit
                ? t("views.users.new.next")
                : t("views.users.new.invite")}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserCreateView;
