import React from "react";
import { useTranslation } from "react-i18next";
import CalendarMaterial from "../../components/FiraIcons/CalendarMaterial";
import Skeleton from "../../components/Skeleton/Skeleton";
import { EventStatus, NextEventList } from "../../utils/types.d";
import styles from "./EventListWidget.module.scss";
import { i18n } from "i18next";
import { TableCell, TableRow } from "../Table/Table";
import EmptyProductsWidget from "../FiraIcons/EmptyProductsWidget";
import ArrowRightComplete from "../FiraIcons/ArrowRightComplete";

const formatDate = (date: Date, i18n: i18n) => {
  return (
    date.toLocaleDateString(i18n.language, {
      weekday: "short",
      day: "numeric",
    }) +
    ", " +
    date.toLocaleDateString(i18n.language, {
      year: "numeric",
    })
  );
};

interface Props {
  isLoading: boolean;
  nextEventList: NextEventList[];
  handleEventList?(): void;
}

const EventListWidget = ({
  isLoading,
  nextEventList,
  handleEventList,
}: Props) => {
  const { t, i18n } = useTranslation();

  const nextEvent =
    nextEventList.length > 0
      ? nextEventList.map((event, index) => {
          if (index < 3) {
            return <NextEventListItem key={event.eventId} event={event} />;
          }
        })
      : null;

  return (
    <div className={styles.events}>
      {isLoading ? (
        <>
          <Skeleton width="60%" height="20px" />
          <table>
            <tbody>{SkeletonSideBody}</tbody>
          </table>
        </>
      ) : (
        <>
          <h6>{"Lives"}</h6>
          <div className={styles.nextEventsContainer}>
            <div
              className={`lead-text ${
                nextEvent ? styles.eventsElementsWrapper : styles.textNoTitle
              } `}
            >
              {nextEvent ? nextEvent : <EmptyProductsWidget />}
            </div>
            {handleEventList && (
              <button
                className={styles.buttonNextEvents}
                type="button"
                onClick={handleEventList}
              >
                <span className={styles.textLink}>
                  {t("views.clients.details.seeEvent")}
                </span>
                <ArrowRightComplete />
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const rows = [
  { id: 1, name: "" },
  { id: 2, name: "" },
  { id: 3, name: "" },
];

const NextEventListItem: React.FC<{ event: NextEventList }> = ({ event }) => {
  const { i18n } = useTranslation();

  return (
    <div className={`${styles.ContainerNextEvents}`}>
      <div
        className={`${
          event.status === EventStatus.STARTED
            ? styles.itemEventLive
            : styles.itemEventActive
        } ${styles.side}`}
      ></div>
      <div className={styles.itemEventWrapper}>
        <div className={styles.ContainerTitle}>
          <div className={`${styles.textTitleEvent} ${styles.ellipsis}`}>
            <b>{event.storeName}</b>
          </div>
          {event.status === EventStatus.STARTED && (
            <span className={styles.bullet}>Live</span>
          )}
        </div>

        <div className={`lead-text ${styles.textTime}`}>{event.eventName}</div>
      </div>
      <div className={styles.itemSeparator}></div>
    </div>
  );
};

const SkeletonSideBody = rows.map((data) => {
  return (
    <TableRow key={data.id}>
      <TableCell size="100px">
        <span className={styles.skeletonCell}>
          <Skeleton width="220px" height="20px" />
          <Skeleton width="170px" height="20px" />
        </span>
      </TableCell>
    </TableRow>
  );
});

export default EventListWidget;
