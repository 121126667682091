import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { components, Theme } from "react-select";
import styles from "./SelectCheckbox.module.scss";
import { itemsOption } from "../../utils/typesClients.d";
import DropdownIndicator from "./DropdownIndicator";

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}: any) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "left",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
    alignItem: "left",
    alignContent: "start",
    justifyContent: "start",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <div
        className={`${styles.CheckboxWrapper} ${
          isSelected ? styles.IsActive : ""
        } ${isFocused ? styles.IsFocus : ""} ${
          isDisabled ? styles.IsDisabled : ""
        }`}
      >
        <input
          className={styles.Checkbox}
          name={"name"}
          type="checkbox"
          checked={isSelected}
          disabled={isDisabled}
          onChange={(e) => ({})}
        />
        <div className={styles.CheckboxButton}></div>
      </div>
      {children}
    </components.Option>
  );
};

interface SelectProps {
  name?: string;
  itemsOptions: itemsOption[];
  onChange(items: itemsOption[]): void;
}
const SelectCheckbox = ({ name, onChange, itemsOptions }: SelectProps) => {
  const defaultOption = itemsOptions[0];

  defaultOption.isSelected = true;
  const [selectedOptions, setSelectedOptions] = useState<itemsOption[]>([
    defaultOption,
  ]);
  const { t } = useTranslation();

  //CUSTOM STYLE DEL COMPONENTE SELECT
  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      marginTop: 0,
      background: "#F7F8F9",
    }),
    container: (provided: any) => ({
      ...provided,
      width: "100%",
      height: " 40px",
      fontSize: "14px",
      paddingBottom: 7,
      color: "black",
    }),
    control: (provided: any) => ({
      ...provided,
      minHeight: "30px",
      height: " 40px",
      boxShadow: "none",
      borderColor: "#e5e5e5",
      "&:hover": {
        color: "#e5e5e5",
        border: provided.isFocused ? "1px solid #ffde07" : "1px solid #ffde07",
      },
    }),
    input: (provided: any) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      height: "40px",
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      height: "30px",
      padding: "0 6px",
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      color: "#000000",
      fontWeight: 400,
    }),
  };

  useEffect(() => {
    onChange(selectedOptions);
  }, [selectedOptions]);

  const getPackegesSelected = () => {
    const array = selectedOptions.map((item, key) => {
      return " " + item.label;
    });

    return array.toString() + "";
  };
  return (
    <div>
      <Select
        placeholder={getPackegesSelected()}
        styles={customStyles}
        isMulti
        isClearable={false}
        isSearchable={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        controlShouldRenderValue={false}
        value={selectedOptions}
        onChange={(options) => {
          if (Array.isArray(options)) {
            setSelectedOptions(options.map((opt) => opt));
          }
        }}
        options={itemsOptions}
        components={{
          Option: InputOption,
          IndicatorSeparator: () => null,
          DropdownIndicator,
        }}
      />
    </div>
  );
};

export default SelectCheckbox;
