import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import styles from "./ClientListView.module.scss";

import { useTranslation } from "react-i18next";
import {
  InfiniteTable,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  orderingType,
} from "../../../components/Table/Table";
import { FiraAccountState, OptionsSelect } from "../../../utils/types.d";
import Skeleton from "../../../components/Skeleton/Skeleton";
import EmptyPieChartWidget from "../../../components/FiraIcons/EmptyPieChartWidget";

import { dateFormat } from "../../../utils/handleDates";
import WorldSvg from "../../../components/FiraIcons/world";
import PhoneSvg from "../../../components/FiraIcons/PhoneSvg";
import PlayerSvg from "../../../components/FiraIcons/PlayerSvg";
import { Button } from "../../../components";
import { ButtonStyle } from "../../../components/Button/Button";
import { useDebounce } from "../../../utils/useDebounce";
import {
  BusinessClient,
  ClientContextData,
  ClientsFilter,
  FilterTypesClients,
  FiraProducts,
} from "../../../utils/typesClients.d";
import { useNavigate } from "react-router-dom";
import { ClientsContext } from "../../../providers/clientsProvider";

const ClientListView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filterValue, setFilterValue] = useState("");
  const { clients, isLoading, fetchClients } = useContext(
    ClientsContext
  ) as ClientContextData;

  const [clientList, setClientList] = useState<BusinessClient[]>([]);
  const debounced = useDebounce(filterValue, 800);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    setClientList(clients);
  }, [clients]);

  const usersHeader = [
    { name: "", size: "5px" },
    { name: t("views.clients.table.account"), size: "60px" },
    { name: t("views.clients.table.state"), size: "7%" },
    { name: t("views.clients.table.subscription"), size: "15%" },
    { name: t("views.clients.table.creationDate"), size: "50px" },
    { name: t("views.clients.table.country"), size: "50px" },
    { name: "", size: "5%" },
    { name: "", size: "20%" },
    { name: "", size: "5px" },
  ];

  const rowOptions = [
    {
      id: 1,
      name: t("views.clients.table.options.inactive"),
      value: FiraAccountState.active,
    },
    {
      id: 2,
      name: t("views.clients.table.options.active"),
      value: FiraAccountState.onHold,
    },
  ];

  const handleSelectRowOption = () => {
    //agregar opcion de ver detalle
  };

  const handleDebouncedFilter = () => {
    if (filterValue.length != 0) {
      fetchClients(
        FilterTypesClients.accountName,
        filterValue,
        ClientsFilter.ascend
      );
    } else {
      fetchClients(FilterTypesClients.default, "0000", ClientsFilter.ascend);
    }
  };

  useEffect(() => {
    !firstLoad && handleDebouncedFilter();
  }, [debounced]);

  const header = (
    <TableRow>
      {usersHeader.map((head, i) => (
        <TableHead key={i} size={head.size} centerContent={i !== 1}>
          {head.name}
        </TableHead>
      ))}
    </TableRow>
  );

  const body = clientList.map((client, i) => {
    const array = client.currentSubscription
      ? client.currentSubscription.productsOn.length > 0
        ? client.currentSubscription.productsOn
        : [""]
      : [""];
    const elements = array.map((item, key) => {
      if (item === FiraProducts.firaWeb) {
        return <WorldSvg key={key} />;
      } else if (item === FiraProducts.firaTv) {
        return <PlayerSvg key={key} />;
      } else if (item === FiraProducts.proseller) {
        return <PhoneSvg key={key} />;
      }
    });

    const handleClickDetails = () => {
      navigate(`${client.clientId}`);
    };

    return (
      <TableRow key={i}>
        <TableCell></TableCell>
        <TableCell>{client.fullName}</TableCell>
        <TableCell centerContent>
          {
            <div className={styles.itemStatus}>
              <label
                className={`${styles.badgeStatus} ${
                  client.accountState == FiraAccountState.active
                    ? styles.active
                    : styles.inactive
                }`}
              >
                {client.accountState == FiraAccountState.active
                  ? t("views.clients.details.active")
                  : t("views.clients.details.inactive")}
              </label>
            </div>
          }
        </TableCell>
        <TableCell centerContent>
          <div style={{ justifyContent: "center", display: "flex" }}>
            {elements}
          </div>
        </TableCell>
        <TableCell centerContent>
          {client.createdAt != null
            ? dateFormat(new Date(client.createdAt))
            : ""}
        </TableCell>
        <TableCell centerContent>{client.country}</TableCell>
        <TableCell centerContent>
          {client.liveEvent ? (
            <div className={styles.itemLive}>
              <label className={styles.badgeLive}>Live</label>
            </div>
          ) : (
            ""
          )}
        </TableCell>
        <TableCell centerContent>
          <div className={styles.buttonTd}>
            <Button
              clickHandler={handleClickDetails}
              buttonStyle={ButtonStyle.transparent}
              width="50%"
              height="48px"
            >
              <label className={styles.labelDetail}>
                {t("views.clients.table.button")}
              </label>
            </Button>
          </div>
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
    );
  });

  const orderingList = [
    {
      id: 1,
      name: t("views.clients.filterOptions.recent"),
      value: ClientsFilter.newer,
    },
    {
      id: 2,
      name: t("views.clients.filterOptions.older"),
      value: ClientsFilter.older,
    },

    {
      id: 3,
      name: t("views.clients.filterOptions.alphabet"),
      value: ClientsFilter.ascend,
    },
    {
      id: 4,
      name: t("views.clients.filterOptions.reverse"),
      value: ClientsFilter.descend,
    },
    {
      id: 5,
      name: t("views.clients.filterOptions.live"),
      value: ClientsFilter.broadcastingActive,
    },
    {
      id: 6,
      name: t("views.clients.filterOptions.nolive"),
      value: ClientsFilter.broadcastingNotActive,
    },
  ];
  const [selectedFilter, setSelectedFilter] = useState<orderingType>(
    orderingList[0]
  );

  const handleVideoFiltering = (event: ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    setFilterValue(keyword);
  };

  const handleOrdering = (id: number) => {
    const selected = orderingList.filter((p) => {
      return p.id === id;
    });
    setSelectedFilter(selected[0]);
  };

  useEffect(() => {
    setFirstLoad(false);
    if (
      selectedFilter.value + "" === FilterTypesClients.broadcastingActive ||
      selectedFilter.value + "" === FilterTypesClients.broadcastingNotActive
    ) {
      fetchClients(selectedFilter.value + "", "0", "ASCEND_ALPHABET");
    } else {
      selectedFilter.value &&
        fetchClients(
          FilterTypesClients.default,
          "none",
          selectedFilter.value + ""
        );
    }
  }, [selectedFilter]);

  const onChangeOption = (option: OptionsSelect) => {
    option.value === "none"
      ? fetchClients(FilterTypesClients.default, "0", ClientsFilter.ascend)
      : fetchClients(
          FilterTypesClients.country,
          option.value,
          ClientsFilter.ascend
        );
  };
  return (
    <>
      <TableHeader
        hideCheckBox
        searchInputValue={filterValue}
        searchInputChangeHandle={handleVideoFiltering}
        orderingList={orderingList}
        orderingValue={selectedFilter}
        orderingClick={handleOrdering}
        onChange={onChangeOption}
      />
      <div className={styles.infiniteTableWrapper}>
        {isLoading ? (
          <InfiniteTable
            header={header}
            body={SkeletonTableBody}
            rowsNumber={clientList.length}
            loadData={() => ({})}
            rowLoader={<RowLoader />}
            hasMore={clientList.length >= 20}
          />
        ) : clientList.length > 0 ? (
          <InfiniteTable
            header={header}
            body={isLoading ? SkeletonTableBody : body}
            rowsNumber={clientList.length}
            loadData={() => ({})}
            rowLoader={<RowLoader />}
            hasMore={clientList.length >= 20}
          />
        ) : (
          <EmptyClientsView />
        )}
      </div>
    </>
  );
};

const placeholder = [
  { id: 1, name: "placeholder" },
  { id: 2, name: "placeholder" },
  { id: 3, name: "placeholder" },
  { id: 4, name: "placeholder" },
  { id: 5, name: "placeholder" },
  { id: 6, name: "placeholder" },
  { id: 7, name: "placeholder" },
];
const SkeletonTableBody = placeholder.map((data) => {
  return (
    <TableRow key={data.id}>
      <TableCell size="8px"></TableCell>
      <TableCell>
        <Skeleton width="50px" height="50px" />
      </TableCell>
      <TableCell size="300px">
        <div className={styles.nameCell}>
          <Skeleton width="300px" />
        </div>
      </TableCell>
      <TableCell centerContent>
        <div className={`${styles.topCells} ${styles.selectVisibility} `}>
          <Skeleton />
        </div>
      </TableCell>
      <TableCell centerContent>
        <div className={styles.topCells}>
          <Skeleton width="200px" />
        </div>
      </TableCell>
      <TableCell centerContent>
        <div className={styles.topCells}>
          <Skeleton width="200px" />
        </div>
      </TableCell>
      <TableCell size="100%"></TableCell>
    </TableRow>
  );
});

const EmptyClientsView = () => {
  return (
    <div className={styles.emptyWrapper}>
      <EmptyPieChartWidget />
    </div>
  );
};

const RowLoader = () => {
  return (
    <table className={styles.fakeTable}>
      <tr className={styles.fakeRow} key={"loader"}>
        <td
          className={`${styles.first} ${styles.fakeCell}`}
          style={{ width: "8px" }}
        ></td>
        <td
          className={styles.fakeCell}
          style={{ width: "57px", minWidth: "50px" }}
        >
          <Skeleton width="50px" height="50px" />
        </td>
        <td
          className={styles.fakeCell}
          style={{ width: "300px", minWidth: "300px" }}
        >
          {/* por alguna razon esta celda agrega un margen interno, asi que lo cancelamos con marginRight */}
          <div style={{ marginRight: "-41px", width: "fit-content" }}>
            <Skeleton width="300px" />
          </div>
        </td>
        <td className={styles.fakeCell} style={{ minWidth: "100px" }}>
          <div className={styles.centered}>
            <Skeleton />
          </div>
        </td>
        <td className={styles.fakeCell} style={{ minWidth: "200px" }}>
          <div className={styles.centered}>
            <Skeleton width="200px" />
          </div>
        </td>
        <td
          className={`${styles.last} ${styles.fakeCell}`}
          style={{ width: "50px" }}
        ></td>
      </tr>
    </table>
  );
};
export default ClientListView;
