import React, { ReactElement, SyntheticEvent, useState } from "react";
import InfoCircle from "../../components/FiraIcons/InfoCircle";
import PopUpInfo from "../../components/PopUpInfo/PopUpInfo";
import Skeleton from "../../components/Skeleton/Skeleton";
import styles from "./CardWidget.module.scss";

interface Props {
  title: string;
  description: string;
  data: string;
  tooltipText?: string;
  isLoading?: boolean;
  background?: string;
}

const CardWidget = (props: Props) => {
  const [elemetTarget, setElementTarget] = useState<HTMLElement>();
  const [showInfo, setShowInfo] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const handleClickInfo = (e: SyntheticEvent<HTMLElement>) => {
    setShowInfo(showInfo ? false : true);
    setIsActive(!showInfo);
    setElementTarget(e.currentTarget);
  };
  return (
    <div
      className={styles.ContainerCardWidget}
      style={{ background: props.background ? props.background : "#FFFFFF" }}
    >
      {showInfo && (
        <PopUpInfo
          title={props.tooltipText ? props.tooltipText : ""}
          elementRef={elemetTarget}
        />
      )}
      {props.tooltipText && (
        <div
          className={styles.infoTooltip}
          onMouseEnter={handleClickInfo}
          onMouseLeave={handleClickInfo}
        >
          <InfoCircle color={isActive ? "#ffde07" : "#000"} />
        </div>
      )}

      <label className={styles.TitleCard}>
        {props.isLoading ? <Skeleton width="80%" height="22px" /> : props.title}
      </label>
      <label className={styles.DataCard}>
        {props.isLoading ? <Skeleton width="60%" height="22px" /> : props.data}
      </label>
    </div>
  );
};

export default CardWidget;
