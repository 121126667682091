import React, { useEffect, useState } from "react";
import ModalLayout from "../../../layout/ModalLayout/ModalLayout";
import styles from "./EditServiceModal.module.scss";
import Button, { ButtonStyle } from "../../Button/Button";
import { useTranslation } from "react-i18next";
import ToggleSwitch from "../../ToggleSwitch/ToggleSwitch";
import { ServicesName } from "../../../utils/types.d";
interface EditServiceModalProps {
  listServices: string[];
  onSave(productsOn: string[]): void;
  onClose(): void;
}

const EditServiceModal = ({
  onClose,
  onSave,
  listServices,
}: EditServiceModalProps) => {
  const { t } = useTranslation();
  const [useManagerWeb, setUseManagerWeb] = useState(false);
  const [useProseller, setUseProseller] = useState(false);
  const [useFiratv, setUseFiratv] = useState(false);

  const handleToggleWeb = () => {
    //
    setUseManagerWeb(!useManagerWeb);
  };
  const handleToggleFiratv = () => {
    //
    setUseFiratv(!useFiratv);
  };
  const handleToggleProseller = () => {
    //
    setUseProseller(!useProseller);
  };

  const handleSaveModal = () => {
    //
    const productsOn = [];
    useManagerWeb && productsOn.push(ServicesName.FIRA_WEB);
    useFiratv && productsOn.push(ServicesName.FIRA_TV);
    useProseller && productsOn.push(ServicesName.PROSELLER);

    onSave(productsOn);
  };

  useEffect(() => {
    //
    listServices.map((item, key) => {
      item === ServicesName.FIRA_TV
        ? setUseFiratv(true)
        : item === ServicesName.FIRA_WEB
        ? setUseManagerWeb(true)
        : setUseProseller(true);
    });
  }, []);
  return (
    <ModalLayout>
      <div className={styles.EditStoreContainer}>
        <div className={styles.itemRow}>
          <div className={styles.headerContainer}>
            <label className={styles.headerTitle}>
              {t("views.clients.details.editService.title")}
            </label>
            <label className={styles.headerSubTitle}>
              {t("views.clients.details.editService.subtitle")}
            </label>
          </div>
        </div>

        <div className={styles.itemRow}>
          <div className={styles.containerItem}>
            <div className={styles.titleContainer}>
              <label className={styles.textTitle}>
                {t("views.clients.details.editService.managerWeb")}
              </label>

              <ToggleSwitch
                optionFalse={t("toggle.inactive") + ""}
                optionTrue={t("toggle.active") + ""}
                checked={useManagerWeb}
                handleChecked={handleToggleWeb}
              />
            </div>
            <ul style={{ paddingLeft: "2px", margin: "0px" }}>
              <li
                className={styles.textDescription}
                style={{ paddingBottom: "4px" }}
              >
                {t("views.clients.details.editService.descWeb1")}
              </li>
              <li className={styles.textDescription}>
                {t("views.clients.details.editService.descWeb2")}
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.itemRow}>
          <div className={styles.containerItem}>
            <div className={styles.titleContainer}>
              <label className={styles.textTitle}>
                {t("views.clients.details.editService.proseller")}
              </label>
              <ToggleSwitch
                optionFalse={t("toggle.inactive") + ""}
                optionTrue={t("toggle.active") + ""}
                checked={useProseller}
                handleChecked={handleToggleProseller}
              />
            </div>
            <ul style={{ paddingLeft: "2px", margin: "0px" }}>
              <li
                className={styles.textDescription}
                style={{ paddingBottom: "4px" }}
              >
                {t("views.clients.details.editService.descproseller1")}
              </li>
              <li className={styles.textDescription}>
                {t("views.clients.details.editService.descproseller2")}
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.itemRow}>
          <div className={styles.containerItem}>
            <div className={styles.titleContainer}>
              <label className={styles.textTitle}>
                {t("views.clients.details.editService.firatv")}
              </label>
              <ToggleSwitch
                optionFalse={t("toggle.inactive") + ""}
                optionTrue={t("toggle.active") + ""}
                checked={useFiratv}
                handleChecked={handleToggleFiratv}
              />
            </div>
            <ul style={{ paddingLeft: "2px", margin: "0px" }}>
              <li
                className={styles.textDescription}
                style={{ paddingBottom: "4px" }}
              >
                {t("views.clients.details.editService.descfiratv1")}
              </li>
              <li className={styles.textDescription}>
                {t("views.clients.details.editService.descfiratv2")}
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.bottomContainer}>
          <Button
            width={"106px"}
            height={"32px"}
            buttonStyle={ButtonStyle.border}
            clickHandler={onClose}
          >
            <label style={{ cursor: "pointer" }}>
              {t("views.clients.details.editService.cancel")}
            </label>
          </Button>
          <Button
            width={"106px"}
            height={"32px"}
            clickHandler={handleSaveModal}
          >
            <label style={{ cursor: "pointer" }} className="lead-14-text">
              {t("views.clients.details.editService.save")}
            </label>
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default EditServiceModal;
