import { UsersData } from "../utils/types";
import { apiManagement } from "./api";

export const UsersListService = (
  filterType: string,
  valueFilter: string,
  sortType: string
) => {
  return apiManagement.get<UsersData[]>(
    `/v1/super-admin/fetch-user-collections/${filterType}/${valueFilter}/${sortType}`
  );
};
//v1/super-admin/send-invitation/Arturo/arturo.bilbao@firaonlive.com
export const SendInvitationService = (username: string, email: string) => {
  return apiManagement.post<string>(
    `/v1/super-admin/send-invitation/${username}/${email}`
  );
};
