import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Logout = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M17.975 20.614v-4.432H12.74a.537.537 0 0 1-.408-.2.749.749 0 0 1-.17-.482c0-.18.062-.354.17-.482a.537.537 0 0 1 .408-.2h5.235v-4.432c0-.632-.213-1.239-.592-1.686-.38-.448-.893-.7-1.43-.7H9.023c-.536 0-1.05.252-1.43.7-.378.447-.591 1.054-.592 1.686v10.228c0 .632.214 1.239.593 1.686.379.448.893.7 1.429.7h6.932c.536 0 1.05-.252 1.429-.7.379-.447.592-1.054.592-1.686Z"
      fill="currentColor"
    />
    <path
      d="M21.202 16.182 19.3 18.427a.753.753 0 0 0-.159.478.747.747 0 0 0 .17.474c.106.125.25.197.4.2a.533.533 0 0 0 .406-.188l2.888-3.41a.749.749 0 0 0 .169-.481c0-.18-.06-.354-.17-.482l-2.887-3.41a.534.534 0 0 0-.406-.187.538.538 0 0 0-.4.2.747.747 0 0 0-.17.473.753.753 0 0 0 .159.479l1.902 2.245h-3.227v1.364h3.227Z"
      fill="currentColor"
    />
  </svg>
);

export default Logout;
