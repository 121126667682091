import React from "react";
import { components } from "react-select";
import ArrowDownSolid from "../FiraIcons/ArrowDownSolid";

const DropdownIndicator: React.FC<any> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDownSolid />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
