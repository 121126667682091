import { useDispatch } from "react-redux";

import authReducer from "./auth/authSlice";
import utilsReducer from "./utils/utilsSlice";
import dashboardReducer from "./dashboard/dashboardSlice";

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";

const rootReducer = combineReducers({
  auth: authReducer,
  utils: utilsReducer,
  dashboard: dashboardReducer,
});

const persistConfig = {
  key: "root",
  storage: storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
