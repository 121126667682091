export const timeTextFormatHMS = (total?: number) => {
  let timeConvert = "";
  if (total && total > 0) {
    if (total > 60) {
      const h = total / 60;

      const x = h - Math.floor(h);

      const minutos = x > 0 ? x * 60 : 0;

      if (minutos > 0) {
        timeConvert = Math.floor(h) + "h:" + Math.floor(minutos) + "m";
      } else {
        timeConvert = Math.floor(h) + "h:";
      }
    } else {
      const minutos = Math.floor(total);
      const seg = total - minutos;
      timeConvert = minutos + "m:" + Math.floor(seg * 60) + "s";
    }
  } else {
    timeConvert = "0";
  }
  return timeConvert;
};

export const timeTextFormatDHMS = (totalIn?: number) => {
  let timeConvert = "";
  const total = totalIn ? totalIn : 0;
  if (total && total > 0) {
    if (total > 60) {
      const h = total / 60;
      const x = h - Math.floor(h);
      const horas = x * 60;

      if (total > 4320) {
        const d = Math.floor(h) / 24;
        timeConvert = Math.floor(d) + "d";
      } else {
        timeConvert = Math.floor(h) + "h:" + Math.floor(horas) + "m";
      }
    } else {
      const minutos = Math.floor(total);
      const seg = total - minutos;
      timeConvert = minutos + "m:" + Math.floor(seg * 60) + "s";
    }
  } else {
    timeConvert = "0";
  }
  return timeConvert;
};
