import React, { useContext, useEffect, useState } from "react";

import styles from "./DashboardEvents.module.scss";
import { convertDateUTC, timeZoneDate } from "../../../utils/handleDates";
import { timeTextFormatHMS } from "../../../utils/timeTextFormatHMS";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../utils/typesStates";
import "react-multi-date-picker/styles/colors/yellow.css";
import CardWidget from "../../../widgets/CardWidget/CardWidget";
import InteractionsWidget from "../../../widgets/InteractionsWidget/InteractionsWidget";
import {
  DashboardContext,
  StatisticsEventContextData,
} from "../../../providers/DashboardProvider";
import { setDashboardIsLoading } from "../../../store/dashboard/dashboardSlice";

const DashboardEventsView = () => {
  const { t } = useTranslation();
  const { dashboard, utils } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { statisticsEvent, fetchStatisticsEvent } = useContext(
    DashboardContext
  ) as StatisticsEventContextData;

  useEffect(() => {
    if (utils.screenWidth < 900) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [utils.screenWidth]);

  const callStatisticsService = async () => {
    fetchStatisticsEvent(
      dashboard.country !== "" ? dashboard.country : "none",
      timeZoneDate(convertDateUTC(new Date(dashboard.firstDate))),
      timeZoneDate(convertDateUTC(new Date(dashboard.lastDate)))
    );
  };

  useEffect(() => {
    !dashboard.isLoading &&
      (dispatch(setDashboardIsLoading(true)), callStatisticsService());
  }, [dashboard.firstDate, dashboard.lastDate, dashboard.country]);

  const containerCards = (
    <div
      className={`${styles.countersWrapper} ${
        isMobile ? styles.mobileCounter : ""
      }`}
    >
      <CardWidget
        title={t("views.dashboard.cards.totalAudience")}
        description={t("views.dashboard.cards.totalAudience")}
        data={statisticsEvent?.audience + ""}
        isLoading={dashboard.isLoading}
      />

      <CardWidget
        title={t("views.dashboard.cards.numberOfLives")}
        description={t("views.dashboard.cards.numberOfLives")}
        data={statisticsEvent?.events + ""}
        isLoading={dashboard.isLoading}
      />
      <CardWidget
        title={t("views.dashboard.cards.testLives")}
        description={t("views.dashboard.cards.testLives")}
        data={statisticsEvent?.tests + ""}
        isLoading={dashboard.isLoading}
      />
      <CardWidget
        title={t("views.dashboard.cards.averageViews")}
        description={t("views.dashboard.cards.averageViews")}
        data={timeTextFormatHMS(statisticsEvent?.averageVisualizationTime) + ""}
        isLoading={dashboard.isLoading}
      />
      <CardWidget
        title={t("views.dashboard.cards.transmissionTime")}
        description={t("views.dashboard.cards.transmissionTime")}
        data={timeTextFormatHMS(statisticsEvent?.eventTime) + ""}
        isLoading={dashboard.isLoading}
      />
    </div>
  );
  return (
    <>
      <div className={styles.dashboardContainer}>
        {containerCards}
        {!isMobile ? (
          <div className={styles.desktopContainer}>
            <div className={styles.desktopContainer__firstCol}>
              <InteractionsWidget
                likes={statisticsEvent?.likes ? statisticsEvent.likes : 0}
                clicks={statisticsEvent?.clicks ? statisticsEvent.clicks : 0}
                messages={
                  statisticsEvent?.messages ? statisticsEvent.messages : 0
                }
                shares={statisticsEvent?.shares ? statisticsEvent.shares : 0}
                isLoading={dashboard.isLoading}
                pieHeight={268}
              />
            </div>
            <div className={styles.desktopContainer__secondCol}></div>
          </div>
        ) : (
          <div className={styles.mobileContainer}>
            <div className={styles.subGrid}>
              <div className={styles.subGrid__views}>
                <InteractionsWidget
                  likes={statisticsEvent?.likes ? statisticsEvent.likes : 0}
                  clicks={statisticsEvent?.clicks ? statisticsEvent.clicks : 0}
                  messages={
                    statisticsEvent?.messages ? statisticsEvent.messages : 0
                  }
                  shares={statisticsEvent?.shares ? statisticsEvent.shares : 0}
                  isLoading={dashboard.isLoading}
                  pieHeight={322}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DashboardEventsView;
