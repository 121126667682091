import * as React from "react";
import { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "../../utils/types";
import { RootState } from "../../utils/typesStates";
import FiraNavButton, {
  NavButtonPosition,
} from "../FiraNavButton/FiraNavButton";
import styles from "./FiraSideBar.module.scss";
import { setNavbarState } from "../../store/utils/utilsSlice";

interface FiraSideBarProps {
  topNavButtons: NavLink[];
  bottomNavButtons?: NavLink[];
}

const FiraSideBar = ({ topNavButtons, bottomNavButtons }: FiraSideBarProps) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { utils } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const closeNavBarHandler = () => {
    dispatch(setNavbarState(!utils.navBarIsClosed));
  };

  useEffect(() => {
    if (utils.screenWidth < 1200) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [utils.screenWidth]);

  let navLinks: ReactElement | undefined;
  if (topNavButtons && topNavButtons?.length > 0) {
    navLinks = (
      <div className={styles.NavigationButtonsWrapper}>
        {topNavButtons?.map((link) => (
          <FiraNavButton
            key={link.name}
            icon={link.icon}
            to={link.to}
            clickHandler={isMobile ? closeNavBarHandler : () => ""}
            subButtons={link.subLink}
          >
            {link.name}
          </FiraNavButton>
        ))}
      </div>
    );
  }

  let bottomNavLinks: ReactElement | undefined;
  if (bottomNavButtons && bottomNavButtons.length > 0) {
    bottomNavLinks = (
      <div className={styles.BottomNavigationWrapper}>
        {bottomNavButtons.map((link) => (
          <FiraNavButton
            key={link.name}
            icon={link.icon}
            to={link.to}
            position={NavButtonPosition.bottom}
            clickHandler={link.clickHandler}
          >
            {link.name}
          </FiraNavButton>
        ))}
      </div>
    );
  }

  return (
    <div
      className={`${styles.FiraSideBarWrapper} ${
        utils.navBarIsClosed ? styles.close : ""
      } ${isMobile ? styles.isMobile : ""}`}
    >
      <>
        {navLinks}
        {bottomNavLinks}
      </>
    </div>
  );
};

export default FiraSideBar;
