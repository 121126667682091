import * as React from "react";
import { SVGProps } from "react";

const PlayerSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 96 96"
    style={{
      background: "new 0 0 96 96",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M72.63 16.03S57.31 15.01 48 15.01c-10 0-24.63 1.02-24.63 1.02-9.95.44-17.93 7.13-17.93 17.18v29.57c0 10.05 7.54 16.38 17.93 17.18 0 0 18.47 1.02 24.63 1.02s24.63-1.02 24.63-1.02c9.71-1.11 17.93-7.13 17.93-17.18V33.21c0-10.05-7.63-16.44-17.93-17.18zm-6.82 33.79-25 14.65c-1.53.9-3.44-.22-3.44-2.01v-29.3c0-1.79 1.91-2.91 3.44-2.01L65.8 45.8c1.54.89 1.54 3.12.01 4.02z"
      style={{
        fill: "#e6ebec",
      }}
    />
  </svg>
);

export default PlayerSvg;
