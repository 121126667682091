import React, { createContext, useState } from "react";
import { useDispatch } from "react-redux";
import {
  DashboardStatisticsResponse,
  ProviderProps,
  StatisticsFiraTvByUserResponse,
  StatisticsFiraTvCountryResponse,
} from "../utils/types";
import {
  StatisticsByUsersFiraTvService,
  StatisticsCountryFiraTvService,
  StatisticsEventService,
} from "../services/dashboard";
import { setDashboardIsLoading } from "../store/dashboard/dashboardSlice";

export interface StatisticsEventContextData {
  statisticsEvent: DashboardStatisticsResponse | undefined;
  fetchStatisticsEvent: (
    country: string,
    startDate: string,
    endDate: string
  ) => void;
  statisticsFiraTvCountry: StatisticsFiraTvCountryResponse | undefined;
  fetchStatisticsFiraTvCountry: (country: string) => void;
  statisticsFiraTvByUsers: StatisticsFiraTvByUserResponse | undefined;
  fetchStatisticsFiraTvByUsers: (
    accountId: string,
    startDate: string,
    endDate: string
  ) => void;
}

const DashboardContext = createContext<StatisticsEventContextData | undefined>(
  undefined
);

export const DashboardProvider: React.FC<ProviderProps> = ({ children }) => {
  const [statisticsEvent, setStatisticsEvent] =
    useState<DashboardStatisticsResponse>();

  const [statisticsFiraTvCountry, setStatisticsFiraTvCountry] =
    useState<StatisticsFiraTvCountryResponse>();

  const [statisticsFiraTvByUsers, setStatisticsFiraTvByUsers] =
    useState<StatisticsFiraTvByUserResponse>();

  const dispatch = useDispatch();

  const fetchStatisticsEvent = async (
    country: string,
    startDate: string,
    endDate: string
  ) => {
    try {
      dispatch(setDashboardIsLoading(true));
      const response = await StatisticsEventService(
        country,
        startDate,
        endDate
      );
      dispatch(setDashboardIsLoading(false));
      setStatisticsEvent(response.data as DashboardStatisticsResponse);
    } catch (error) {
      dispatch(setDashboardIsLoading(false));
      console.error("Error fetching statistics:", error);
    }
  };

  const fetchStatisticsFiraTvCountry = async (country: string) => {
    try {
      dispatch(setDashboardIsLoading(true));
      const response = await StatisticsCountryFiraTvService(country);
      dispatch(setDashboardIsLoading(false));
      setStatisticsFiraTvCountry(
        response.data as StatisticsFiraTvCountryResponse
      );
    } catch (error) {
      dispatch(setDashboardIsLoading(false));
      console.error("Error fetching statistics:", error);
    }
  };

  const fetchStatisticsFiraTvByUsers = async (
    accountId: string,
    startDate: string,
    endDate: string
  ) => {
    try {
      dispatch(setDashboardIsLoading(true));
      const response = await StatisticsByUsersFiraTvService(
        accountId,
        startDate,
        endDate
      );
      dispatch(setDashboardIsLoading(false));
      setStatisticsFiraTvByUsers(
        response.data as StatisticsFiraTvByUserResponse
      );
    } catch (error) {
      dispatch(setDashboardIsLoading(false));
      console.error("Error fetching statistics users:", error);
    }
  };

  const contextValue: StatisticsEventContextData = {
    statisticsEvent,
    fetchStatisticsEvent,
    statisticsFiraTvCountry,
    fetchStatisticsFiraTvCountry,
    statisticsFiraTvByUsers,
    fetchStatisticsFiraTvByUsers,
  };

  return (
    <DashboardContext.Provider value={contextValue}>
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;
export { DashboardContext };
