import React from "react";

import { useTranslation } from "react-i18next";

import styles from "./ClientsView.module.scss";
import FiraNavBar from "../../components/FiraNavBar/FiraNavBar";

import Button, { ButtonStyle } from "../../components/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import TabLayout from "../../layout/TabLayout/TabLayout";
import ClientListView from "./ClientListView/ClientListView";
import { TabElement } from "../../utils/types";

const ClientsView: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let titleSection;
  let exportButton: JSX.Element;
  let createButton: JSX.Element;
  const handleClickCreate = () => {
    navigate("/home/clients/new");
  };
  {
    titleSection = (
      <span className={`${styles.title}`}>{t("views.clients.title")}</span>
    );
    exportButton = (
      <Button
        key={"btnexport"}
        width="73px"
        buttonStyle={ButtonStyle.transparent}
        clickHandler={() => {
          //openImportProductsModal();
        }}
      >
        <span className="lead-14-text">{t("views.clients.exportButton")}</span>
      </Button>
    );
    createButton = (
      <Button key={"btncreate"}>
        <Link
          className={`${styles.LinkToNewClient} ${"lead-14-text"}`}
          onClick={handleClickCreate}
          to={"/home/clients/new"}
        >
          {t("views.clients.createButton")}
        </Link>
      </Button>
    );
  }
  const clientsTabs: TabElement[] = [
    {
      titleTab: t("views.clients.tabs.clients"),
      element: <ClientListView />,
      navbar: (
        <FiraNavBar
          titleSection={titleSection}
          trailingOptions={[exportButton, createButton]}
        ></FiraNavBar>
      ),
    },
    //{
    //  titleTab: "Otro",
    //  element: <ClientListView />,
    //  navbar: undefined,
    //},
  ];
  return (
    <div className={styles.ClientsTabWrapper}>
      <TabLayout tabs={clientsTabs} />
    </div>
  );
};

export default ClientsView;
