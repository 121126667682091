import React, { createContext, useEffect, useState } from "react";
import { RootState } from "../utils/typesStates";
import {
  acountOwnerInfoService,
  clientListService,
  createAccountBusinessClientService,
  generalAccountInfoService,
  getLastEventService,
  getOperatorsInfoByStoreService,
  getStoreInfoService,
  toggleSignupExpress,
  updateFiraProductsService,
} from "../services/clients";
import { useSelector } from "react-redux";
import {
  AccountOwner,
  BusinessClient,
  BusinessClientCreate,
  ClientContextData,
  GeneralData,
  Operator,
  ResponseCreateBusinessClient,
  StoreData,
} from "../utils/typesClients";
import { NextEventList, ProviderProps } from "../utils/types";

const ClientsContext = createContext<ClientContextData | undefined>(undefined);

export const ClientsProvider: React.FC<ProviderProps> = ({ children }) => {
  const initialState: AccountOwner = {
    username: "",
    name: "",
    accountEmail: "",
    createdAt: "",
    contact: {
      emails: "",
      phones: [
        {
          country: "",
          operator: "",
          number: "",
          type: "",
        },
      ],
      socialMedia: "",
      webpages: [],
    },
    signOnExpress: null,
    country: "",
    accountState: "",
    productsOn: [],
  };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [clients, setClients] = useState<BusinessClient[]>([]);
  const [accountOwner, setAccountOwner] = useState<AccountOwner>(initialState);
  const [generalData, setGeneralData] = useState<GeneralData | undefined>();
  const [lastEvent, setLastEvent] = useState<NextEventList[]>([]);
  const [storeList, setStoreList] = useState<StoreData[]>([]);
  const [operatorList, setOperatorList] = useState<Operator[]>([]);
  const [responseCreate, setResponseCreate] =
    useState<ResponseCreateBusinessClient>({
      code: "",
      message: "",
      success: false,
      errorDateTime: "",
      errorList: [],
    });
  const { auth } = useSelector((state: RootState) => state);
  const [loadingData, setLoadingData] = useState<{ [key: string]: boolean }>({
    loadcards: false,
    loadstores: false,
    loadevents: false,
    loadinfo: false,
    loadCreate: false,
  });

  const [isErrorProducts, setIsErrorProducts] = useState<boolean>(false);

  const fetchClients = async (
    filterType: string,
    valueFilter: string,
    sortType: string
  ) => {
    try {
      setIsLoading(true);

      const response = await clientListService(
        filterType,
        valueFilter,
        sortType
      );
      setIsLoading(false);
      setClients(response.data);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching clients:", error);
    }
  };

  // /v1/super-admin/account-owner-info/{accountId}
  const getAccountOwnerInfo = async (accountId: string) => {
    try {
      setLoadingData((prevLoadingData) => ({
        ...prevLoadingData,
        ["loadinfo"]: true,
      }));
      const response = await acountOwnerInfoService(accountId);

      setAccountOwner(response.data);
      setLoadingData((prevLoadingData) => ({
        ...prevLoadingData,
        ["loadinfo"]: false,
      }));
    } catch (error) {
      setLoadingData((prevLoadingData) => ({
        ...prevLoadingData,
        ["loadinfo"]: false,
      }));
      console.error("Error fetching clients:", error);
    }
  };

  const getGeneralAccountInfo = async (accountId: string) => {
    try {
      setLoadingData((prevLoadingData) => ({
        ...prevLoadingData,
        ["loadcards"]: true,
      }));
      const response = await generalAccountInfoService(accountId);

      setGeneralData(response.data as GeneralData);
      setLoadingData((prevLoadingData) => ({
        ...prevLoadingData,
        ["loadcards"]: false,
      }));
    } catch (error) {
      setLoadingData((prevLoadingData) => ({
        ...prevLoadingData,
        ["loadcards"]: false,
      }));
      console.error("Error fetching clients:", error);
    }
  };

  // v1/super-admin/last-events/64e414ff64f8913df746c2bd
  const getLastEventInfo = async (accountId: string) => {
    try {
      setLoadingData((prevLoadingData) => ({
        ...prevLoadingData,
        ["loadevents"]: true,
      }));
      const response = await getLastEventService(accountId);

      setLastEvent(response.data);
      setLoadingData((prevLoadingData) => ({
        ...prevLoadingData,
        ["loadevents"]: false,
      }));
    } catch (error) {
      setLoadingData((prevLoadingData) => ({
        ...prevLoadingData,
        ["loadevents"]: false,
      }));
      console.error("Error fetching clients:", error);
    }
  };

  // /v1/super-admin/get-stores-info/{accountId}/{offSet}/{limit}/{sortType}
  const getStoresListInfo = async (
    accountId: string,
    storeName: string,
    sortType: string,
    offSet: number,
    limit: number
  ) => {
    try {
      setLoadingData((prevLoadingData) => ({
        ...prevLoadingData,
        ["loadstores"]: true,
      }));
      setIsError(false);
      const response = await getStoreInfoService(
        accountId,
        sortType,
        offSet,
        limit,
        storeName
      );

      setStoreList(response.data);
      setLoadingData((prevLoadingData) => ({
        ...prevLoadingData,
        ["loadstores"]: false,
      }));
    } catch (error) {
      setIsError(true);
      setLoadingData((prevLoadingData) => ({
        ...prevLoadingData,
        ["loadstores"]: false,
      }));
      console.error("Error fetching clients:", error);
    }
  };

  const getOperatorList = async (
    accountId: string,
    name: string,
    sortType: string,
    offSet: number,
    limit: number
  ) => {
    try {
      const response = await getOperatorsInfoByStoreService(
        accountId,
        sortType,
        offSet,
        limit,
        name
      );

      setOperatorList(response.data);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  //setAccountOwner({...accountOwner,productsOn: newProductsOn, });  updateFiraProductsService
  const updateFiraProducts = async (accountId: string, body: string[]) => {
    try {
      setIsEdit(true);
      setLoadingData((prevLoadingData) => ({
        ...prevLoadingData,
        ["loadinfo"]: true,
      }));
      const response = await updateFiraProductsService(accountId, body);

      setAccountOwner({ ...accountOwner, productsOn: response.data.products });
      setLoadingData((prevLoadingData) => ({
        ...prevLoadingData,
        ["loadinfo"]: false,
      }));
      setIsErrorProducts(false);
    } catch (error) {
      setLoadingData((prevLoadingData) => ({
        ...prevLoadingData,
        ["loadinfo"]: false,
      }));
      setIsErrorProducts(true);
      console.error("Error fetching clients:", error);
    }
  };

  const createBusinessClient = async (body: BusinessClientCreate) => {
    try {
      setIsError(false);
      setLoadingData((prevLoadingData) => ({
        ...prevLoadingData,
        ["loadCreate"]: true,
      }));

      const response = await createAccountBusinessClientService(body);

      setLoadingData((prevLoadingData) => ({
        ...prevLoadingData,
        ["loadCreate"]: false,
      }));
      setResponseCreate((prevLoadingData) => ({
        ...prevLoadingData,
        success: true,
      }));
    } catch (error: any) {
      setIsError(true);
      setLoadingData((prevLoadingData) => ({
        ...prevLoadingData,
        ["loadCreate"]: false,
      }));

      if (error.response && error.response.status === 400) {
        if (error.response.data.errorList) {
          setResponseCreate((prevLoadingData) => ({
            ...prevLoadingData,
            errorList: error.response.data.errorList,
          }));
        } else {
          setResponseCreate((prevLoadingData) => ({
            ...prevLoadingData,
            message: error.response.data.message,
          }));
        }
      } else {
        console.log("Error create:", error);
      }
    }
  };

  const setDetailsData = () => {
    setAccountOwner(initialState);
    setGeneralData(undefined);
    setStoreList([]);
    setLastEvent([]);
    setOperatorList([]);
  };

  const setClientList = () => {
    setClients([]);
  };

  const setEditMode = (mode: boolean) => {
    setIsEdit(mode);
  };

  const setError = (mode: boolean) => {
    setIsError(mode);
    setResponseCreate({
      code: "",
      message: "",
      success: false,
      errorDateTime: "",
      errorList: [],
    });
  };

  const toggleSignUp = async (accountId: string) => {
    try {
      const { message } = await toggleSignupExpress(accountId);
      setAccountOwner((prevState) => ({
        ...prevState,
        signOnExpress: message
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const contextValue: ClientContextData = {
    clients,
    setClientList,
    accountOwner,
    generalData,
    lastEvent,
    storeList,
    operatorList,
    loadingData,
    isLoading,
    isError,
    isErrorProducts,
    fetchClients,
    getAccountOwnerInfo,
    getGeneralAccountInfo,
    getLastEventInfo,
    getStoresListInfo,
    setDetailsData,
    getOperatorList,
    updateFiraProducts,
    setEditMode,
    isEdit,
    responseCreate,
    createBusinessClient,
    setError,
    toggleSignUp,
  };

  return (
    <ClientsContext.Provider value={contextValue}>
      {children}
    </ClientsContext.Provider>
  );
};

export default ClientsProvider;
export { ClientsContext };
