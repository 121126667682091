import React, { createContext, useState } from "react";
import { ProviderProps } from "../utils/types";
import { RootState } from "../utils/typesStates";
import { useSelector } from "react-redux";
import { getEventListService } from "../services/events";
import { EventsData } from "../utils/typesEvents";

export interface EventsContextData {
  isLoading: boolean;
  eventsList: EventsData[];
  errorEvents: boolean;
  getEventList: (
    offSet: number,
    limit: number,
    sortType: string,
    filterType: string,
    filterValue: string
  ) => void;
  getNextEventList: (
    offSet: number,
    limit: number,
    sortType: string,
    filterType: string,
    filterValue: string
  ) => void;
}
const EventsContext = createContext<EventsContextData | undefined>(undefined);

export const EventsProvider: React.FC<ProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [eventsList, setEventsList] = useState<EventsData[]>([]);
  const [errorEvents, setErrorEvents] = useState<boolean>(false);
  const { auth } = useSelector((state: RootState) => state);

  const getEventList = async (
    offSet: number,
    limit: number,
    sortType: string,
    filterType: string,
    filterValue: string
  ) => {
    try {
      setIsLoading(true);
      setEventsList([]);
      setErrorEvents(false);
      const response = await getEventListService(
        sortType,
        offSet,
        limit,
        filterType,
        filterValue
      );
      setIsLoading(false);
      setEventsList(response.data);
    } catch (error) {
      setIsLoading(false);
      setErrorEvents(true);
      console.error("Error fetching clients:", error);
    }
  };

  const getNextEventList = async (
    offSet: number,
    limit: number,
    sortType: string,
    filterType: string,
    filterValue: string
  ) => {
    try {
      setErrorEvents(false);
      const response = await getEventListService(
        sortType,
        offSet,
        limit,
        filterType,
        filterValue
      );
      setIsLoading(false);
      setEventsList(response.data);
    } catch (error) {
      setIsLoading(false);
      setErrorEvents(true);
      console.error("Error fetching clients:", error);
    }
  };

  const contextValue: EventsContextData = {
    isLoading,
    eventsList,
    errorEvents,
    getEventList,
    getNextEventList,
  };

  return (
    <EventsContext.Provider value={contextValue}>
      {children}
    </EventsContext.Provider>
  );
};

export default EventsProvider;
export { EventsContext };
