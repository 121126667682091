import React, { ChangeEvent, useContext, useEffect, useState } from "react";

import styles from "./DashboardFiraTv.module.scss";
import { convertDateUTC, timeZoneDate } from "../../../utils/handleDates";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../utils/typesStates";
import "react-multi-date-picker/styles/colors/yellow.css";
import CardWidget from "../../../widgets/CardWidget/CardWidget";
import InteractionsWidget from "../../../widgets/InteractionsWidget/InteractionsWidget";
import {
  DashboardContext,
  StatisticsEventContextData,
} from "../../../providers/DashboardProvider";
import { setDashboardIsLoading } from "../../../store/dashboard/dashboardSlice";
import DateRangePickerComponent from "../../../components/DateRangePickerComponent/DateRangePickerComponent";
import Input from "../../../components/Input/Input";
import MagnifyingGlass from "../../../components/FiraIcons/MagnifyingGlass";
import {
  BusinessClient,
  ClientContextData,
  ClientsFilter,
  FilterTypesClients,
} from "../../../utils/typesClients.d";
import { ClientsContext } from "../../../providers/clientsProvider";
import { useDebounce } from "../../../utils/useDebounce";
import { Countries } from "../../../utils/types.d";
import { timeTextFormatHMS } from "../../../utils/timeTextFormatHMS";

const DashboardFiraTvView = () => {
  const { t } = useTranslation();
  const { dashboard, utils } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const {
    statisticsFiraTvCountry,
    statisticsFiraTvByUsers,
    fetchStatisticsFiraTvCountry,
    fetchStatisticsFiraTvByUsers,
  } = useContext(DashboardContext) as StatisticsEventContextData;

  const { clients, fetchClients, setClientList } = useContext(
    ClientsContext
  ) as ClientContextData;
  const [filterValue, setFilterValue] = useState("");
  const debounced = useDebounce(filterValue, 800);
  const [firstLoad, setFirstLoad] = useState(true);
  const [searchOptions, setSearchOptions] = useState<BusinessClient[]>([]);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>("");

  const callStatisticsService = async () => {
    dispatch(setDashboardIsLoading(true));
    fetchStatisticsFiraTvCountry(dashboard.country);
  };

  const callStatisticsByUserService = async (clientId: string) => {
    dispatch(setDashboardIsLoading(true));
    fetchStatisticsFiraTvByUsers(
      clientId === "" ? Countries.none : clientId,
      timeZoneDate(convertDateUTC(new Date(dashboard.firstDate))),
      timeZoneDate(convertDateUTC(new Date(dashboard.lastDate)))
    );
  };
  useEffect(() => {
    setShowOptions(false);
    setClientList();
    dispatch(setDashboardIsLoading(true));
    callStatisticsService();
    callStatisticsByUserService(selectedOption);
    setFirstLoad(false);
  }, []);

  useEffect(() => {
    if (utils.screenWidth < 1200) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [utils.screenWidth]);

  useEffect(() => {
    dispatch(setDashboardIsLoading(true));
    !firstLoad && callStatisticsService();
  }, [dashboard.country]);

  useEffect(() => {
    const options = clients;
    setSearchOptions(options);
    setShowOptions(options.length > 0);
  }, [clients]);

  useEffect(() => {
    !firstLoad && handleDebouncedFilter();
  }, [debounced]);

  useEffect(() => {
    !firstLoad && callStatisticsByUserService(selectedOption);
  }, [selectedOption, dashboard.firstDate, dashboard.lastDate]);

  const containerCards = (
    <div
      className={`${styles.countersWrapper} ${
        isMobile ? styles.mobileCounter : ""
      }`}
    >
      <CardWidget
        title={t("views.dashboard.cards.vodTotal")}
        description={t("views.dashboard.cards.vodTotal")}
        data={
          statisticsFiraTvCountry?.totalVods
            ? statisticsFiraTvCountry.totalVods + ""
            : "0"
        }
        isLoading={dashboard.isLoading}
      />

      <CardWidget
        title={t("views.dashboard.cards.vodVisible")}
        description={t("views.dashboard.cards.vodVisible")}
        data={
          statisticsFiraTvCountry?.visibleVods
            ? statisticsFiraTvCountry.visibleVods + ""
            : "0"
        }
        isLoading={dashboard.isLoading}
      />
      <CardWidget
        title={t("views.dashboard.cards.vodHidden")}
        description={t("views.dashboard.cards.vodHidden")}
        data={
          statisticsFiraTvCountry?.hideVods
            ? statisticsFiraTvCountry.hideVods + ""
            : "0"
        }
        isLoading={dashboard.isLoading}
      />
    </div>
  );

  const containerDataCards = (
    <div
      className={`${styles.countersWrapper} ${
        isMobile ? styles.mobileCounter : ""
      }`}
    >
      <CardWidget
        title={t("views.dashboard.cards.totalAudience")}
        description={t("views.dashboard.cards.totalAudience")}
        data={
          statisticsFiraTvByUsers?.totalAudience
            ? statisticsFiraTvByUsers.totalAudience + ""
            : "0"
        }
        isLoading={dashboard.isLoading}
        background={"#F7F8F9"}
      />

      <CardWidget
        title={t("views.dashboard.cards.averageTimeViews")}
        description={t("views.dashboard.cards.averageTimeViews")}
        data={timeTextFormatHMS(
          statisticsFiraTvByUsers?.averagePlayTime
            ? statisticsFiraTvByUsers.averagePlayTime / 1000
            : 0
        )}
        isLoading={dashboard.isLoading}
        background={"#F7F8F9"}
      />
      <CardWidget
        title={t("views.dashboard.cards.totalViews")}
        description={t("views.dashboard.cards.totalViews")}
        data={
          statisticsFiraTvByUsers?.totalPlayBack
            ? statisticsFiraTvByUsers.totalPlayBack + ""
            : "0"
        }
        isLoading={dashboard.isLoading}
        background={"#F7F8F9"}
      />
    </div>
  );
  //#F7F8F9

  const handleVideoFiltering = (event: ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    setFilterValue(keyword);

    // Muestra las opciones si hay resultados
  };

  const handleDebouncedFilter = () => {
    if (filterValue.length != 0) {
      fetchClients(
        FilterTypesClients.accountName,
        filterValue,
        ClientsFilter.ascend
      );
    } else {
      setClientList();
      setSelectedOption("");
      callStatisticsByUserService("");
      setShowOptions(false);
    }
  };

  const handleOptionClick = (option: BusinessClient) => {
    setSelectedOption(option.clientId);
    setShowOptions(false);
  };

  const subHeader = (
    <div className={styles.subheader}>
      <div className={styles.itemLeft}>
        <label className={styles.title}>
          {t("views.dashboard.subContainer.generalData")}
        </label>
        <label className={styles.subtitle}>
          {t("views.dashboard.subContainer.subtitle")}
        </label>
      </div>
      <div className={styles.itemRight}>
        <div className={styles.SearchContainer}>
          <Input
            height="34px"
            width="100%"
            onChange={handleVideoFiltering}
            value={filterValue}
            icon={<MagnifyingGlass className="SearchIcon" />}
            placeholder={"" + t("components.table.searchPlaceHolder")}
          />
          {showOptions && (
            <ul className={styles.optionslist}>
              {searchOptions.map((option, index) => (
                <li key={index} onClick={() => handleOptionClick(option)}>
                  {option.fullName}
                </li>
              ))}
            </ul>
          )}
        </div>
        <DateRangePickerComponent />
      </div>
    </div>
  );
  return (
    <>
      <div className={styles.dashboardContainer}>
        {containerCards}
        <div className={styles.dataContainer}>
          {subHeader}
          {containerDataCards}
          {!isMobile ? (
            <div className={styles.desktopContainer}>
              <div className={styles.desktopContainer__firstCol}>
                <InteractionsWidget
                  likes={
                    statisticsFiraTvByUsers?.likes
                      ? statisticsFiraTvByUsers.likes
                      : 0
                  }
                  clicks={
                    statisticsFiraTvByUsers?.clicks
                      ? statisticsFiraTvByUsers.clicks
                      : 0
                  }
                  messages={0}
                  shares={
                    statisticsFiraTvByUsers?.shares
                      ? statisticsFiraTvByUsers.shares
                      : 0
                  }
                  isLoading={dashboard.isLoading}
                  pieHeight={360}
                  background={"#F7F8F9"}
                />
              </div>
              <div className={styles.desktopContainer__secondCol}></div>
            </div>
          ) : (
            <div className={styles.mobileContainer}>
              <div className={styles.subGrid}>
                <div className={styles.subGrid__views}>
                  {" "}
                  <InteractionsWidget
                    likes={
                      statisticsFiraTvByUsers?.likes
                        ? statisticsFiraTvByUsers.likes
                        : 0
                    }
                    clicks={
                      statisticsFiraTvByUsers?.clicks
                        ? statisticsFiraTvByUsers.clicks
                        : 0
                    }
                    messages={0}
                    shares={
                      statisticsFiraTvByUsers?.shares
                        ? statisticsFiraTvByUsers.shares
                        : 0
                    }
                    isLoading={dashboard.isLoading}
                    pieHeight={360}
                    background={"#F7F8F9"}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DashboardFiraTvView;
