import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgStoreIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M.9 9.479c.369.275.864.407 1.35.407.495 0 .981-.132 1.35-.407.558-.436.9-1.11.9-1.896 0 .787.333 1.46.9 1.896.369.275.864.407 1.35.407.495 0 .981-.132 1.35-.407.558-.436.9-1.11.9-1.896 0 .787.333 1.46.9 1.896.369.275.864.407 1.359.407.486 0 .972-.132 1.341-.407.558-.436.9-1.11.9-1.896 0 .787.333 1.46.9 1.896.369.275.864.407 1.35.407.495 0 .981-.132 1.35-.407.567-.436.9-1.11.9-1.896v-.948L15.3 0H3.6L0 6.635v.948c0 .787.333 1.46.9 1.896ZM2.7 18h4.5v-4.74h3.6V18h4.5v-6.635a1.928 1.928 0 0 1-.9-.408c-.567-.426-.9-.692-.9-1.478 0 .786-.342 1.052-.9 1.478-.369.285-.855.408-1.341.417-.495 0-.99-.132-1.359-.417-.567-.426-.9-.692-.9-1.478 0 .786-.342 1.052-.9 1.478-.369.285-.855.408-1.35.417-.486 0-.981-.132-1.35-.417-.567-.426-.9-.692-.9-1.488 0 .796-.342 1.062-.9 1.488-.261.2-.567.36-.9.417V18Z"
      fill="#000"
    />
  </svg>
);

export default SvgStoreIcon;
