import { EventsData } from "../utils/typesEvents";
import { apiCmsPrivate } from "./api";

//api/v1/super-admin/event/list-events/{offSet}/{limit}/{sortType}   filterType=EVENT_NAME&filterValue=a
export const getEventListService = (
  sortType: string,
  offSet: number,
  limit: number,
  filterType: string,
  filterValue: string
) => {
  const queryParams = new URLSearchParams({
    filterType: filterType,
    filterValue: filterValue,
  });
  let urlService = "";
  filterType !== "" && filterValue !== ""
    ? (urlService = `/api/v1/super-admin/event/list-events/${offSet}/${limit}/${sortType}?${queryParams}`)
    : (urlService = `/api/v1/super-admin/event/list-events/${offSet}/${limit}/${sortType}`);

  return apiCmsPrivate.get<EventsData[]>(urlService);
};
